@keyframes awn-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes awn-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes awn-slide-right {
  from {
    opacity: 0;
    left: 100%;
  }

  to {
    opacity: 1;
    left: 0;
  }
}

@keyframes awn-slide-left {
  from {
    opacity: 0;
    right: 100%;
  }

  to {
    opacity: 1;
    right: 0;
  }
}

@keyframes awn-bar {
  from {
    right: 100%;
  }

  to {
    right: 0;
  }
}

.awn-popup-loading-dots,
.awn-popup-loading-dots:before,
.awn-popup-loading-dots:after {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  animation-fill-mode: both;
  background: #fff;
  animation: awn-loading-dots 1s infinite ease-in-out;
}

.awn-popup-loading-dots {
  position: relative;
  margin-left: 24px;
  display: inline-block;
  color: #fff;
  animation-delay: -0.16s;
}

.awn-popup-loading-dots:before,
.awn-popup-loading-dots:after {
  content: '';
  position: absolute;
  top: 0;
}

.awn-popup-loading-dots:before {
  left: -16px;
  animation-delay: -0.32s;
}

.awn-popup-loading-dots:after {
  left: 16px;
}

@keyframes awn-loading-dots {
  0%,
  80%,
  100% {
    box-shadow: 0 0 0 0;
  }

  40% {
    box-shadow: 0 0 0 2px;
  }
}
