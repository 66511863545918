@import '../../../styles/flex';
@import '../../../styles/variables';
@import '../../../styles/common';

.footer-wrapper {
  width: 100%;

  @media screen and (max-width: 767px) {
    height: 100%;
  }
}

.footer {
  width: 100%;
  box-sizing: border-box;
  padding: 2.78vh $margin-desktop;
  background-color: $beige3;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  bottom: 0;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    padding-top: 0;
    padding-bottom: 60px;
    justify-content: space-around;
    padding: 16px $margin-laptop;
    max-height: 190px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    height: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: 767px) and (orientation: landscape) {
    height: auto;
    max-height: none;
  }

  @media screen and (max-width: 767px) and (orientation: landscape) {
    max-height: none;
    height: auto;
  }

  @media screen and (max-width: 767px) and (orientation: portrait) {
    justify-content: center;
  }

  @media screen and (max-width: 425px) {
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding: 1.5vh 15vw;
  }
}

.footer-bottom {
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: $dark-brown;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: $beige5;
  @media screen and (max-width: 767px) {
    height: $footer-bottom-height-mobile;
  }
}

.footer__column-content-container {
  @media screen and (max-width: 900px) and (orientation: portrait) {
    display: flex;
    margin-top: 24px;
  }
  @media screen and (min-width: 1025px) {
    display: block;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-top: 0;
  }

  .footer__column-items {
    &:first-child {
      @media screen and (max-width: 900px) and (orientation: portrait) {
        margin-right: 16px;
        margin-bottom: 0;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 2vw;
      }
    }

    &:last-child {
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }
}

.footer__column-content {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    margin-top: 0;
  }
  @media screen and (max-width: 767px) {
    margin-top: 30px;
    font-size: 12px;
    width: 50%;
  }
  @media screen and (max-width: 425px) {
    width: 250px;
  }

  &:last-child {
    @media screen and (max-width: 767px) {
      text-align: center;
    }

    @media screen and (max-width: 425px) {
      text-align: left;
    }
  }

  label,
  a {
    margin-bottom: 0.7vw;
    @media screen and (max-width: 767px) {
      margin-bottom: 2vw;
    }
  }

  label:last-child,
  a:last-child {
    margin-bottom: 0;
  }

  .footer__column-title {
    font-weight: bold;
    letter-spacing: 0.9px;
    color: $brown-black;
    font-size: 14px;

    @media screen and (min-width: 1025px) {
      justify-content: left;
    }
  }

  .footer__column-items {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    color: $gray2;
    text-decoration: none;
    cursor: pointer;

    svg {
      margin-right: 10px;
    }

    @media screen and (max-width: 767px) {
      justify-content: center;
    }
    @media screen and (max-width: 425px) {
      justify-content: flex-start;
    }
  }
}

.contacts-title {
  @media screen and (max-width: 900px) and (orientation: portrait) {
    display: none;
  }
  @media screen and (max-width: 767px) {
    display: block;
  }
}
