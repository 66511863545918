@import '../../styles/variables';
@import '../../styles/common';

.root-account {
  min-height: 100%;
  position: relative;
  @include display-flex;
  background-color: $beige;

  @media screen and (max-width: 767px) {
    background-color: $bg-mobile-beige;
  }
}
