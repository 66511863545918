@import '../../../../styles/variables';
@import '../../../../styles/flex';
@import '../../../../styles/common';
@import '../../../../styles/icons';
@import '../../../../styles/buttons';

$label-height-desktop: 3.125vw;
$label-height-laptop: 5.99vw;

.checkout-page {
  &.grid {
    // Start css-grid building
    display: grid;
    min-height: 100%;
    grid-template-areas: '. header header header header .' '. breadcrumbs link link link .' '. title order order order .' 'items items order order order .' 'items items amount additional additional .' 'prev prev . . next .';
    grid-template-columns: $margin-desktop 36.25vw auto 20vw 12vw $margin-desktop;
    grid-template-rows: $header-height-desktop $breadcrumbs-height-desktop $page-title auto $amount-height-desktop $footer-height-desktop;

    .grid-area {
      &-header {
        grid-area: header;
      }

      &-breadcrumbs {
        grid-area: breadcrumbs;
      }

      &-link {
        grid-area: link;
        text-align: right;
      }

      &-page-title {
        grid-area: title;
        z-index: 9;
      }

      &-items-list {
        grid-area: items;
        background-color: $bg-beige;
        margin-top: calc(
          (
              #{$header-height-desktop} + #{$breadcrumbs-height-desktop} + #{$page-title}
            ) * -1
        );
        padding-top: calc(
          #{$header-height-desktop} + #{$breadcrumbs-height-desktop} + #{$page-title}
        );
        margin-left: -$margin-desktop;
        padding-left: $margin-desktop * 2;
      }

      &-order-info {
        grid-area: order;
      }

      &-page-pointer-prev {
        grid-area: prev;
        background-color: $bg-beige;
        margin-left: -$margin-desktop;
        padding-left: $margin-desktop * 2;
      }

      &-amount {
        grid-area: amount;
      }

      &-additional-block {
        grid-area: additional;
      }

      &-page-pointer-next {
        grid-area: next;
      }
    }

    // End css-grid building

    .grid-area-items-list {
      padding-right: 1.563vw;

      .items-list {
        overflow-y: auto;
        max-height: calc(
          100vh - #{$header-height-desktop} - #{$breadcrumbs-height-desktop} - #{$page-title} -
            #{$footer-height-desktop}
        );
        padding-right: 1.302vw;

        .item {
          @include display-flex;
          @include align-items(center);
          @include justify-content(space-between);
          padding-top: 25px;
          padding-bottom: 25px;
          border-bottom: 0.052vw solid $beige2;

          .item-image {
            img {
              max-height: 5.469vw;
              position: relative;
              top: 12px;
            }
          }

          .item-price {
            text-align: right;

            .changer-quantity {
              @include text-base($size: 16px, $color: $gray2);

              span {
                margin: 0 10px;
                cursor: pointer;
                color: $dark-brown;
              }
            }
          }
        }
      }
    }

    .grid-area-order-info {
      @include display-flex;
      @include flex-direction(column);
      @include justify-content(space-between);
      padding-left: 2.604vw;
      box-sizing: border-box;

      .tabs {
        .label {
          height: $label-height-desktop;

          &-inner {
            display: flex;
            align-items: flex-end;
          }

          svg {
            height: 18px;
            margin-right: 0.625vw;
          }
        }

        .panel {
          overflow-y: auto;
          height: calc(
            100vh - #{$header-height-desktop} - #{$breadcrumbs-height-desktop} -
              #{$amount-height-desktop} - #{$footer-height-desktop} - #{$label-height-desktop}
          );
          padding-right: 0.521vw;

          .form {
            .form-wrap {
              .col {
                .checkbox-container {
                  &_checked-hide {
                    ~ .select_hidden,
                    ~ .form-label_hidden,
                    ~ .attention-message_hidden {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .grid-area-page-pointer-prev {
      @include display-flex();
      @include justify-content(space-between);
      @include align-items(flex-start);
      padding-top: 0.7vw;
      padding-right: 1.563vw;
    }

    .grid-area-amount {
      @include display-flex;
      @include flex-direction(column);
      padding-left: 2.604vw;
      padding-top: 1.042vw;
    }

    .grid-area-additional-block {
      padding-top: 1.042vw;

      .additional-wrap {
        @include display-flex;
        @include align-items(center);

        &_mb-25 {
          margin-bottom: 25px;
        }

        .additional__value {
          @include text-base(
            $size: 1.25vw,
            $family: 'Roboto Condensed',
            $weight: $bold
          );
        }
      }
    }

    .grid-area-page-pointer-next {
      @include display-flex;
      @include flex-direction(column);
      @include align-items(flex-end);

      &_mt {
        padding-top: 0.7vw;

        &:last-of-type {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    .action-cc {
      font-style: normal;
      font-weight: 500;
      font-size: 0.863vw;
      color: #4d4048;
      margin-top: 2.426vw;
      cursor: pointer;

      span {
        margin-right: 0.541vw;
      }
    }

    .action-cc:hover {
      .action-cc__underline {
        border-bottom: 1px solid #4d4048;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .checkout-page {
    &.grid {
      // Start css-grid building for laptop
      grid-template-areas:
        '. header header header .'
        '. breadcrumbs link link .'
        '. title order order .'
        'items items order order .'
        'items items amount additional .'
        'prev prev . next .';
      grid-template-columns: $margin-laptop 47vw 25vw auto $margin-laptop;
      grid-template-rows: $header-height-laptop $breadcrumbs-height-laptop $page-title auto $amount-height-laptop $footer-height-laptop;
      // End css-grid building for laptop

      .grid-area-items-list {
        margin-top: calc(
          (
              #{$header-height-laptop} + #{$breadcrumbs-height-laptop} + #{$page-title}
            ) * -1
        );
        padding-top: calc(
          #{$header-height-laptop} + #{$breadcrumbs-height-laptop} + #{$page-title}
        );
        margin-left: -$margin-laptop;
        padding-left: $margin-laptop * 2;
        padding-right: 20px;

        .items-list {
          overflow-y: auto;
          max-height: calc(
            100vh - #{$header-height-laptop} - #{$breadcrumbs-height-laptop} - #{$page-title} -
              #{$footer-height-laptop}
          );
          padding-right: 1.302vw;

          .item {
            @include flex-direction(column);
            @include align-items(start);

            .item-image {
              img {
                display: none;
              }
            }

            .item-price {
              width: 100%;
              @include display-flex;
              @include align-items(center);
              @include justify-content(space-between);
              margin-top: 20px;
            }
          }
        }
      }

      .grid-area-order-info {
        .tabs {
          .panel {
            height: calc(
              100vh - #{$header-height-laptop} - #{$breadcrumbs-height-laptop} -
                #{$amount-height-laptop} - #{$footer-height-laptop} - #{$label-height-laptop}
            );
          }
        }
      }

      .grid-area-page-pointer-prev {
        justify-content: flex-end;
        padding-top: 25px;
        margin-left: -$margin-laptop;
        padding-left: $margin-laptop * 2;
        padding-right: 20px;
      }

      .grid-area-amount {
        padding-top: 10px;
      }

      .grid-area-additional-block {
        padding-top: 10px;

        .additional-wrap {
          @include justify-content(space-between);

          &_mb-25 {
            margin-bottom: 25px;
          }

          .additional__value {
            font-size: 16px;
          }
        }
      }

      .grid-area-page-pointer-next {
        &_mt {
          padding-top: 25px;
        }

        .attention-message {
          margin-top: 10px;
        }
      }

      .action-add-cc {
        //font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #4d4048;
      }
    }
  }

  @media screen and (orientation: portrait) {
    $height-additional: 10vw;
    $height-amount: 11vw;
    $height-page-pointers: 18vw;

    .checkout-page {
      &.grid {
        // Start css-grid building for laptop portrait
        grid-template-areas:
          '. header header .'
          '. breadcrumbs link .'
          '. title order .'
          'items items order .'
          'items items additional .'
          'items items amount .'
          'prev prev next .';
        grid-template-columns: $margin-laptop 47vw auto $margin-laptop;
        grid-template-rows: $header-height-laptop $breadcrumbs-height-laptop $page-title auto $height-additional $height-amount $height-page-pointers;

        .grid-area-items-list {
          .items-list {
            max-height: calc(
              100vh - #{$header-height-laptop} - #{$breadcrumbs-height-laptop} -
                #{$page-title} - #{$height-page-pointers}
            );
          }
        }

        .grid-area-order-info {
          .tabs {
            .panel {
              height: calc(
                100vh - #{$header-height-laptop} - #{$breadcrumbs-height-laptop} -
                  #{$height-additional} - #{$height-amount} - #{$height-page-pointers} -
                  #{$label-height-laptop}
              );
            }
          }
        }

        .grid-area-amount {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-top: 25px;

          .price-wrap {
            text-align: right;
          }
        }

        // End css-grid building for laptop portrait
        .grid-area-additional-block {
          padding-left: 2.604vw;

          .additional-wrap {
            @include display-flex;
            @include align-items(flex-start);
            @include justify-content(space-between);

            .attention-message {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .checkout-page {
    &.grid {
      // Start css-grid building for mobile
      grid-template-areas: '. header .' '. breadcrumbs .' '. title .' '. items .' '. link .' 'order order order' 'next next next' 'additional additional additional' 'amount amount amount' 'prev prev prev';
      grid-template-columns: $margin-mobile auto $margin-mobile;
      grid-template-rows: $header-height-mobile $breadcrumbs-height-mobile min-content max-content;
      background: $bg-mobile-beige;
      // End css-grid building for mobile

      .grid-area-page-title {
        margin-bottom: $margin-mobile;
      }

      .grid-area-items-list {
        margin-right: -20px;
        padding-bottom: 20px;
      }

      .grid-area-order-info {
        background-color: $white;
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;

        .tabs {
          .label {
            height: 50px;
          }

          .panel {
            height: auto;
          }
        }
      }

      .grid-area-additional-block {
        background-color: $white;
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;

        .additional-wrap {
          .attention-message {
            width: 65%;
            text-align: right;
          }
        }
      }

      .grid-area-page-pointer-prev {
        flex-direction: column;
        padding-top: 20px;
      }

      .grid-area-amount {
        background-color: $white;
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
      }

      .grid-area-page-pointer-prev {
        background-color: $white;
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
        padding-bottom: $margin-mobile;
        margin-left: 0;
      }

      .grid-area-page-pointer-next {
        background-color: $white;
        padding-top: 20px;
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
        padding-bottom: $margin-mobile;
      }
    }
  }
}

@media (max-width: 823px) and (orientation: landscape) {
  .checkout-page {
    &.grid {
      .grid-area-additional-block {
        padding-top: 10px;

        .additional-wrap {
          margin-bottom: 0;

          .attention-message {
            margin: 0;
          }
        }
      }
    }
  }
}
