@import '../../../../styles/flex';
@import '../../../../styles/variables';
@import '../../../../styles/common';

.content-main {
  &_block {
    &__start {
      @media (max-width: 1024px) {
        align-self: center;
      }

      @media (max-width: 767px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        --button-height: 50px;
        --button-block-vertical-margin: 20px;
        --button-block-height: calc(
          var(--button-height) * 4 + (var(--button-block-vertical-margin) * 2)
        );
        --main-title-padding-top: 47px;
      }

      @media (max-height: 600px) {
        --main-title-padding-top: 10px;
      }

      @media (max-width: 550px) {
        --button-height: 40px;
      }

      .img-block {
        position: absolute;
        @include display-flex;
        @include justify-content(center);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../../img/mainPage/main-landing/girl.png');
        background-color: $beige7;
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: bottom;
        background-position-x: right;

        @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
          background-position-x: center;
          background-size: cover;
        }

        @media (max-width: 767px) {
          background-image: url('../../../../img/mainPage/main-landing/girl.png');
          position: relative;
          width: 100vw;
          height: 100vh;
          background-position-y: bottom;
        }

        #startImage {
          margin-top: 0;
          width: 100%;
          position: absolute;
          bottom: 0;
          @media (min-width: 1500px) {
            width: 100%;
            height: 100%;
          }
          @media (max-width: 550px) {
            height: auto;
            width: auto;
            max-height: 80%;
          }
          @media (max-width: 1024px) {
            margin-top: -80px;
          }
          @media (max-width: 1366px) {
            margin-top: -75px;
          }
          @media (max-width: 767px) {
            position: absolute;
            bottom: 0;
            left: 50%;
            max-width: 1008px;
            width: 230%;
            height: auto !important;
            max-height: none;
            margin-top: 0;
            transform: translateX(-50%);
          }
        }

        .light-loader__container {
          width: 40%;
          height: 50%;
          position: relative;
          top: 12%;
          left: 0;
        }
      }

      .pagination-block{
        position: absolute;
        top: 50%;
        left: 5%;
        width: 90%;

        @media (max-width: 767px) {
          top: 40%;
        }

        .pagination {
          @include display-flex;
          @include align-items(center);
          @include justify-content(space-between);

          @media (max-width: 767px) {
            position: absolute;
            z-index: 1000;
            width: 95%;
            left: 3%;
          }

          &-item {

            &:hover {
              cursor: pointer;
            }

            &.active {
            }
          }
        }
      }

      .main-title {
        width: 700px;
        position: absolute;
        top:30%;
        left: 10%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;


        @media (max-width: 1300px) {
          max-width: 50%;
        }

        @media (max-width: 1024px) {
          top: 20%;
          left: 5%;
          text-wrap: none;
          max-width: 350px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }


        @media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) {
          top: 10%;
          max-width: initial;
          width: 70%;
          left: 15%;
          justify-content: flex-start;
        }

        @media (max-width: 767px) {
          max-width: initial;
          top: 0%;
          left: 0;
          z-index: 10;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          padding-left: 20px;
          padding-right: 20px;
          width: 100%;
          height: calc(95vh - #{$header-height-mobile} - 32px);
          padding-top: calc(
                  #{$header-height-mobile} + var(--main-title-padding-top)
          );
          box-sizing: border-box;
        }

        @media (max-resolution: 204dpi) and (max-width: 754px) {
          height: calc(100vh - #{$header-height-mobile} - 80px);
        }


        .main-title-title {
          font-family: 'DidotLH';
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 2.600vw;
          text-align: center;
          line-height: 3.809vw;
          //width: 70%;

          @media (max-width: 1024px) {
            font-size: 2.900vw;
            text-align: center;
            line-height: 37px;
          }

          @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
            width: 100%;
          }

          @media (max-width: 767px) {
            text-align: center;
            font-size: 23px;
            @media (min-height: 660px) and  (max-height: 667px) {
              line-height: 25px;

            }
            }

          @media (max-width: 700px) {
            text-align: center;
            font-size: 20px;
          }
        }

        .content-main_btn {
          display: flex;
          margin-top: 40px;
          gap: 10px;

          @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
            margin-top: 0 !important;
            display: flex;
            flex-direction: row !important;
          }

          @media (max-width: 1180px) {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;
          }

          @media (max-width: 767px) {
            margin-top: 10px;
            flex-direction: column;
            width: 60%;
          }

          .btn {
            padding: 19px 61px;

            @media (max-width: 1024px) {
              margin-right: 0;
            }

            @media (max-width: 767px) {
              padding: 15px 30px;
              margin-right: 0;
            }
          }
        }
      }

      .main-title-offer {
        width: 700px;
        position: absolute;
        top:60%;
        left: 10%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;


        @media (max-width: 1300px) {
          max-width: 50%;
        }

        @media (max-width: 1024px) {
          top: 60%;
          left: 5%;
          text-wrap: none;
          max-width: 350px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }


        @media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) {
          top: 22%;
          max-width: initial;
          width: 70%;
          left: 15%;
          justify-content: flex-start;
        }

        @media (max-width: 767px) {
          max-width: initial;
          top: 20%;
          left: 0;
          z-index: 10;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          padding-left: 20px;
          padding-right: 20px;
          width: 100%;
          height: calc(95vh - #{$header-height-mobile} - 32px);
          padding-top: calc(
                  #{$header-height-mobile} + var(--main-title-padding-top)
          );
          box-sizing: border-box;
        }

        @media (max-width: 430px) {
          margin-top: 28px;
        }

        @media (max-width: 290px) {
          top: 25%;
        }

        @media (max-resolution: 204dpi) and (max-width: 754px) {
          height: calc(100vh - #{$header-height-mobile} - 80px);
        }


        .main-title-title {
          font-family: 'DidotLH';
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 26px;
          text-align: center;
          line-height: 25px;

          @media (max-width: 1024px) {
            text-align: center;
            line-height: 25px;          }

          @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
            font-size: 16px;
            width: 100%;
          }

          @media (max-width: 767px) {
            font-size: 16px;
            text-align: center;
            @media (min-height: 660px) and  (max-height: 667px) {
              font-size: 16px;
            }
          }

          @media (max-width: 700px) {
            font-size: 16px;
            text-align: center;
          }
        }

        .content-main_btn {
          display: flex;
          margin-top: 20px;
          gap: 10px;

          @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
            margin-top: 0 !important;
            display: flex;
            flex-direction: row !important;
          }

          @media (max-width: 1180px) {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;
          }

          @media (max-width: 767px) {
            margin-top: 0 !important;
            flex-direction: column;
            width: 60%;
          }

          .btn {
            padding: 19px 61px;

            @media (max-width: 1024px) {
              margin-right: 0;
            }

            @media (max-width: 767px) {
              padding: 15px 30px;
              margin-right: 0;
            }
          }
        }
      }

      .parent {
        padding-top: 30vh;

        @media (max-width: 767px) {
          padding-top: 0;
        }
      }
    }
  }

  .main-slide.main-slide_start {
    min-height: 490px;
    max-height: 100%;
  }
}


.fadeIn {
  animation: fadeInAnimation 1s linear;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
