@import '../../../../styles/flex';
@import '../../../../styles/variables';
@import '../../../../styles/common';

.content-main {
  &_block {
    //design-block
    &__design {
      @include display-flex();
      @include flex-direction(row);
      align-self: center;
      flex-wrap: wrap;
      top: 25%;

      @media (max-width: 1024px) {
        height: auto;
        align-self: center;
        top: 30%;

        .row {
          align-items: flex-start;
        }
      }

      @media (max-width: 767px) {
        width: 100%;
        justify-content: space-evenly;
        top: initial;
      }

      * {
        box-sizing: border-box;
      }

      .image-wrap {
        position: relative;

        img {
          height: 100%;
          max-height: 50vh;
          max-width: 25vw;
          border-radius: 2.605vw;
          box-shadow: 0 0 2vw rgba(0, 0, 0, 0.5);
          margin-left: 1vw;
          margin-right: 1vw;
          @media (max-width: 1024px) {
            height: auto;
            margin-left: 1.5vw;
            margin-right: 1.5vw;
            margin-bottom: 4vw;
          }
          @media screen and (max-width: 767px) {
            height: 200px;
            width: 152px;
            max-width: none;
            margin-top: 20px !important;
            margin-bottom: 20px;
          }
        }

        svg {
          position: absolute;
          width: 90%;
        }
      }

      .design__left {
        @include display-flex();
        @include align-items(center);
        @include justify-content(flex-end);
        margin-bottom: 4vw;
        padding-top: 4vw;
        padding-right: 0.5vw;
        width: 50%;

        @media (max-width: 1024px) {
          flex-direction: column-reverse;
        }

        > a {
          width: 100%;
          height: 37.2vw;
        }

        span {
          margin-right: 2.5vw;
        }
      }

      .design__right {
        @include display-flex();
        @include align-items(center);
        @include justify-content(flex-start);
        margin-top: 4vw;
        padding-top: 4vw;
        padding-left: 0.5vw;
        width: 50%;

        @media (max-width: 1024px) {
          flex-direction: column-reverse;
        }

        > a {
          width: 100%;
          height: 37.2vw;
        }

        span {
          margin-left: 2.5vw;
        }

        .image-wrap {
          @media (max-width: 1024px) {
            margin-top: 4vw;
          }
        }
      }

      .design-text {
        @include text-base($size: 3.5vw, $weight: $bold, $color: $gray2);

        @media (max-width: 1024px) {
          font-size: 50px;
        }

        @media (max-width: 767px) {
          font-size: 30px;
        }
      }
    }

    //design-block end
  }

  .main-slide {
    &_design {
      min-height: 440px;
      height: 1100px;

      @media screen and (max-width: 1024px) {
        height: 800px;
      }

      @media screen and (max-width: 767px) {
        height: 500px;
      }

      @media screen and (max-width: 400px) {
        height: 400px;
      }
    }
  }
}

#design {
  justify-content: center;
  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    justify-content: center;
  }

  @media screen and (max-width: 600px) {
    justify-content: center;
    gap: 30px;
    .content-main_block__description{
      margin: 20px;
    }

    .content-main_block__design .design__right span{
      margin-bottom: 10px;
    }

    .design__right {
      gap: 30px;
    }
    .design__left {
      gap: 50px;
    }
  }

  @media screen and (max-width: 768px) {
    .content-main_block__design .design__right .image-wrap{
      margin-top:0;
    }
  }
}
