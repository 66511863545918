@import '../../../styles/_variables';
@import '../../../styles/_common';
@import '../../../styles/_flex';

$shift-desktop: 1.615vw;
$shift-tablet: 26px;

$sidebar-animation-duration: 300ms ease-in;
$sidebar-animation-delay: 300ms;

.sidebar {
  width: 22%;
  min-height: 100%;
  padding-top: 8.854vw;
  padding-left: $margin-desktop;
  padding-right: 2.865vw;
  background-color: $white;
  z-index: 2;
  position: relative;
  white-space: nowrap;
  transition: width $sidebar-animation-duration,
    padding-left $sidebar-animation-duration,
    padding-right $sidebar-animation-duration;
  box-sizing: border-box;

  .btn_sidebar-handler {
    position: absolute;
    right: 0;
    transform: translate(50%, -80%);

    .icon_arrow_left {
      max-width: 45%;
      transition: $animation-global;

      path {
        fill: $dark-brown;
        transition: $animation-global;
      }
    }

    &:hover {
      .icon_arrow_left {
        path {
          fill: $white;
        }
      }

      @media (pointer: coarse) {
        .icon_arrow_left {
          path {
            fill: $dark-brown;
          }
        }
      }
    }

    &:active {
      .icon_arrow_left {
        path {
          fill: $white;
        }
      }
    }
  }

  .sidebar-nav-wrap {
    overflow: hidden;

    .sidebar-nav {
      @include display-flex;
      @include flex-direction(column);
      @include justify-content(space-between);
      @include align-items(flex-start);

      &-link {
        @include display-flex;
        @include align-items(center);
        padding-top: 0.521vw;
        padding-bottom: 0.521vw;
        margin-bottom: 1.563vw;
        text-decoration: none;
        border-bottom: 0.104vw solid transparent;
        transition: $animation-global;

        &__text {
          @include text-base($size: 0.833vw, $weight: $bold, $color: $gray2);
          line-height: 1.2;
          letter-spacing: 0.052vw;
          transition: $animation-global;
        }

        &__icon {
          width: $shift-desktop;
          margin-right: 0.521vw;

          path {
            fill: $gray2;
          }
        }

        &:hover {
          border-bottom-color: $yellow;

          .sidebar-nav-link__text {
            color: $dark-brown;
          }

          .sidebar-nav-link__icon {
            path {
              fill: $dark-brown;
            }
          }

          @media (pointer: coarse) {
            border-bottom-color: transparent;

            .sidebar-nav-link__text {
              color: $gray2;
            }

            .sidebar-nav-link__icon {
              path {
                fill: $gray2;
              }
            }
          }
        }

        &.active,
        &:active {
          border-bottom-color: $yellow;

          .sidebar-nav-link__text {
            color: $dark-brown;
          }

          .sidebar-nav-link__icon {
            path {
              fill: $dark-brown;
            }
          }
        }
      }

      .sidebar-nav-btn-wrap {
        width: 100%;
        @include display-flex;
        transform: translate(-#{$shift-desktop}, 0);

        .sidebar-nav-link {
          .sidebar-nav-link__icon {
            margin-right: 0;
          }

          margin-bottom: 0;
        }
      }
    }
  }

  &_active {
    width: calc(1.042vw * 2 + #{$shift-desktop});
    padding-left: 1.042vw;
    padding-right: 1.042vw;

    .btn_sidebar-handler {
      .icon_arrow_left {
        transform: rotate(180deg);
      }
    }

    .sidebar-nav-wrap {
      .sidebar-nav {
        .sidebar-nav-btn-wrap {
          transform: translate(0, 0);
          transition-delay: $sidebar-animation-delay;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: 295px;
    padding-top: 145px;
    padding-left: $margin-laptop;
    padding-right: 20px;

    .sidebar-nav-wrap {
      .sidebar-nav {
        &-link {
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom-width: 1px;
          margin-bottom: 20px;

          &__text {
            font-size: 14px;
          }

          &__icon {
            width: $shift-tablet;
            height: 24px;
            margin-right: 10px;
          }
        }

        .sidebar-nav-btn-wrap {
          transform: translate(-#{$shift-tablet}, 0);
        }
      }
    }

    &_active {
      width: calc(20px * 2 + #{$shift-tablet});
      padding-left: 20px;
      padding-right: 20px;

      .sidebar-nav-wrap {
        .sidebar-nav {
          .sidebar-nav-btn-wrap {
            transform: translate(0, 0);
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: 'portrait') {
    width: calc(20px * 2 + 26px);
    padding-left: 20px;
    padding-right: 20px;

    > .btn {
      display: none;
    }

    &_active {
      > .btn {
        display: none;
      }
    }

    .sidebar-nav-wrap {
      .sidebar-nav {
        .sidebar-nav-btn-wrap {
          transform: translate(0, 0);
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: 225px;
    position: absolute;
    padding-top: 100px;
    padding-left: $margin-mobile;

    .sidebar-nav-wrap {
      .sidebar-nav {
        .sidebar-nav-btn-wrap {
          transform: translate(-#{$shift-tablet}, 0);
          transition: 0ms;
        }
      }
    }

    &_active {
      width: calc(20px * 2 + 26px);

      .sidebar-nav-wrap {
        .sidebar-nav {
          .sidebar-nav-btn-wrap {
            transform: translate(0, 0);
            transition-delay: $sidebar-animation-delay;
          }
        }
      }
    }
  }
}
