@import '../../../../styles/common';

$title-height: 10vw;

.profile-header {
  header.grid-area-header.header {
    &.header_sticky {
      padding: 0 7vh;

      .item.header__logo {
        flex: 1 0 14%;
        justify-content: center;

        @media screen and (max-width: 1500px) {
          flex: 1 0 17%;
        }

        @media screen and (max-width: 1024px) {
          flex: 0 0 17%;
        }
      }

      .item.header__menu {
        flex: 1 0 63%;

        #menu {
          @media screen and (max-width: 1024px) {
            height: 105vh;
          }
        }

        #menu-toggle {
          @media screen and (max-width: 425px) {
            margin-right: 32px;
          }
        }

        #menu-toggle #menu .menu_scrollable .menu-part_left {
          justify-content: space-evenly;
          padding-left: 6vh;

          @media screen and (max-width: 1500px) {
            padding-left: 5vh;
          }

          @media screen and (max-width: 1024px) {
            padding-left: 0;
          }
        }

        @media screen and (max-width: 1500px) {
          flex: 1 0 63%;
        }

        @media screen and (max-width: 1350px) {
          flex: 1 0 66%;
        }

        @media screen and (max-width: 1024px) {
          flex: 0 0 0;
        }
      }

      @media screen and (max-width: 1500px) {
        padding: 0 3vh;
      }

      @media screen and (max-width: 425px) {
        padding: 0 2vh;
      }
    }
  }
}

.content-profile.settings {
  background-color: transparent;
}

.settings .content-main {
  @include display-flex;
  @include flex-direction(column);

  min-height: auto;
  background-color: transparent;

  p {
    height: 3vh;
    min-height: 22px;
    line-height: normal;
    margin-bottom: 8px;

    label {
      &.checkbox-container {
        padding-left: 27px;
        padding-top: 2px;

        .checkmark {
          height: 20px;
          width: 20px;

          &::after {
            left: 7px;
            top: 3px;
            width: 5px;
            height: 9px;
            border-width: 0 2px 2px 0;
          }
        }
      }
    }
  }
}
