@import '../../../styles/variables';
@import '../../../styles/common';
@import '../../../styles/animations';

.hint {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .form-block {
    width: $hint_desktop_width;
    max-width: 100%;
    margin: 2.6vw auto 20px;
    .form {
      &-title {
        text-align: center;
        @include text-base(
          $size: 2.5vw,
          $weight: $bold,
          $color: $dark-brown,
          $family: 'Roboto Condensed'
        );
      }
      &-comment {
        margin-top: 1.823vw;
        text-align: center;
        @include text-base(
          $size: 0.885vw,
          $weight: $medium,
          $color: $dark-brown,
          $family: 'Montserrat'
        );
      }
      &-types-social {
        margin: 30px 0 15px;
      }
      .add-friend {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: end;
        margin-top: 1.5625vw;
        cursor: pointer;
        @include text-base(
          $size: 0.833vw,
          $weight: $medium,
          $color: $dark-brown,
          $family: 'Montserrat'
        );
      }
      textarea {
        min-height: 3.65vw;
        max-height: 3.65vw;
        padding: 0.52vw;
        border: 0.052vw solid $beige2;
        &::placeholder {
          @include text-base(
            $size: 12px,
            $weight: $medium,
            $color: $beige2,
            $family: 'Montserrat'
          );
        }
      }
    }
  }

  .hint-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: $hint_desktop_width;
    margin: auto;
    margin-bottom: 2vw;

    button {
      width: calc(50% - 10px);
    }
  }
}

@media (max-width: 1024px) {
  .hint {
    .form-block {
      width: $hint_laptop_width;
      max-width: 100%;
      .form {
        &-title {
          font-size: 24px;
        }
        &-comment {
          margin: 30px 0 10px;
          font-size: 14px;
        }
        .add-friend {
          font-size: 14px;
        }
        textarea {
          min-height: 70px;
          padding: 10px;
        }
      }
    }
    .hint-controls {
      flex-wrap: wrap;
      justify-content: center;
      width: $hint_laptop_width;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      button {
        width: 100%;
        margin: 0 0 10px;
        padding: 15px;
      }
    }
  }
}
