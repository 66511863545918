@import '../../../../styles/variables';
@import '../../../../styles/common';
@import '../../../../styles/grid';

.content-profile {
  flex: 1 0;
  min-height: 100%;
  padding-top: $pt-desktop;
  padding-left: $pl-desktop;
  background: #fbfaf8;

  .content-main {
    min-height: auto;
    background: none;
  }

  &.content-profile {
    position: relative;
    padding-right: $margin-desktop;

    .page-title {
      margin-bottom: 1.823vw;
    }

    .content-main {
      .profile-photo-wrap {
        max-width: 11vw;

        .profile-photo-container {
          margin-bottom: 1.563vw;
        }

        .profile-photo {
          width: 100%;
          height: 10.116vw;
          min-height: 165px;
          display: flex;
          justify-content: center;
          position: relative;
          overflow: hidden;

          &_edit {
            cursor: pointer;
            > label {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }

          &_bg {
            background: #f8f5f2;
          }

          &__img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }

          &__error {
            padding-top: 5px;
            font-size: 10px;
            color: #d67588;
          }

          > label {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            > input {
              display: none;
            }

            > svg {
              width: 44px;
              height: 55px;
              animation: none;

              > path {
                fill: #ebe0d9;
              }
            }
          }
        }

        .profile-photo-upload {
          width: 100%;
          height: 100%;
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: rgba(77, 64, 72, 0.5);
          z-index: 2;
          pointer-events: none;
          cursor: pointer;

          &__icon {
            position: absolute;
            top: 0;
            right: 0;
            animation: none;
            transition: 0.3s;
          }

          &__icon-center {
            position: static;
            animation: none;
            margin-bottom: 1.5vw;
          }

          &__title {
            @include text-base(
              $size: 0.729vw,
              $family: 'Montserrat',
              $weight: $medium,
              $color: #fff
            );
            line-height: 20px;
          }
        }
      }

      .form {
        width: 90%;
        max-width: 27vw;
        margin-bottom: 2.604vw;

        > .checkbox-container_disabled {
          cursor: default;
        }

        .form .form-label input:not(.animation-disabled):focus + .label-text,
        .form
          .form-label
          .form-label__textarea:not(.animation-disabled):focus
          + .label-text {
          transform: translate3d(0, -3.2vw, 0) scale(0.9) !important;
        }

        > .form-label {
          .invalid {
            &:focus {
              ~ .label-text {
                transform: translate3d(0, -3.2vw, 0) scale(0.9) !important;
                @media screen and (max-width: 1024px) {
                  transform: translate3d(0, -46px, 0) scale(0.9) !important;
                }
              }
            }
          }

          .shrink {
            ~ .label-text {
              transform: translate3d(0, -3.2vw, 0) scale(0.9) !important;
            }
          }
        }
      }
    }

    .parent {
      width: 100%;
    }

    .parent-loader {
      left: -2%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .content-profile {
    padding-top: $pt-tablet;
    padding-left: $margin-mobile;

    &.content-profile {
      padding-top: 99px;
      padding-right: 30px;

      .page-title {
        margin-bottom: 30px;
      }

      .content-main {
        .profile-photo-wrap {
          width: 100%;
          max-width: 100%;
          margin-bottom: 40px;

          .profile-photo-container {
            margin-bottom: 20px;
          }

          .profile-photo {
            width: 100%;
          }
        }

        .form {
          width: 100%;
          max-width: 410px;
          margin-bottom: 50px;

          .form-label {
            input {
              line-height: 15px;
              margin-top: 30px;
            }

            .shrink {
              ~ .label-text {
                transform: translate3d(0, -46px, 0) scale(0.9) !important;
              }
            }
          }

          .custom-select {
            line-height: 14px;
            padding-top: 15.93px;
            margin-bottom: 0.5vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .content-profile {
    padding-top: $pt-mobile;
    padding-left: $pl-mobile;

    &.content-profile {
      padding-right: $margin-desktop;

      .content-main {
        background: none;
        .profile-photo-wrap {
          max-width: 165px;

          .profile-photo {
            height: 165px;
          }
        }

        .form {
          .form-label {
            .autofill-bg-beige {
              &:-webkit-autofill,
              &:-webkit-autofill:hover,
              &:-webkit-autofill:focus {
                box-shadow: 0 0 0 200px $bg-mobile-beige inset;
                -webkit-box-shadow: 0 0 0 200px $bg-mobile-beige inset;
              }
            }
          }
        }
      }
    }
  }
}
