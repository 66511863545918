@import '../../../../../styles/variables';
@import '../../../../../styles/flex';
@import '../../../../../styles/common';
@import '../../../../../styles/icons';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';

.tile-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
  text-align: center;

  @media screen and (max-width: 1588px) {
    grid-template-columns: auto auto auto;
  }

  @media screen and (max-width: 1240px) {
    justify-content: center;
    grid-template-columns: auto auto;
  }

  @media screen and (max-width: 900px) {
    justify-content: center;
    grid-template-columns: auto auto auto;
  }

  @media screen and (max-width: 762px) {
    justify-content: center;
    grid-template-columns: auto auto;
  }

  @media screen and (max-width: 500px) {
    justify-content: center;
    grid-template-columns: auto;
  }

  .product-item {
    position: relative;
    width: 249px;
    height: 305px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: default;

    @media screen and (max-width: 1440px) {
      margin: 7px;
    }

    @media screen and (max-width: 500px) {
      margin: 5px;
      width: 165px;
      height: 205px;
    }

    &_image {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      cursor: pointer;

      img {
        width: 140px;
        height: 140px;
        margin-bottom: 15px;
      }

      .icon_compare,
      .icon_compare-solid {
        opacity: 0;
        z-index: -20;
        padding: 5px;
      }

      .icon_no_img {
        width: 65%;
        position: absolute;
      }

      svg.hide {
        opacity: 0;
        z-index: -20;
      }

      span {
        padding-right: 2px;
      }
    }

    &_description {
      font-family: Montserrat;
      font-size: 16px;
      line-height: 19.5px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    &_sku {
      font-family: Montserrat;
      font-size: 14px;
      line-height: 17.07px;
      font-weight: normal;
      margin-bottom: 15px;
      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    &_price {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 24px;
      line-height: 28.13px;
      font-weight: 700;
      margin-bottom: 20px;
      &_head{
        font-size: 14px;
      }
    }

    &_info-hover-block {
      display: flex;
      // display: none;
      flex-direction: column;
      background-color: $beige5;
      padding-top: 5px;
      outline: 0.5px solid $beige3;
      font-family: Montserrat;
      font-size: 16px;
      line-height: 19.5px;
      font-weight: 600;
      margin-bottom: 10px;
      position: absolute;
      width: 100%;
      top: 100%;
      z-index: 21;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;

      .btn {
        svg {
          path {
            fill: initial;
            transition: all 0.3s ease;
          }
        }

        &:hover {
          svg {
            path {
              fill: $gray2;
              transition: all 0.3s ease;
            }
          }
        }
      }

      .btn:first-child {
        margin: 20px 20px 5px 20px;
        padding: 10px 20px;
        justify-content: center;
        border: none;
      }

      .btn:last-child {
        margin: 0 auto 10px;
        justify-content: center;
      }
    }
  }

  &_mobile {
    .product-item {
      margin: 10px;
      border: 0.5px solid $beige3;
      width: 210px;
      height: 240px;

      &_active {
        outline: 0.5px solid $beige3;

        .product-item_info-hover-block {
          opacity: 1;
          visibility: visible;
        }
      }

      &_image {
        img {
          width: 90px;
          height: 90px;
        }

        .icon_compare,
        .icon_compare-solid {
          opacity: 1;
          z-index: 20;
        }
      }

      &_description {
        font-size: 14px;
        line-height: 17.07px;
      }

      &_sku {
        font-size: 12px;
        line-height: 14.63px;
      }

      &_price {
        font-size: 18px;
        line-height: 21.09px;
      }

      &_info-hover-block {
        font-size: 14px;
        line-height: 17.07px;
      }
    }
  }

  &_pc {
    .product-item {
      &:hover {
        outline: 0.5px solid $beige3;

        .product-item_info-hover-block {
          opacity: 1;
          visibility: visible;

          // .btn:hover {
          //   svg {
          //     path {
          //       fill: $gray2;
          //       transition: all 0.3s ease;
          //     }
          //   }
          // }
        }
      }
    }
  }
}
