@import '../../../../styles/variables';
@import '../../../../styles/common';
@import '../../../../styles/grid';

.account-favorite-page {
  min-height: 100%;
  width: 100%;
  position: relative;
  @include display-flex;
  background-color: $beige;

  @media screen and (max-width: 1024px) {
    background-color: $bg-mobile-beige;
  }
  * {
    box-sizing: border-box;
  }

  &__content {
    width: 100%;
    background-color: $beige;
    padding-right: 7.291vw;
    @include display-flex;
    @include flex-direction(column);
    min-height: 100%;
    padding-top: $pt-desktop;
    padding-left: $pl-desktop;
    @media screen and (max-width: 1024px) {
      padding-right: 0;
      padding-top: 100px;
      padding-left: 88px;
    }
    .parent {
      width: 100%;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 2.604vw;
    @media screen and (max-width: 1024px) {
      height: 44px;
    }
  }
  &__title {
    @include text-base(
      $size: 2.5vw,
      $family: 'Roboto Condensed',
      $color: $dark-brown
    );
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    @media screen and (max-width: 1024px) {
      font-size: 24px;
    }
  }

  &__items-list {
    display: flex;
    flex-direction: column;
    margin-top: 2.6vw;

    &::-webkit-scrollbar {
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      width: 3px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      width: 3px;
      background-color: $gray3;
    }

    @media screen and (max-width: 1024px) {
      margin-top: 32px;
      width: 100%;
      overflow-x: auto;
      padding-bottom: 24px;
    }
  }

  &__item {
    display: flex;
    height: 5.729vw;
    align-items: center;
    padding: 1.3vw 0;
    border-bottom: 0.052vw solid $beige2;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      padding: 13px 0;
      height: 70px;
      border-bottom: 1px solid $beige2;
      min-width: 720px;
    }

    .icons-container {
      .btn {
        &:hover {
          svg {
            path {
              fill: $white;
              transition: all 200ms ease-in;
            }
          }
        }

        svg {
          width: 24px;
          height: 20px;
          transition: all 200ms ease-in;
        }
      }
    }
  }
  &__item-image {
    width: 3.646vw;
    height: 3.646vw;
    img {
      width: 100%;
      height: auto;
      margin-top: 0.26vw;
    }
    @media screen and (max-width: 1024px) {
      width: 50px;
      height: 50px;
      img {
        margin-top: 4px;
      }
    }
  }
  &__item-characteristics {
    display: flex;
    flex-direction: column;
    padding-left: 1.562vw;
    height: 100%;
    justify-content: space-around;
    @media screen and (max-width: 1024px) {
      padding-left: 10px;
    }
  }
  &__diamond-sku {
    @include text-base(
      $size: 0.937vw,
      $family: 'Montserrat',
      $color: $brown-black,
      $weight: $bold
    );
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
  }
  &__diamond-characteristics {
    margin-top: 0.521vw;
    display: flex;

    li {
      padding: 0 1.04vw;
      border-right: 0.052vw solid $beige2;
      @include text-base(
        $size: 0.729vw,
        $family: 'Montserrat',
        $color: $dark-brown
      );
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        border-right: none;
      }
    }

    @media screen and (max-width: 1024px) {
      margin-top: 10px;
      li {
        padding: 0 10px;
        border-right: 1px solid $beige2;
        font-size: 12px;
      }
    }
  }
  &__left-block {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &__right-block {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn_circle {
      margin-right: 0.625vw;
    }
    @media screen and (max-width: 1024px) {
      width: 36%;
      .diamond-price {
        font-size: 16px;
      }
      .btn_circle {
        margin-right: 10px;
        width: 48px;
        height: 48px;
      }
    }
  }

  &__empty-list {
    margin-top: 2.5vw;
    padding-left: 7.291vw;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media screen and (max-width: 1024px) {
      padding-left: 0;
      padding-right: 20px;
    }
  }

  &__empty-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.562vw;
    img {
      height: 4.166vw;
      width: auto;
    }
    @media screen and (max-width: 1024px) {
      margin-bottom: 30px;

      img {
        height: 64px;
      }
    }
  }

  &__empty-title {
    text-transform: uppercase;
    margin-bottom: 2.604vw;
    @include text-base(
      $size: 1.25vw,
      $family: 'Montserrat',
      $color: $brown-black,
      $weight: $bold
    );

    @media screen and (max-width: 1024px) {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }

  &__empty-subtitle {
    margin-bottom: 2.604vw;
    @include text-base($size: 0.833vw, $family: 'Montserrat', $color: $gray2);
    @media screen and (max-width: 1024px) {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }

  &__empty-btn {
    height: 3.95vw;
    width: 26.042vw;
    font-size: 0.833vw;
    @media screen and (max-width: 1024px) {
      height: 48px;
      width: 100%;
      max-width: 335px;
      font-size: 14px;
    }
  }
}
