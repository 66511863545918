@import '../../../../styles/flex';
@import '../../../../styles/variables';
@import '../../../../styles/common';

.content-main {
  &_block {
    &__jewelry {
      @include display-flex;
      @include justify-content(center);
      flex-grow: 1;
      margin-bottom: 15vh;
      position: relative;
      align-items: flex-end;
      width: 100%;

      @media (max-width: 1024px) {
        height: 80%;
        align-items: center;
        margin-top: initial;
      }

      @media screen and (max-width: 767px) {
        align-items: center;
        flex-grow: 0.5;
        height: 50%;
      }

      .img-container {
        height: 100%;
        max-height: 50.344vh;
        position: absolute;

        @media screen and (max-width: 1024px) {
          max-width: 100%;
          height: 55vh;
          margin-top: -50px;
        }

        @media screen and (max-width: 767px) {
          margin-top: -50px;
          height: 350px;
        }

        img {
          height: 100%;
          width: auto;
        }
      }
    }
  }

  #jewelry {
    .content-main_btn {
      position: absolute;
      top: 90vh;

      @media screen and (max-width: 1024px) {
        height: 80%;
      }

      @media screen and (max-width: 767px) {
        position: absolute;
        top: 75%;
        height: auto;
      }

      .btn_lg2 {
        padding: 19px 109px;

        @media (max-width: 767px) {
          padding: 19px 109px;
        }
      }
    }
  }

  .main-slide {
    &_jewelry {
      min-height: 578px;
      height: 1200px;

      @media screen and (max-width: 1024px) {
        height: 1300px;
      }

      @media screen and (max-width: 767px) {
        height: 800px;
      }

      @media screen and (max-width: 400px) {
        height: 700px;
      }
    }
  }

  @media (max-width: 767px) {
    .main-container-ios {
      .content-main_block__jewelry {
        .content-main_btn {
          top: 81vh;
        }
      }
    }
  }
}
