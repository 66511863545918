@import '../../styles/variables';
@import '../../styles/common';

.policy-page {
  &.grid {
    // Start css-grid building for Desktop
    display: grid;
    min-height: 100%;
    grid-template-areas: '. header header .' '. breadcrumbs . .' '. title link .' '. tabs tabs .' '. btn contact .';
    grid-template-columns: $margin-desktop 24.74vw auto $margin-desktop;
    grid-template-rows: $header-height-desktop $breadcrumbs-height-desktop $page-title auto $footer-height-desktop;
    background: linear-gradient(
      to right,
      $bg-beige 0%,
      $bg-beige 32%,
      white 32%,
      white 100%
    );

    .grid-area {
      &-header {
        grid-area: header;
        padding-left: $margin-desktop;
        padding-right: $margin-desktop;
      }

      &-breadcrumbs {
        grid-area: breadcrumbs;
      }

      &-link {
        grid-area: link;
        text-align: right;
      }

      &-page-title {
        grid-area: title;
        z-index: 9;
      }

      &-tabs {
        grid-area: tabs;
      }

      &-page-btn {
        grid-area: btn;
      }

      &-contact {
        grid-area: contact;
      }
      &-attention {
        display: none;
      }
    }

    // End css-grid building for Desktop

    .grid-area-tabs {
      .tabs_container {
        display: flex;

        .tabs-links {
          display: flex;
          flex-direction: column;
          width: 20%;
          margin-right: 15%;
          padding-top: 1.042vw;
          box-sizing: border-box;

          .tab__link {
            @include text-base($size: 0.833vw, $weight: bold);
            line-height: 1.2;
            letter-spacing: 0.052vw;
            transition: background 0.1s, color 0.1s;
            padding-top: 0.521vw;
            padding-bottom: 0.521vw;
            text-decoration: none;
            border-bottom: 0.104vw solid transparent;

            &:not(:last-child) {
              margin-bottom: 2.083vw;
            }

            &:hover {
              border-bottom-color: $yellow;
            }

            @media (pointer: coarse) {
              &:hover {
                border-bottom-color: transparent;
              }
            }

            &.active {
              border-bottom-color: $yellow;
            }
          }
        }

        .tabs-panels {
          width: 65%;
          height: calc(
            100vh -
              (
                #{$header-height-desktop} + #{$breadcrumbs-height-desktop} + #{$page-title} +
                  #{$footer-height-desktop}
              )
          );
          overflow-y: auto;
          box-sizing: border-box;
          padding-right: 2.083vw;
          margin-right: -2.083vw;

          .panel {
            &-header {
              display: flex;
              justify-content: space-between;
              padding-bottom: 1.563vw;

              &__title {
                @include text-base($size: 0.833vw, $weight: $bold);
                text-transform: uppercase;
              }
            }

            &-content {
              @include text-base($size: 0.833vw, $weight: $regular);
              line-height: 1.7;

              .sub-title {
                &_bold {
                  font-weight: $bold;
                  margin: 1.563vw 0 0.521vw;
                }

                &_medium {
                  font-weight: $medium;
                  margin: 0.521vw 0;
                }
              }

              p {
                margin: 0.26vw 0;
              }
            }
          }
        }
      }
    }

    .grid-area-page-btn {
      display: flex;
      justify-content: space-between;
      margin-top: 1.563vw;

      .btn {
        &:hover {
          svg {
            path {
              fill: $white;
              transition: $animation-global;
            }
          }
        }
      }

      svg {
        width: 1.833vw;
        height: 1.938vw;
        path {
          transition: $animation-global;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .policy-page {
    &.grid {
      // Start css-grid building for Laptop
      grid-template-columns: $margin-laptop 24.74vw auto $margin-laptop;
      grid-template-rows: $header-height-laptop $breadcrumbs-height-laptop $page-title auto 100px;
      // End css-grid building for Laptop

      .grid-area-header {
        padding-left: $margin-laptop;
        padding-right: $margin-laptop;
      }

      .grid-area-tabs {
        .tabs_container {
          .tabs-links {
            padding-top: 20px;

            .tab__link {
              font-size: 16px;

              &:not(:last-child) {
                margin-bottom: 30px;
              }
            }
          }

          .tabs-panels {
            height: calc(
              100vh - #{$header-height-laptop} - #{$breadcrumbs-height-laptop} -
                #{$page-title} - 100px
            );
            padding-right: 20px;
            margin-right: -20px;

            .panel {
              &-header {
                padding-bottom: 30px;

                &__title {
                  font-size: 16px;
                }
              }

              &-content {
                font-size: 14px;

                .sub-title {
                  &_bold {
                    margin: 25px 0 10px;
                  }

                  &_medium {
                    margin: 10px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .policy-page {
    &.grid {
      // Start css-grid building for mobile
      grid-template-areas: '. header .' '. breadcrumbs .' '. title .' '. link .' 'tabs tabs tabs' 'contact contact contact' 'btn btn btn';
      grid-template-columns: $margin-mobile auto $margin-mobile;
      grid-template-rows: $header-height-mobile $breadcrumbs-height-mobile min-content min-content auto min-content min-content;
      background: $bg-mobile-beige;
      // End css-grid building for mobile

      .grid-area-header {
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
      }

      .grid-area-page-title {
        margin-bottom: $margin-mobile;
      }

      .grid-area-tabs {
        background-color: $white;

        .tabs_container {
          flex-direction: column;

          .tabs-links {
            width: 100%;
            margin-right: 0;
            padding: $margin-mobile;
            box-shadow: $shadow-bottom-xs;

            .tab__link {
              padding-top: 4px;
              padding-bottom: 4px;
              border-bottom-width: 1.5px;

              &:not(:last-child) {
                margin-bottom: 20px;
              }
            }
          }

          .tabs-panels {
            width: 100%;
            height: auto;
            padding-right: 0;
            margin-right: 0;

            .panel {
              padding: 0 $margin-mobile;

              &-header {
                flex-direction: column;
                padding-top: 40px;
                padding-bottom: 20px;
              }

              &-content {
                p {
                  margin: 10px 0;
                }
              }
            }

            &.block-scrollbar::-webkit-scrollbar {
              width: 0;
            }
          }
        }
      }

      .grid-area-page-btn {
        margin-top: 0;
        background-color: $white;
        padding: $margin-mobile;
      }
    }
  }
}

@media (max-width: 823px) and (orientation: landscape) {
  .policy-page {
    &.grid {
      grid-template-areas: '. header header .' '. attention . .';
      grid-template-columns: $margin-desktop;
      grid-template-rows: $header-height-desktop;

      .grid-area {
        &-header {
          grid-area: header;
          padding-left: $margin-desktop;
          padding-right: $margin-desktop;
        }

        &-breadcrumbs {
          display: none;
        }

        &-link {
          display: none;
        }

        &-page-title {
          display: none;
        }

        &-tabs {
          display: none;
        }

        &-page-btn {
          display: none;
        }

        &-contact {
          display: none;
        }
        &-attention {
          display: flex;
          grid-area: attention;
          justify-content: center;
          margin-top: 15%;
          margin-left: 20%;
          width: 60%;
          @include text-base($size: 24px, $weight: $bold, $color: $yellow-dark);
        }
      }
    }
  }
}
