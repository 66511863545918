@import '../../../styles/variables';

.tooltip {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-transform: none;
  background: $beige3;
  border-radius: 10px;
  box-sizing: border-box;

  &--hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.tooltip__icon {
  flex-shrink: 0;
  margin-right: 10px;
}
