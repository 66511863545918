@import '../../styles/variables';
@import '../../styles/common';
@import '../../styles/flex';

$footer-desktop: 6vw;
$footer-laptop: 130px;

.header.education-page-header {
  grid-area: header;
  background-color: $bg-beige-85;
  box-shadow: 0 0.781vw 17px rgba(0, 0, 0, 0.08);
}

.education-page {
  width: 100%;
  height: 100%;
  @media screen and (max-width: 767px) {
    height: auto;
  }
  // The decision for adaptive height on some tablets with hidden/shown browser toolbars
  // https://developers.google.com/web/updates/2016/12/url-bar-resizing

  &.grid {
    // Start css-grid building for Desktop
    display: grid;
    grid-template-areas: 'header header header header' 'cover-image cover-image tabs .';
    grid-template-columns: $margin-desktop 1fr 1fr $margin-desktop;
    grid-template-rows: $header-height-desktop;

    .grid-area {
      &-page-title {
        grid-area: page-title;
        display: none;
      }

      &-cover-image-container {
        grid-area: cover-image;
        z-index: -1;
      }

      &-tabs {
        grid-area: tabs;
      }
      &-attention {
        display: none;
      }
    }

    // End css-grid building for Desktop

    .parent {
      height: 100%;
    }
    .grid-area-cover-image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
      height: calc(100vh + #{$header-height-desktop});
      margin-top: $header-height-desktop * -1;

      &.cutting {
        // background-image: url('../../../img/education-imgs/education-img-cover-cutting.webp');
      }

      &.color {
        // background-image: url('../../../img/education-imgs/education-img-cover-color.webp');
      }

      &.clarity {
        //background-image: url('../../../img/education-imgs/education-img-cover-clarity.webp');
      }

      &.carat {
        //  background-image: url('../../../img/education-imgs/education-img-cover-carat.webp');
      }
    }

    .grid-area-tabs {
      margin-top: 4vw;
      overflow-y: auto;
      padding-left: 8.854vw;
      padding-bottom: $footer-desktop;

      .tabs_container {
        .tabs-links {
          @include display-flex();
          margin-bottom: 2.083vw;
          box-sizing: border-box;

          .tab__link {
            @include text-base(
              $size: 0.729vw,
              $weight: medium,
              $color: $yellow
            );
            line-height: 1.2;
            letter-spacing: 0.052vw;
            transition: background 0.1s, color 0.1s;
            padding-top: 0.521vw;
            padding-bottom: 0.521vw;
            text-decoration: none;
            border-bottom: 0.104vw solid transparent;
            margin-right: 5.208vw;

            &:last-child {
              margin-right: 0;
            }

            &:hover {
              border-bottom-color: $yellow;

              @media (pointer: coarse) {
                border-bottom-color: transparent;
              }
            }

            &.active {
              border-bottom-color: $yellow;
            }
          }
        }

        .tabs-panels {
          .panel {
            &-header {
              width: 50%;

              &__title {
                @include text-base(
                  $size: 2.76vw,
                  $family: 'Roboto Condensed',
                  $weight: $bold
                );
                text-transform: uppercase;
                letter-spacing: 0.2vw;
                line-height: 1;
                margin: 1.563vw 0;
              }
            }

            &-text-block {
              @include text-base(
                $size: 0.833vw,
                $family: 'Futura Book',
                $weight: $regular,
                $color: $dark-blue-50
              );
              width: 90%;
              line-height: 1.7;

              p {
                margin-bottom: 0.521vw;
              }
            }

            &-imgs-wrap {
              @include display-flex();
              margin-top: 2.604vw;
              margin-bottom: 2.604vw;
              width: 100%;

              .three-imgs-in-block {
                margin-bottom: 1.042vw;

                .figures-wrap {
                  @include display-flex();

                  .figure {
                    .figure__img {
                      margin: 1.042vw 0.26vw;
                      width: 3vw;

                      &-big {
                        width: 5vw;
                      }
                    }

                    figcaption {
                      @include text-base($size: 0.729vw, $color: $gray6);
                      text-align: center;

                      &.near-colorless {
                        color: #a89e88;
                      }

                      &.noticeable-color {
                        color: #b1a07e;
                      }
                    }

                    svg {
                      height: auto;
                    }
                  }
                }

                .imgs-block__title {
                  @include text-base($size: 0.729vw, $color: $gray6);
                  text-align: center;

                  &.near-colorless {
                    color: #a89e88;
                  }

                  &.noticeable-color {
                    color: #b1a07e;
                  }
                }
              }

              .four-imgs-in-block {
                width: 100%;
                @include display-flex();
                @include align-items(flex-start);

                div {
                  width: 20%;
                  text-align: center;

                  img,
                  svg {
                    width: 80%;
                    height: auto;
                  }
                }
              }

              .figure__carat {
                display: flex;
                align-items: flex-end;
                @media (min-width: 767px) and (max-width: 1023px) {
                  transform: scale(0.9) translateX(-3vw);
                }
                @media (max-width: 375px) {
                  flex-wrap: wrap;
                  justify-content: center;
                }

                .figure {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  margin-right: 12px;
                  @media screen and (max-width: 1024px) {
                    margin-right: 8px;
                  }

                  &:last-of-type {
                    margin-right: 0;
                  }

                  figcaption {
                    @include text-base($size: 0.729vw, $weight: $light);
                    @media screen and (max-width: 1023px) {
                      @include text-base($size: 10px, $weight: $light);
                      margin-bottom: 10px;
                    }

                    &:first-of-type {
                      margin-bottom: 4px;
                    }
                  }

                  svg {
                    width: 100%;
                    margin-bottom: 10px;
                    opacity: 0.6;
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer {
      position: fixed;
      bottom: 0;
      right: 0;
      height: $footer-desktop;
      width: 50%;
      box-sizing: border-box;
      padding-top: 1.042vw;
      padding-left: 8.854vw;
      padding-right: $margin-desktop;
      background-color: $white;
    }
  }

  &-img__container {
    position: relative;
  }
}

@media screen and (max-width: 1024px) {
  .education-page {
    &.grid {
      // Start css-grid building for Laptop
      grid-template-columns: $margin-laptop 1fr 1fr $margin-laptop;
      grid-template-rows: $header-height-laptop;

      .grid-area-header {
        padding-left: $margin-laptop;
        padding-right: $margin-laptop;
      }

      .grid-area-cover-image {
        margin-top: $header-height-laptop * -1;
        height: calc(100% + #{$header-height-laptop});
      }

      // End css-grid building for Laptop

      .grid-area-tabs {
        margin-top: 40px;
        padding-bottom: $footer-laptop;
        padding-left: 4vw;

        .tabs_container {
          .tabs-links {
            @include justify-content(space-between);
            margin-bottom: 30px;

            .tab__link {
              font-size: 14px;
              padding-top: 10px;
              padding-bottom: 10px;
              border-bottom-width: 2px;
              margin-right: 0;
            }
          }

          .tabs-panels {
            margin-bottom: 20px;

            .panel {
              &-header {
                width: 100%;

                &__title {
                  font-size: 24px;
                  margin: 20px 0;
                }
              }

              &-text-block {
                width: 100%;
                font-size: 14px;

                p {
                  margin-bottom: 7px;
                }
              }

              &-imgs-wrap {
                @include flex-wrap(wrap);
                margin-top: 30px;
                margin-bottom: 30px;

                .three-imgs-in-block {
                  width: 100%;
                  margin-bottom: 20px;

                  .figures-wrap {
                    @include justify-content(space-around);
                    width: 100%;

                    .figure {
                      .figure__img {
                        width: auto;
                        margin: 10px;

                        &-big {
                          width: 7vw;
                        }
                      }

                      figcaption {
                        font-size: 14px;
                      }
                    }
                  }

                  .imgs-block__title {
                    font-size: 14px;
                  }
                }

                .four-imgs-in-block {
                  div {
                    width: 25%;

                    img {
                      width: 90%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .footer {
        padding-top: 20px;
        padding-right: $margin-laptop;
        height: $footer-laptop;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .education-page {
    position: initial;

    &.grid {
      // Start css-grid building for Mobile
      grid-template-areas: 'header header header' 'page-title page-title page-title' 'cover-image cover-image cover-image' 'tabs tabs tabs';
      grid-template-columns: $margin-mobile auto $margin-mobile;
      grid-template-rows: $header-height-mobile auto 300px min-content;
      background-color: $bg-mobile-beige;

      .grid-area-header {
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
        background-color: $bg-mobile-beige;
        box-shadow: none;
      }

      // End css-grid building for Mobile

      .grid-area-page-title {
        opacity: 0;
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
        margin-bottom: $margin-mobile;
      }

      .grid-area-cover-image-container {
        z-index: 10;
        //  display: none;
      }

      .grid-area-cover-image {
        margin-top: $header-height-mobile * -1;
        height: calc(100% + #{$header-height-mobile});
      }

      .grid-area-tabs {
        height: initial;
        overflow-y: initial;
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
        background-color: $white;
        margin-top: 0;

        .tabs_container {
          width: 100%;

          .tabs-links {
            @include justify-content(space-between);
            margin-top: 30px;
          }

          .tabs-panels {
            .panel {
              &-header {
                text-align: center;
                width: 100%;

                &__title {
                  font-size: 24px;
                  margin: 20px 0;
                }
              }

              &-text-block {
                width: 100%;
                font-size: 14px;

                p {
                  margin-bottom: 7px;
                }
              }

              &-imgs-wrap {
                @include justify-content(center);

                .three-imgs-in-block {
                  .figures-wrap {
                    .figure {
                      .figure__img {
                        &-big {
                          width: 18vw;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .footer {
        width: 100%;
        padding-top: 20px;
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
        box-shadow: $shadow-top-xs;
      }
    }
  }
}

@media (max-width: 823px) and (orientation: landscape) {
  .education-page {
    &.grid {
      grid-template-areas: '. header header .' '. attention . .';
      grid-template-columns: $margin-desktop;
      grid-template-rows: $header-height-desktop;

      .grid-area {
        &-header {
          grid-area: header;
          padding-left: $margin-desktop;
          padding-right: $margin-desktop;
        }

        &-page-title {
          display: none;
        }

        &-cover-image-container {
          display: none;
        }
        &-tabs {
          display: none;
        }

        &-attention {
          display: flex;
          grid-area: attention;
          justify-content: center;
          margin-top: 10%;
          margin-left: 20%;
          width: 60%;
          @include text-base($size: 24px, $weight: $bold, $color: $yellow-dark);
        }
      }
    }
  }
}
