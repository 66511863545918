.light-loader {
  width: 16.5vw;
  height: 13.66vw;
  white-space: pre;
  animation: none;
  @media screen and (max-width: 767px) {
    width: 40.5vw;
    height: 40.66vw;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 27.11vw;
    height: 27vw;
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  &__gradient {
    stop {
      stop-color: #fff;
    }
  }

  &__mask {
    mask-type: alpha;
  }

  &__group-1 {
    transform: translate(50%, 50%);
  }

  &__layer {
    transform: rotate(-30deg);
    animation: 2.683s linear infinite both light-loader-animation;
    offset-path: path(
      'M61,16C102.167,67.833,308,327,308,327C308,327,102.167,67.833,61,16L61,16'
    );
    offset-rotate: 0deg;

    & > g {
      transform: translateY(5%) rotate(90deg) scale(0.18, 13);
    }
  }
}

@keyframes light-loader-animation {
  0% {
    offset-distance: 0%;
  }
  50% {
    offset-distance: 50%;
  }
  100% {
    offset-distance: 100%;
  }
}
