/* https://benjaminhorn.io/code/simple-sass-mixins-for-generating-grids-similar-to-bootstrap/
 * Generates classes for a grid of columns, prefixed by size.
 * The result will be .col-[size]-[col]-[total-columns] E.g. .col-xs-6-12
 *
 * Wrap the row of columns with .row
 *
 * @param {string}	$size			The name of the size target (e.g. xs, sm, md, lg, xl, etc.)
 * @param {int}		$grid-columns	Amount of columns
 */
@mixin grid-generator($size, $grid-columns) {
  @for $i from 1 through $grid-columns {
    $percent: percentage($i / $grid-columns);

    .col-#{$size}-#{$i} {
      width: $percent;
      float: left;
      padding-left: 15px;
      padding-right: 15px;
      box-sizing: border-box;
    }

    .offset-#{$size}-#{$i} {
      margin-left: $percent;
    }
  }
}

/*
 * A mixin targeting a specific device width and larger.
 * These are the available targets: 'xs', 'sm', 'md', 'lg', 'xl'
 *
 * @param {string} $media The target media
 */
@mixin respond-from($media) {
  @if $media == xs {
    @media (min-width: 0px) {
      @content;
    }
  } @else if $media == sm {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $media == md {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $media == lg {
    @media (min-width: 1440px) {
      @content;
    }
  } @else if $media == xl {
    @media (min-width: 1800px) {
      @content;
    }
  }
}

@include grid-generator(xs, 12);

@include respond-from(sm) {
  @include grid-generator(sm, 12);
}

@include respond-from(md) {
  @include grid-generator(md, 12);
}

@include respond-from(lg) {
  @include grid-generator(lg, 12);
}

@include respond-from(xl) {
  @include grid-generator(xl, 12);
}
