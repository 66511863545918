@import '../../../../../styles/variables';
@import '../../../../../styles/common';
@import '../../../../../styles/grid';

.orders-current-window {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-bottom: 4.167vw;
  overflow-y: auto;
  @media screen and (max-width: 1024px) {
    padding-bottom: 40px;
  }
  &,
  & > * {
    box-sizing: border-box;
  }

  .modal-window__btn-close {
    z-index: 9 !important;
  }

  &__header-logo {
    position: absolute;
    width: 8.854vw;
    top: 2.8vw;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 1024px) {
      width: 100px;
      top: 24px;
    }
  }

  &__header {
    position: relative;
    width: 100%;
    background-color: $beige;
    padding: 3.73vw 7.292vw 3.125vw;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
      padding: 80px 60px 30px;
    }
    @media screen and (max-width: 767px) {
      padding: 80px 20px 30px;
    }
  }

  &__header-info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &_to-right {
      align-items: flex-end;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__header-info-title {
    @include text-base(
      $size: 2.5vw,
      $family: 'Roboto Condensed',
      $weight: bold,
      $color: $black
    );
    @media screen and (max-width: 1024px) {
      font-size: 24px;
      margin-bottom: 2px;
    }
  }

  &__header-info-subtitle {
    margin-bottom: 0.521vw;
    @include text-base(
      $size: 0.729vw,
      $family: 'Montserrat',
      $weight: $light,
      $color: $gray2
    );
    @media screen and (max-width: 1024px) {
      font-size: 12px;
    }
  }

  &__header-info-date {
    @include text-base(
      $size: 0.833vw,
      $family: 'Roboto Condensed',
      $weight: bold,
      $color: $black
    );
    margin-bottom: 2.083vw;
    span {
      margin-top: 0.5vw;
      display: block;
      width: 100%;
    }
    @media screen and (max-width: 1024px) {
      font-size: 14px;
      span {
        margin-top: 6px;
      }
    }
  }

  &__header-info-text {
    @include text-base($size: 0.833vw, $weight: $light, $color: $black);
    line-height: 1.6;
    &_to-right {
      text-align: end;
    }
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
  }

  &__header-info-additional {
    margin-top: 2.083vw;
    position: relative;
    padding-left: 36px;
    box-sizing: border-box;
    @include text-base(
      $size: 0.833vw,
      $family: 'Montserrat',
      $weight: $light,
      $color: $black
    );
    line-height: 1.6;
    svg {
      position: absolute;
      left: 0;
      top: 2px;
    }
    span {
      color: $yellow;
    }

    @media screen and (max-width: 1024px) {
      font-size: 14px;
      margin-top: 15px;
      width: 300px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 320px;
    }
  }

  &__header-seller {
    margin: 3.958vw 0 0.417vw;
    @include text-base(
      $size: 0.833vw,
      $family: 'Montserrat',
      $weight: bold,
      $color: $black
    );
    @media screen and (max-width: 1024px) {
      font-size: 14px;
      margin: 10px 0 35px;
    }
  }

  &__header-prev-cost {
    text-decoration: line-through;
    @include text-base(
      $size: 1.042vw,
      $family: 'Montserrat',
      $weight: bold,
      $color: $yellow
    );
    margin-top: 0.833vw;
  }

  &__details {
    width: 100%;
    padding: 1.38vw 7.292vw;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      padding: 10px 40px;
      justify-content: space-around;
    }

    @media screen and (max-width: 767px) {
      padding: 10px 20px;
      justify-content: space-between;
    }
  }

  &__details-title {
    @include text-base(
      $size: 1.25vw,
      $family: 'Montserrat',
      $weight: bold,
      $color: $brown-black
    );
    margin-top: 1.823vw;
    margin-bottom: 1.042vw;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 12px;
    }
  }

  &__details-item {
    width: 20.823vw;

    @media screen and (max-width: 1024px) {
      width: 335px;
      max-width: 45%;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__details-field {
    @include text-base(
      $size: 0.833vw,
      $family: 'Montserrat',
      $weight: bold,
      $color: $brown-black
    );
    line-height: 1.042vw;
    border: 0;
    border-bottom: 0.052vw solid $beige2;
    outline: 0;
    border-radius: 0;
    padding: 0 0 0.56vw;
    width: 100%;
    margin-top: 1.719vw;
    display: flex;
    justify-content: space-between;
    @include text-base($size: 0.833vw, $weight: $light, $color: $gray2);

    @media screen and (max-width: 1024px) {
      font-size: 12px;
      line-height: 20px;
      border-bottom: 1px solid $beige2;
      padding: 0 0 4px;
      margin-top: 10px;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  &__details-field-value {
    color: $black;
  }

  &__table {
    padding: 0 7.292vw;
    width: 100%;
    background-color: $beige;

    @media screen and (max-width: 1024px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 20px;
      margin-top: 20px;
    }
  }
  &__table-row {
    position: relative;
    height: 14.323vw;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 0.052vw solid $beige2;
    &:last-of-type {
      border-bottom: none;
    }
    @media screen and (max-width: 1024px) {
      height: 245px;
      border-bottom: 1px solid $beige2;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
    }
  }
  &__table-row-image {
    width: 5.99vw;
    height: auto;
    margin-top: 1.719vw;
    @media screen and (max-width: 1024px) {
      width: 70px;
      margin-top: 10px;
    }
    @media screen and (max-width: 767px) {
      position: absolute;
      top: 10px;
      width: auto;
      height: 50px;
    }
  }
  &__table-row-stone-details {
    margin-left: 2.083vw;
    @media screen and (max-width: 1024px) {
      margin-left: 20px;
    }
    @media screen and (max-width: 767px) {
      margin-left: 0;
      padding-left: 20px;
    }
  }
  &__details-info-title {
    font-size: 1.25vw;
    font-weight: 700;
    margin-bottom: 1.042vw;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    @media screen and (max-width: 1024px) {
      font-size: 16px;
      margin-bottom: 24px;
    }
    @media screen and (max-width: 767px) {
      padding-left: 36px;
      margin-top: 32px;
      margin-bottom: 30px;
    }
  }
  &__details-info-column {
    width: 50%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 360px;
    }
  }
  &__table-row-stone-details {
    width: 38%;
    @media screen and (max-width: 1024px) {
      width: 50%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  &__details-info-item {
    display: flex;
    padding: 0.3vw 0;
    span {
      width: 50%;
      padding-right: 0.416vw;
      color: $gray2;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }
    span + span {
      color: $dark-brown;
    }

    @media screen and (max-width: 1024px) {
      padding: 6px 0;
      span {
        padding-right: 8px;
      }
    }
  }
  &__details-info-item-pdf {
    margin-left: 0.208vw;
    width: 0.625vw;
    height: 0.625vw;
    display: block;
    background: url('../../../../../img/svgicons/file-certificate.svg') 50% /
      100% no-repeat;
  }
  &__details-info-item-link {
    display: inline-flex;
    color: $dark-brown !important;
    text-decoration: none !important;
    border-bottom: 1px solid $gray2;
  }
  &__details-info {
    display: flex;
    justify-content: space-between;
    @include text-base(
      $size: 0.729vw,
      $family: 'Montserrat',
      $weight: $light,
      $color: $gray2
    );
    @media screen and (max-width: 1024px) {
      font-size: 12px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 30px;
    }
  }

  &__table-row-total-block {
    position: relative;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    height: 100%;
    @include text-base(
      $size: 0.833vw,
      $family: 'Montserrat',
      $weight: $light,
      $color: $gray2
    );

    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
      width: 100%;
      max-width: 360px;
      justify-content: flex-start;
      padding: 0 20px;
    }
  }

  &__table-row-total-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 7.292vw;
    color: $black;
    span {
      @include text-base(
        $size: 1.25vw,
        $family: 'Roboto Condensed',
        $weight: bold,
        $color: $brown-black
      );
      font-weight: 700;
      margin-top: 1.521vw;
    }

    @media screen and (max-width: 1024px) {
      width: 86px;
      span {
        font-size: 16px;
        margin-top: 20px;
      }
    }

    @media screen and (max-width: 767px) {
      width: 30%;
      align-items: center;
      &:first-of-type {
        align-items: flex-start;
      }
      &:last-of-type {
        align-items: flex-end;
      }
      span {
        margin-top: 10px;
      }
    }
  }

  &__footer {
    width: 100%;
    padding: 3.73vw 7.292vw 3.125vw;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 1024px) {
      padding: 40px 60px 8px;
    }
    @media screen and (max-width: 767px) {
      padding: 30px 20px 0;
      .orders-current-window__header-info-subtitle {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .orders-current-window__header-info {
      display: flex !important;
    }
  }

  &__footer-phone {
    margin-top: 1.04vw;
    position: relative;
    padding-left: 35px;
    width: 360px;
    @include text-base(
      $size: 0.833vw,
      $family: 'Montserrat',
      $weight: $light,
      $color: $black
    );
    line-height: 1.6;
    svg {
      position: absolute;
      left: 0;
      top: 4px;
      min-width: 24px;
      min-height: 20px;
    }
    span {
      color: $yellow;
    }

    @media screen and (max-width: 1024px) {
      font-size: 14px;
      margin-top: 15px;
      width: 340px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__footer-info {
    border-top: 0.052vw solid $beige2;
    width: 13.02vw;
    display: flex;
    flex-direction: column;
    margin-top: 1.25vw;

    @media screen and (max-width: 1024px) {
      width: 230px;
      margin-top: 20px;
      margin-bottom: 40px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      align-items: flex-end;
    }
  }

  &__footer-info-item {
    margin-top: 1.042vw;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @include text-base(
      $size: 0.833vw,
      $family: 'Montserrat',
      $weight: $light,
      $color: $gray2
    );
    span + span {
      @include text-base(
        $size: 1.25vw,
        $family: 'Roboto Condensed',
        $weight: bold,
        $color: $brown-black
      );
    }

    @media screen and (max-width: 1024px) {
      margin-top: 16px;
      font-size: 12px;
      span + span {
        font-size: 16px;
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 360px;
    }
  }

  &__footer-action {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .btn_brown {
    width: 26.04vw !important;

    @media screen and (max-width: 1024px) {
      width: 335px !important;
    }
    @media screen and (max-width: 767px) {
      width: 100% !important;
      max-width: 360px;
    }
  }

  &__loader-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(#fff, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
}
