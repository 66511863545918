@import '../../styles/variables';
@import '../../styles/flex';
@import '../../styles/common';
@import '../../styles/icons';
@import '../../styles/animations';
@import '../../styles/functions';

$diamond-sku-height: 2.76vw;

.product-detail-page {
  position: relative;
  min-height: 100%;
  padding-top: $header-height-desktop;
  margin: 0 $margin-desktop;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.2s;
  flex-wrap: nowrap;

  & > * {
    box-sizing: border-box;
  }

  @media screen and (max-width: 1024px) {
    padding-top: $header-height-laptop;
    margin: 0 20px;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-top: $header-height-mobile;

    .product-detail-page__breadcrumbs {
      order: 1;
    }
    .product-detail-page__product-image__container {
      order: 2;
    }
    .product-detail-page__product-features__container {
      order: 3;
    }
    .product-detail-page__product-info {
      order: 3;
      padding-bottom: 30px;
    }
    .product-detail-page__product-price {
      order: 4;
    }
    .matching-band-block {
      order: 5;
    }
    .product-detail-page__product-features {
      order: 6;

      .product-detail-page__details-title {
        margin-top: 30px;
      }
    }
    .product-detail-page__product-details {
      order: 6;
    }
    .product-detail-page__page-btn {
      order: 99;
    }
  }

  &__header {
    grid-area: header;
    padding-left: $margin-desktop;
    padding-right: $margin-desktop;

    @media screen and (max-width: 1024px) {
      padding-left: $margin-laptop;
      padding-right: $margin-laptop;
    }

    @media screen and (max-width: 767px) {
      padding-left: $margin-mobile;
      padding-right: $margin-mobile;
    }
  }

  &__breadcrumbs {
    width: 100%;
    z-index: 9;
    position: absolute;
    left: 0;
    padding-top: 0.78vh;

    @media screen and (max-width: 767px) {
      padding-left: $margin-mobile;
      padding-right: $margin-mobile;
      margin-bottom: 10px;
      position: relative;
      padding-top: 20px;
    }
  }

  &__set-hint {
    position: absolute;
    right: 13px;
    z-index: 99;
    width: 68%;

    @media (max-width: 767px) {
      position: static;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__view-btns-wrap {
    margin-right: 1.604vw;
    @include display-flex();
    @include align-items(flex-end);

    @media screen and (max-width: 1024px) {
      margin: 0 20px;
    }

    .image {
      min-height: 100px;
    }
  }

  &__product-info {
    margin-top: calc(
      (#{$header-height-desktop} + #{$breadcrumbs-height-desktop}) * -1
    );
    padding-top: calc(
      #{$header-height-desktop} + #{$breadcrumbs-height-desktop} + 1.56vw
    );
    padding-left: 3.125vw;
    padding-right: 3.125vw;

    .diamond-sku {
      &.decoration {
        &:after {
          position: static;
          margin-top: 1.302vw;
          height: 4px;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      margin-top: calc(
        (#{$header-height-laptop} + #{$breadcrumbs-height-laptop}) * -1
      );
      padding-top: calc(
        #{$header-height-laptop} + #{$breadcrumbs-height-laptop} + 20px
      );
      padding-left: 22px;
      padding-right: 22px;

      .diamond-sku {
        &.decoration {
          &:after {
            position: static;
            margin-top: 20px;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
      padding-top: 30px;
      background-color: #fff;
    }
  }

  &__product-details {
    width: 33%;
    flex-grow: 1;
    padding-left: 3.125vw;
    padding-top: 1.56vw;
    opacity: 1;
    transition: 0.3s;

    &_with-set-hint {
      padding-top: calc(1.56vw + 100px);
    }

    @media screen and (max-width: 1024px) {
      padding-left: 22px;
      padding-right: 0;
      padding-top: 20px;
      width: 36%;
      box-sizing: border-box;

      &_with-set-hint {
        padding-top: calc(20px + 100px);
      }

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    @media screen and (max-width: 767px) {
      background-color: $beige;
      padding-right: 22px;
      width: 100%;
    }
  }

  &__page-btn {
    grid-area: page-btn;
    display: none;

    @media screen and (max-width: 767px) {
      display: block;
      background-color: $beige;
      padding-left: $margin-mobile;
      padding-right: $margin-mobile;
    }
  }

  &__back-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
    @include text-base($size: 0.833vw);
    line-height: 1;
    font-weight: 700;
    box-sizing: border-box;

    svg {
      height: 0.833vw;
      min-height: auto;
      min-width: auto;
      margin-right: 0.833vw;
    }

    @media screen and (max-width: 1024px) {
      font-size: 14px;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 20px;
      }
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__product-image-header {
    width: 100%;
    min-height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.56vw;
    margin-top: 1.302vw;

    @media screen and (max-width: 1024px) {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  &__product-image {
    height: max-content;
    @include display-flex;
    @include flex-direction(column);
    @include align-items(start);
    @include justify-content(center);
    position: relative;
    overflow: hidden;
    padding-top: 1.56vw;
    transition: 0.3s;

    @media screen and (max-width: 1024px) {
      padding-top: 20px;
    }

    @media screen and (max-width: 767px) {
      padding-top: 10px;
      @include align-items(center);
    }

    &__container {
      width: 33%;

      @media screen and (max-width: 1024px) {
        width: 30%;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &__product-images-wrap {
    width: 21.09vw;
    height: 21.09vw;
    margin-bottom: 1.25vw;
    @include display-flex();
    @include align-items(center);
    @include justify-content(center);
    position: relative;

    @media screen and (max-width: 1024px) {
      margin: 0 auto;
      width: 200px;
      height: 208px;
      margin-bottom: 12px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    &-stone {
      @media screen and (max-width: 767px) {
        display: block;
      }
    }

    .product-image {
      max-width: 100%;
      max-height: 100%;
      transition: transform 0.3s ease-in, opacity 0.2s ease-in;

      &_notDisplay {
        display: none;

        @media screen and (max-width: 767px) {
          display: inline;
          max-width: 100%;
          max-height: 100%;
          transition: transform 0.3s ease-in, opacity 0.2s ease-in;
        }
      }
    }

    .view {
      width: 100%;
      height: 700px;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin: 0;
        height: 450px;
      }

      &_notDisplay {
        display: none;

        @media screen and (max-width: 767px) {
          display: inline;
          width: 500px;
          height: 700px;
          transform: scale(0.55);
          margin-top: 120px;
          margin-bottom: 0;
        }
      }
    }
  }

  &__product-sprite {
    width: 21.09vw;
    height: 21.09vw;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top:20%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1200px) {
      top: 30%;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 208px;
      top: 35%;

      @media (pointer: coarse) {
        cursor: pointer;
      }
    }

    @media screen and (max-width: 767px) {
      width: 45vw;
      height: 45vw;
      display: none;
    }

    &_measLength {
      background-position: 0 0;
    }

    &_measWidth {
      background-position: 0 -20vw;
    }

    &_measDepth {
      background-position: 0 -40vw;
    }

    &_depthPercent {
      background-position: 0 -80vw;
    }

    &_table {
      background-position: 0 -100vw;
    }

    svg {
      width: auto;
      height: 100%;
    }
  }

  &__product-images-wrap-3d {
    height: fit-content;
    width: 100%;

    video {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      display: flex;
    }

    @media screen and (max-width: 1024px) {
      width: calc(100% - 20px);
      margin: 0;
    }

    &__container {
      width: 80%;
    }

    ~ .product-detail-page__product-sprite {
      position: relative !important;
    }
  }

  &__product-features {
    background-color: $bg-beige;
    padding-left: 3.125vw;
    padding-right: 3.125vw;

    @media screen and (max-width: 1024px) {
      padding-left: 22px;
      padding-right: 22px;
    }

    @media screen and (max-width: 767px) {
      background-color: $beige;
    }

    &__container {
      position: relative;
      background-color: #fbfaf8;
      width: 33%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -100vh;
        width: 100%;
        height: 100%;
        min-height: 999vh;
        background-color: $bg-beige;
        z-index: -1;

        @media screen and (max-width: 767px) {
          content: none;
        }
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      &_with-set-hint {
        padding-top: 100px;
      }
    }

    .label-text {
      display: none;
    }

    .select__inner {
      margin-top: 0.52vw !important;
    }
  }

  &__product-features-list {
    margin: 1.25vw 0 1.302vw;

    @media screen and (max-width: 1024px) {
      margin-top: 10px;
      margin-bottom: 20px;
    }

    li {
      max-width: 22.07vw;
      border-bottom: 0.089vw solid $beige2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 2.604vw;

      @media screen and (max-width: 1024px) {
        max-width: none;
        border-bottom: 1px solid $gray5;
        height: 40px;
      }

      > span {
        display: inline-block;
        @include text-base($size: 0.833vw, $color: $gray2);

        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          float: right;
          color: $brown-black;
          display: flex;
          align-items: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        @media screen and (max-width: 1024px) {
          display: inline-block;
          font-size: 14px;
        }
      }
    }
  }

  &__product-price {
    padding-left: 3.125vw;
    padding-right: 3.125vw;
    display: flex;
    flex-direction: column;
    padding-bottom: 3.646vw;

    @media screen and (max-width: 1024px) {
      @include display-flex;
      @include flex-direction(column);
      @include align-items(flex-start);
      @include justify-content(space-between);
      padding-left: 22px;
      padding-right: 22px;
    }

    @media screen and (max-width: 767px) {
      background-color: $beige;
      padding-left: 0;
      padding-right: 0;
    }

    .btn-wrap {
      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      @media screen and (max-width: 767px) {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        padding: 0 22px;
      }
    }

    .action-wrap {
      padding-left: 1.125vw;
      padding-right: 1.125vw;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        background-color: #fff;
        padding: 0 22px;
        margin-bottom: 17px;
        box-sizing: border-box;
      }

      .btn {
        width: auto;

        &:hover {
          svg {
            path {
              fill: $gray2;
              transition: all 200ms ease-in;
            }
          }
        }
      }

      svg {
        width: 24px;
        height: 20px;

        path {
          transition: all 200ms ease-in;
        }
      }
    }
  }

  &__product-set-prices {
    background-color: $bg-beige;

    @media screen and (max-width: 767px) {
      background-color: $beige;
    }
  }

  &__product-set-prices-list {
    margin: 1.25vw 0 1.302vw;

    @media screen and (max-width: 1024px) {
      margin-top: 10px;
      margin-bottom: 20px;
    }

    li {
      max-width: 22.07vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 2.604vw;

      @media screen and (max-width: 1024px) {
        max-width: none;
        border-bottom: 1px solid $gray5;
        height: 40px;
      }

      > span {
        display: inline-block;
        @include text-base($size: 0.833vw, $color: $gray2);

        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          float: right;
          color: $brown-black;
          display: flex;
          align-items: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        @media screen and (max-width: 1024px) {
          display: inline-block;
          font-size: 14px;
        }
      }

      &:last-child {
        border-bottom: 0.089vw solid $beige2;
      }
    }
  }

  &__product-price-wrap {
    @include display-flex;
    @include align-items(center);
    @include justify-content(space-between);
    flex-wrap: wrap;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: baseline;

      > .btn {
        order: 2;

        svg {
          margin-right: 12px;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      justify-content: space-between;

      .diamond-price {
        font-size: 24px;
      }

      .btn-wrap {
        margin-top: 30px;
        width: 100%;
      }
    }

    .btn_bordered-dark-gray {
      &:hover {
        svg {
          path {
            fill: $white;
            transition: all 200ms ease-in;
            @media screen and (max-width: 767px) {
              fill: $dark-brown;
              transition: all 200ms ease-in;
            }
          }
        }
      }

      &:disabled {
        svg {
          path {
            fill: $white;
            transition: all 200ms ease-in;
          }
        }
      }

      svg {
        path {
          transition: all 200ms ease-in;
        }
      }

      &.added {
        background: $dark-brown;
        color: $white;
        svg {
          path {
            fill: $white;
          }
        }

        &:hover {
          background: $gray2;
          border-color: $gray2;
        }
      }
    }
  }

  &__details-wrap {
    width: 100%;
    height: 100%;
    padding-right: 2.604vw;
    margin-right: -2.604vw;

    @media screen and (max-width: 1024px) {
      padding-right: 12px;
      margin-right: 0;

      .details-title {
        font-size: 16px;
        padding-bottom: 20px;
      }
    }

    @media screen and (max-width: 767px) {
      height: auto;
    }

    .custom-select {
      background: none;
      height: 2.604vw;
      padding-top: 0;
      margin-bottom: 0;

      .select {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 767px) {
          border-bottom: 1px solid #dbdbdb;
        }

        .label-text {
          position: static;

          &:last-of-type {
            margin-right: 14px;
          }
        }
      }
    }

    .options {
      background: $beige;
    }

    .select__inner {
      height: 100%;
      width: 100%;
      padding-right: 0 !important;
      position: absolute;
      margin-top: 0 !important;

      @media screen and (max-width: 1024px) {
        padding-right: 0 !important;
      }
    }
  }

  &__details-list {
    margin: 1.25vw 0 1.302vw;

    @media screen and (max-width: 1024px) {
      margin: 20px 0;

      .custom-select {
        height: 41px;
        box-sizing: border-box;
      }
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 2.604vw;
      border-bottom: 0.052vw solid $beige2;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-user-select: none;
      -webkit-touch-callout: none;

      @media screen and (max-width: 1024px) {
        border-bottom-width: 1px;
        min-height: 40px;
      }

      @media screen and (max-width: 767px) {
        border-bottom: 1px solid $gray5;
      }

      span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include text-base($size: 0.833vw, $color: $gray2);

        &.info-circle {
          @include text-base($size: 10px, $color: $dark-brown);
        }

        @media screen and (max-width: 1024px) {
          font-size: 14px;

          &.custom-tooltip {
            margin-top: 6px;
          }

          &.info-circle {
            @include text-base($size: 12px, $color: $dark-brown);
          }
        }

        @media screen and (max-width: 767px) {
          span {
            &:last-child {
              text-align: right;
            }
          }
        }

        &:first-child {
          width: 60%;
        }

        &:last-child {
          width: auto;
          color: $brown-black;
          justify-content: flex-end;
          white-space: nowrap;
          text-align: right;
        }

        .common-link {
          display: flex;
          flex-shrink: 0;

          .icon_file_certificate {
            width: 20px;
            display: inline;
          }
        }
      }

      .info-circle {
        width: auto !important;
        justify-content: center !important;
      }

      &[data-detail]:hover {
        cursor: pointer;
        border-bottom-color: $beige2;

        @media screen and (max-width: 767px) {
          &[data-detail]:hover {
            border-bottom-color: $gray5;
          }
        }
      }
    }
    .options-list li {
      height: auto;
    }
  }

  &__details-list_wrap {
    li {
      span {
        &:last-child {
          padding: 5px 0 5px 10px;
          white-space: normal;
          word-break: break-word;
        }
      }
    }
  }

  &__details-title {
    @include text-base($size: 0.833vw, $weight: $bold);
    line-height: 2.5vw;
    padding-bottom: 1.302vw;
    @include display-flex();
    @include align-items(center);

    @media screen and (max-width: 1024px) {
      font-size: 16px;
      padding-bottom: 20px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 30px;
      font-size: 14px;

      &_display-none-mobile {
        display: none;
      }
    }

    svg {
      margin-right: 1.094vw;
    }

    &_no-padding {
      padding-bottom: 0;
      margin-top: 2.604vw;
    }
  }

  &__details-tooltip {
    margin-left: 30px;
  }

  &__product-engrave {
    margin-top: 1.458vw;
    margin-bottom: 1.458vw;
    @media screen and (max-width: 1024px) {
      margin-top: 22px;
      margin-bottom: 22px;
    }

    &-paragraph {
      @include text-base($size: 0.625vw, $weight: $regular);
      line-height: 1.7;
      margin-bottom: 0.417vw;
      color: $gray2;
      @media screen and (max-width: 1024px) {
        font-size: 13px;
        margin-bottom: 6px;
      }
      @media screen and (max-width: 767px) {
        font-size: 10px;
      }
    }
  }

  &__product-engrave-description {
    @include text-base(
      $size: getVW(17),
      $family: 'Montserrat',
      $color: $dark-brown
    );
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    @media (max-width: 1024px) {
      font-size: 14px;
    }

    .icon_inside_bang,
    .icon_outside_bang {
      width: 20px;
      height: 20px;
      min-width: 16px;
      min-height: 16px;

      @media (max-width: 767px) {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__product-engrave-action {
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .icon {
      padding-right: 15px;
    }
  }
  &__product-engrave-controls {
    span {
      margin-left: 20px;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }

  &__product-set-with {
    margin-top: 1.458vw;
    margin-bottom: 1.458vw;
    @media screen and (max-width: 1024px) {
      margin-top: 22px;
      margin-bottom: 22px;
    }

    &-link{
      text-decoration: none;
    }

    &-paragraph {
      display: flex;
      justify-content: space-between;
      @include text-base($size: 0.833vw, $weight: $regular);
      line-height: 1.7;
      margin-bottom: 0.781vw;
      @media screen and (max-width: 1024px) {
        font-size: 13px;
        margin-bottom: 6px;

        & > span {
          font-size: 13px !important;
        }
      }

      @media screen and (max-width: 767px) {
        font-size: 10px;
        margin-bottom: 6px;

        & > span {
          font-size: 10px !important;
        }
      }

      &-description {
        text-transform: uppercase;
        @include text-base($size: 0.625vw, $weight: $regular);
        color: $gray2;
      }

      &-pcs {
        @include text-base($size: 0.833vw, $weight: $bold);
        line-height: normal;
      }
    }
  }

  &__delivery-tooltip {
    position: absolute;
    bottom: calc(100% - 12px);
    width: calc(100% - 6px);
    margin-left: 3px;
    padding: 30px 40px;
    background-color: #fff;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
    z-index: 21;

    &.bottom {
      top: calc(100% - 12px);
      bottom: auto;
    }
  }

  &__delivery-conditions {
    position: relative;
    padding-top: 1.458vw;
    padding-bottom: 1.458vw;
    cursor: pointer;

    &-title {
      display: flex;
      align-items: center;
      @include text-base($size: getVW(14), $weight: 700);
      line-height: getVW(17);
      letter-spacing: 0.9px;
      text-decoration-line: underline;
      color: $gray2;

      @media screen and (max-width: 1024px) {
        font-size: 14px;
        line-height: 17px;
      }
    }

    &-paragraph {
      @include text-base($size: getVW(14), $weight: $regular, $family: 'Montserrat');
      line-height: 1.2;
      margin-bottom: getVW(12);

      strong {
        font-size: getVW(16);
        line-height: getVW(19);
        color: $yellow;
        font-family: 'Roboto Condensed';
      }

      @media screen and (max-width: 1024px) {
        font-size: 12px;
        margin-bottom: 20px;

        strong {
          font-size: 14px;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      padding-right: 22px;
      padding-top: 22px;
      padding-bottom: 22px;
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 30px;
    }
  }

  &__delivery-icon {
    margin-right: 22px;
    margin-left: 7px;
  }

  .jc-start {
    justify-content: start;
  }
}

.product-detail-page-3d {
  &.product-detail-page {
    margin-right: 0;
  }
  .product-detail-page__product-image {
    width: 100%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .product-detail-page__product-features__container {
    flex-grow: 1;
    margin-right: $margin-desktop;

    @media screen and (max-width: 1024px) {
      padding-right: $margin-laptop;
    }

    @media screen and (max-width: 767px) {
      padding-right: 0;
    }

    li {
      max-width: none;
    }
  }

  .product-detail-page__product-details {
    @media screen and (min-width: 768px) {
      opacity: 0;
      padding: 0;
      flex-grow: 0;
      width: 0;
      height: 0;
      transition: 0.3s;
    }
  }
}

.product-swiper {
  width: 100%;
  height: 8.25vw;
  margin: 0;
  padding: 0;
  position: static;

  @media screen and (max-width: 1024px) {
    height: 140px;
  }

  @media screen and (max-width: 767px) {
    height: 220px;
    width: 100%;
    padding-bottom: 24px;
  }

  &__container {
    width: 20.83vw;
    height: 10.25vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1.962vw;
    padding-bottom: 1.256vw;
    box-sizing: border-box;

    @media screen and (max-width: 1024px) {
      width: 80%;
      height: auto;
      margin: 0 0 18px;
    }

    @media screen and (max-width: 767px) {
      margin: 0 0 4px;
      padding-bottom: 16px;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &-prev {
      .product-swiper__image_active {
        border: none !important;
      }
    }

    .product-swiper__image {
      width: 85%;
      height: auto;

      @media screen and (max-width: 1024px) {
        width: 80%;
      }

      @media screen and (max-width: 767px) {
        height: 100%;
        width: auto;
      }

      &_active {
        border: 1px solid #c6c1c2;
        padding: 4px;
        z-index: 1;

        @media screen and (max-width: 767px) {
          border: none;
        }
      }
    }
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-prev {
    left: -2.28vw;

    @media screen and (max-width: 1024px) {
      left: -24px;
    }
  }

  .swiper-button-next {
    right: -2.28vw;

    @media screen and (max-width: 1024px) {
      right: -24px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    &::after,
    &::before {
      font-size: 0.833vw;
      color: $dark-brown;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
      @media screen and (max-width: 767px) {
        content: none;
      }
    }
  }

  .swiper-pagination {
    display: none;
    @media screen and (max-width: 767px) {
      box-sizing: border-box;
      display: flex;
      width: 100%;
      justify-content: center;
      bottom: 18px;
      padding: 0 12px;
    }

    .swiper-pagination-bullet {
      height: 10px;
      width: 10px;
      margin: 0 6px;
    }

    .swiper-pagination-bullet-active {
      margin: 0 32px;
      background: $dark-brown;
    }

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.can_be_set_with-block {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  //margin: 50px 0 30px;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;


  &__product {
    display: flex;
    align-items: center;
    padding: getVW(10);
    text-decoration: none;
    border: 1px solid transparent;
    cursor: pointer;
    box-sizing: border-box;

    //&:hover {
    //  border-color: #d6d1d0;
    //}
    //
    //&:active {
    //  border-color: #4d4048;
    //}

    @media (max-width: 1024px) {
      padding: 5px;
    }

    @media (max-width: 767px) {
      width: 100%;
      background-color: #fff;
    }
  }

  &__action {
    flex-grow: 1;
    text-align: end;
  }

  &__title {
    margin-bottom: getVW(20);
    font-weight: 700;
    font-size: getVW(18);
    line-height: 1.25;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: $dark-brown;
    text-decoration: none;
    @media (max-width: 1024px) {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  &__subtitle {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: getVW(16);
    color: $dark-brown;
    text-decoration: none;
    line-height: 1.25;
    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }

  &__info-description {
    font-weight: 500;
    font-size: getVW(14);
    line-height: 1.3;
    color: $gray2;
    text-decoration: none;
    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }

  &__info-delimiter {
    display: inline-block;
    margin: 0 5px;
  }

  img {
    width: 30px;
    height: auto;
    margin-right: 10px;
  }

  .icon_mr-xs{
    margin:0;
    height: 12px;
    width: auto;
  }
}

.disable_detail_actions {
  pointer-events: none;
  cursor: not-allowed ;
}
