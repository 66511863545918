@import '../../../../styles/flex';
@import '../../../../styles/variables';
@import '../../../../styles/common';

.orders-page {
  width: 100%;
  background-color: $beige;
  @include display-flex;
  @include flex-direction(column);
  min-height: 100%;
  position: relative;
  padding-top: $pt-desktop;
  padding-left: $pl-desktop;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    padding-top: 100px;
    padding-left: 88px;
    width: calc(100% - 66px);
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .parent {
    width: 100%;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__title {
    @include text-base(
      $size: 2.5vw,
      $family: 'Roboto Condensed',
      $color: $dark-brown
    );
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    @media screen and (max-width: 1024px) {
      font-size: 24px;
    }
  }
  &__search,
  &__search-btn {
    margin-right: 7.291vw;
    @media screen and (max-width: 1024px) {
      margin-right: 20px;
    }
    position: relative;
    svg {
      position: absolute;
      left: 1.041vw;
      width: 1.041vw;
      height: 1.041vw;
      top: 50%;
      transform: translateY(-50%);
      @media screen and (max-width: 1024px) {
        left: 20px;
        width: 16px;
        height: 16px;
      }
    }
  }
  &__search {
    @media screen and (max-width: 767px) {
      display: none;
      &::before {
        content: none;
      }
    }
    &_active {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 8px;
      .orders-page__search-input {
        width: 100%;
      }
    }
  }
  &__search-btn {
    display: none;
    background-color: $beige5;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    cursor: pointer;
    &_active {
      border: 1px solid $yellow;
    }
    @media screen and (max-width: 767px) {
      display: block;
    }
    &::before {
      @media screen and (max-width: 767px) {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &__search-input {
    width: 18.75vw;
    height: 2.604vw;
    border-radius: 1.302vw;
    border: none;
    background-color: $beige5;
    padding-left: 2.5vw;
    @include text-base(
      $size: 0.833vw,
      $family: 'Montserrat',
      $color: $dark-brown
    );
    &::placeholder {
      color: $gray2;
    }
    @media screen and (max-width: 1024px) {
      width: 360px;
      height: 44px;
      border-radius: 22px;
      font-size: 14px;
      padding-left: 48px;
    }
    @media screen and (max-width: 767px) {
      padding-left: 20px;
    }
  }
  &__filters {
    position: relative;
    display: flex;
    width: 100%;
    align-items: flex-end;
    height: 4.604vw;
    .custom-select {
      width: 15.104vw !important;
      margin-right: 2.24vw;
    }
    @media screen and (max-width: 1024px) {
      height: 60px;
      justify-content: space-between;
      .custom-select {
        width: 30% !important;
        margin-right: 30px;
        font-size: 14px;
      }
    }
    @media screen and (max-width: 767px) {
      height: 0;
    }
  }
  &__filters-selects-wrapper {
    height: 100%;
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      display: none;
      position: absolute;
      background: $beige5;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
      width: 268px;
      height: auto;
      flex-direction: column;
      justify-content: space-between;
      right: 20px;
      bottom: -48px;
      z-index: 999;
      padding: 0 10px 10px;
      &_active {
        display: flex;
        transform: translateY(100%);
        .custom-select {
          width: 100% !important;
          margin-right: 0;
          height: 60px;
          margin-bottom: 0;
          background-color: $beige5;
        }
        .select {
          height: 100%;
        }
        .select__inner {
          margin-top: 18px !important;
          height: 38px !important;
        }
        .label-text {
          bottom: 16px !important;
        }
      }
    }
  }
  &__filters-menu {
    display: none;
    cursor: pointer;
    @media screen and (max-width: 767px) {
      display: block;
      width: 24px;
      height: 24px;
      background: url(images/sortUp.svg) 50% / cover no-repeat;
      &_active {
        background: url(images/sortUpActive.svg) 50% / cover no-repeat;
      }
    }
  }

  &__clear-filters {
    @include text-base(
      $size: 0.833vw,
      $family: 'Montserrat',
      $color: $dark-brown
    );
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.833vw;
    justify-content: space-around;
    cursor: pointer;
    @media screen and (max-width: 1024px) {
      display: none;
    }

    svg {
      width: 13px;
      height: 15px;
    }
  }

  &__count {
    @include text-base(
      $size: 0.833vw,
      $weight: $semibold,
      $color: $brown-black
    );
    margin-top: 1.563vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    @media screen and (max-width: 1024px) {
      margin-top: 18px;
      font-size: 14px;
    }

    .grid-area-items-per-page {
      display: block;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__table-pagination-wrapper {
    @media screen and (max-width: 1024px) {
      margin-top: 18px;
    }
    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: space-between;
    }

    .grid-area-items-per-page {
      display: none;

      @media screen and (max-width: 767px) {
        display: block;
      }
    }

    .pagination {
      margin-right: 0.833vw;
      @media screen and (max-width: 767px) {
        margin-right: 12px;
      }
    }

    .pagination li a {
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }

    .quantity-items li {
      margin-left: 0;
    }
  }

  .header {
    padding-right: $margin-desktop;
    padding-left: $margin-desktop;
    @media (max-width: 1024px) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .custom-select {
    padding-top: 0;
    .select__inner {
      height: 2.2vw;
      @media (max-width: 1024px) {
        height: auto;
      }
    }
  }
}
