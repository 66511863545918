@import '../../../styles/_variables';
@import '../../../styles/_flex';
@import '../../../styles/_common';

.preview {
  font-family: 'Montserrat', sans-serif;
  width: 22vw;
  position: absolute;
  top: 80%;
  right: 0;
  padding: 2.083vw 1.042vw;
  background-color: $beige;
  box-shadow: -0.625vw 0.573vw 0.885vw 0 rgba(0, 0, 0, 0.13),
    inset 0.156vw 0.156vw 0 0 rgba(255, 255, 255, 0.24);
  box-sizing: border-box;

  &-list {
    max-height: 40vh;
    padding-right: 0.521vw;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &-footer {
    &-total {
      @include text-base($size: 0.757vw);
      @include display-flex();
      @include flex-direction(column);
      @include align-items(flex-end);
      color: $gray2;
      text-align: center;
      margin: 15px 0 25px 0;

      &_span {
        @include text-base(
          $size: 1.298vw,
          $family: 'Roboto Condensed',
          $weight: 700
        );
        margin-top: 5px;
      }
    }

    &-btns-wrap {
      @include display-flex;

      .btn {
        font-size: 0.865vw;
        font-weight: 500;
      }

      .btn:not(:last-child) {
        margin-right: 0.781vw;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: 25vw;

    &-footer {
      &-total {
        @include text-base($size: 0.957vw);

        &_span {
          @include text-base(
            $size: 1.498vw,
            $family: 'Roboto Condensed',
            $weight: 700
          );
        }
      }

      &-btns-wrap {
        .btn {
          font-size: 1.1vw;
        }
      }
    }
  }
}
