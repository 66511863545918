@import '../../../../styles/variables';
@import '../../../../styles/flex';
@import '../../../../styles/common';

.checkout-page {
  &.grid {
    .grid-area-order-info {
      .order-wrapper {
        height: calc(
          100vh - #{$header-height-desktop} - #{$breadcrumbs-height-desktop} - #{$amount-height-desktop} -
            #{$footer-height-desktop}
        );
        overflow: auto;
        padding: 1.042vw 1.563vw;
        border: 1px solid $yellow;
        box-sizing: border-box;
        @include display-flex;
        @include flex-wrap(wrap);
        @include align-content(flex-start);

        .order-block {
          @include flex-grow(1);
          width: calc((100% - 2% * 3) / 3);
          margin-right: 2%;
          margin-top: 1%;
          margin-bottom: 1%;

          &:last-child {
            margin-right: 0;
          }

          &__title {
            @include text-base($size: 0.833vw, $weight: $bold);
            letter-spacing: 0.078vw;
            padding: 0.625vw 0;
            margin-bottom: 0.313vw;
            border-bottom: 0.104vw solid $yellow;
            display: inline-block;
          }

          &__table {
            tbody {
              tr {
                td {
                  padding: 0.625vw 0.26vw;

                  &.label {
                    @include text-base($size: 0.677vw, $color: $gray2);
                    width: 40%;
                    white-space: nowrap;
                  }

                  &.value {
                    @include text-base($size: 0.781vw);
                    word-break: break-all;
                    //word-wrap: break-word;
                  }
                }
              }
            }
          }
        }

        @media screen and (max-width: 1024px) {
          height: calc(
            100vh - #{$header-height-laptop} - #{$breadcrumbs-height-laptop} - #{$amount-height-laptop} -
              #{$footer-height-laptop}
          );
          padding: 0;
          border: none;

          .order-block {
            width: calc((100% - 2% * 2) / 2);

            &__title {
              font-size: 14px;
              letter-spacing: 1.5px;
              padding: 12px 0;
              margin-bottom: 12px;
              border-bottom: 2px solid $yellow;
            }

            &__table {
              width: 100%;

              tbody {
                tr {
                  td {
                    padding: 11px 3px;

                    &.label {
                      font-size: 12px;
                    }

                    &.value {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }

          @media screen and (orientation: portrait) {
            .order-block {
              width: 100%;
              margin-right: 0;
              margin-bottom: 7%;
            }
          }
        }

        @media screen and (max-width: 767px) {
          height: auto;
          padding-top: 20px;

          .order-block {
            &__title {
              padding: 5px 0;
              border: none;
            }

            &__table {
              tbody {
                tr {
                  width: 100%;
                  border-bottom: 1px solid $beige2;

                  td {
                    &:last-child {
                      text-align: right;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .btn.order-action {
      display: flex;
      align-items: center;
      @include text-base($size: 0.75vw, $family: 'Montserrat', $weight: $bold, $color: $white);
      letter-spacing: 0.9px;

      @media screen and (max-width: 767px) {
        font-size: 3.5vw;
      }

      &:active {
        background-color: $dark-brown;
      }

      svg {
        margin-right: 20px;
        transform: rotate(0deg);

        @media (pointer: coarse) {
          path {
            fill: $white;
          }
        }
      }
    }
  }
}

.grid-area-promocode {
  padding-top: 4.2vw;
  position: relative;

  .promocode-block {
    @include display-flex;
    @include align-items(center);
    width: 100%;

    input {
      height: 2.24vw;
      margin-right: 0.521vw;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 200px $bg-beige inset;
      }
    }

    .icon {
      right: 1.042vw;
    }

    .btn {
      height: 2.24vw;
    }
  }
}

.grid-area-items-list{
  display: flex;
  flex-direction: column;
  gap: 20px;
  .promocode-info-block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    .grid-area-promocode{
      flex-grow: 1;
    }
    .cart-item__price{
      .cart-item__price-set{
        font-size: 12px;
        text-align: center;
      }
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
