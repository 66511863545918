@import '../../styles/variables';
@import '../../styles/common';

.uncaught-error {
  &.grid {
    display: grid;
    min-height: 100%;
    grid-template-areas: '. header header header .' '. . content . .';
    grid-template-columns: $margin-desktop 1fr 47vw 1fr $margin-desktop;
    grid-template-rows: minmax(190px, 20vh);
    background-image: linear-gradient(to right, $bg-beige 50%, $beige5 50%);

    .grid-area {
      &-header {
        grid-area: header;
      }
      &-content {
        grid-area: content;
        display: flex;
        flex-direction: column;
        text-align: center;

        .wrap-logo {
          align-self: center;
          .logo {
            width: 150px;
            height: auto;
            margin-bottom: 20px;
          }
        }
        .error-status {
          @include text-base($size: 26px, $weight: $bold);
          margin-top: 1.563vw;
          line-height: 1.563vw;
          @media (max-width: 1024px) {
            @include text-base($size: 24px, $weight: $bold);
          }
          @media (max-width: 768px) {
            line-height: 1.3;
            @include text-base($size: 20px, $weight: $bold);
          }
        }
        .error-message {
          @include text-base($size: 18px, $weight: $regular);
          margin-top: 30px;
          line-height: 1.6;
          text-align: center;
          @media (max-width: 1024px) {
            line-height: 1.5;
            @include text-base($size: 16px, $weight: $regular);
          }
          @media (max-width: 768px) {
            line-height: 1.3;
            @include text-base($size: 14px, $weight: $regular);
          }
        }
        .error-button {
          align-self: center;
          margin-top: 30px;
          width: 26.42vw;
          @media (max-width: 1024px) {
            padding: 15px 20px;
          }
          @media (max-width: 768px) {
            padding: 15px 20px;
          }
        }
      }
    }
  }
}
