@import '../../../../styles/variables';
@import '../../../../styles/common';

.cart-page__engrave-link {
  position: relative;
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}

.cart-page__engrave-tip {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  &__inner {
    width: 294px;
    margin-bottom: 10px;
    padding: 30px;
    background-color: $beige5;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    @include text-base($size: 12px, $family: 'Montserrat', $color: $dark-brown);
  }

  &__item {
    + .cart-page__engrave-tip__item {
      margin-top: 15px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.9px;

    svg {
      margin-right: 10px;
    }
  }

  &__text {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    word-break: break-word;
  }

  &__description {
    font-weight: 400;
  }

  &__description-color {
    margin-right: 2px;
  }
}

.cart-page__engrave-tip__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9998;
}
