.collapse {
  &__caret-icon {
    margin-right: 10px;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;

    &_up {
      transform: rotate(180deg);
      transition: transform 0.2s ease-in-out;
    }
  }
}
