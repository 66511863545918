@import '../../../../styles/variables';
@import '../../../../styles/flex';
@import '../../../../styles/common';
@import '../../../../styles/animations';

.compare-panel {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  position: fixed;
  height: 58px;
  bottom: 0;
  right: 7vw;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid $dark-brown;
  z-index: 22;
  box-shadow: -0.625vw 0.573vw 0.885vw 0 rgba(0, 0, 0, 0.13),
    inset 0.156vw 0.156vw 0 0 rgba(255, 255, 255, 0.24);
  @include display-flex();
  @include flex-direction(row);
  transition: $animation-global;

  .info {
    width: 216px;
    background-color: $dark-brown;
    text-align: center;
    padding-top: 19px;

    &__link {
      color: white;
      text-decoration: none;
      transition: $animation-global;

      &:hover {
        cursor: pointer;

        @media (pointer: coarse) {
          color: $dark-brown;
        }
      }

      &:active {
        color: $brown-black;
      }
    }
  }

  .trash {
    width: 58px;
    background-color: $beige;

    &__icon {
      text-align: center;
    }
  }
}

@media screen and (max-width: 1024px) {
  .compare-panel {
    right: 0;
  }
}

@media screen and (max-width: 767px) {
  .compare-panel {
    width: 100%;
    right: 0;

    .info {
      flex-grow: 1;

      &__link {
        font-size: 14px;
      }
    }
  }
}
