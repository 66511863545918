@import '../../styles/variables';
@import '../../styles/flex';
@import '../../styles/common';
@import '../../styles/icons';

.cart-page {
  & * {
    box-sizing: border-box;
  }

  &.grid {
    // Start css-grid building
    display: grid;
    min-height: 100%;
    grid-template-areas: '. header header header header .' '. breadcrumbs breadcrumbs breadcrumbs breadcrumbs .' '. title title title title .' '. items items items items .' '. contact promocode amount additional-block .' '. contact promocode amount btn-next-page .';
    grid-template-columns: $margin-desktop 18.75vw 29vw auto 20vw $margin-desktop;
    grid-template-rows: $header-height-desktop $breadcrumbs-height-desktop $page-title auto 6vw 6vw;
    background: linear-gradient(
      to right,
      white 0,
      white 56%,
      $bg-beige 56%,
      $bg-beige 70%,
      white 70%,
      white 100%
    );

    .grid-area {
      &-header {
        grid-area: header;
      }

      &-breadcrumbs {
        grid-area: breadcrumbs;
      }

      &-page-title {
        grid-area: title;
      }

      &-items-list {
        grid-area: items;
      }

      &-contact {
        grid-area: contact;
      }

      &-promocode {
        grid-area: promocode;
      }

      &-amount {
        grid-area: amount;
      }

      &-additional-block {
        grid-area: additional-block;
      }

      &-btn-next-page {
        grid-area: btn-next-page;
      }
    }

    // End css-grid building

    .grid-area-items-list {
      .items-list {
        overflow-y: auto;
        max-height: calc(
          100vh - #{$header-height-desktop} - #{$breadcrumbs-height-desktop} - #{$page-title} -
            6vw - 6vw
        );
      }
    }

    .mobile-cart-panel {
      display: none;
    }

    .grid-area-promocode {
      padding-left: 10%;
      padding-right: 10%;
      padding-top: 4.2vw;
      position: relative;

      .promocode-block {
        @include display-flex;
        @include align-items(center);
        width: 100%;

        input {
          height: 2.24vw;
          margin-right: 0.521vw;

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0 200px $bg-beige inset;
          }
        }

        .icon {
          right: 1.042vw;
        }

        .btn {
          height: 2.24vw;
        }
      }
    }

    .grid-area-amount {
      @include display-flex();
      padding-left: 16%;
      padding-top: 2.2vw;

      .price-block {
        width: 50%;
      }
    }

    .grid-area-additional-block {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 1.823vw;

      .additional-wrap {
        @include display-flex();
        @include align-items(center);

        &_mb-20 {
          margin-bottom: 20px;
        }

        .additional__value {
          @include text-base(
            $size: 1.25vw,
            $family: 'Roboto Condensed',
            $weight: $bold
          );
        }
      }
    }

    .grid-area-btn-next-page {
      &_mt {
        padding-top: 0.7vw;
      }
    }
  }
}
.btn_center {
  justify-content: center !important;
}

@media screen and (max-width: 1024px) {
  .cart-page {
    &.grid {
      // Start css-grid building for laptop
      grid-template-areas:
        '. header header header header .'
        '. breadcrumbs breadcrumbs breadcrumbs breadcrumbs .'
        '. title title title title .'
        '. items items items items .'
        '. contact promocode amount additional-block .'
        '. contact promocode amount btn-next-page .';
      grid-template-columns: $margin-laptop 20vw 32vw auto auto $margin-laptop;
      grid-template-rows: $header-height-laptop $breadcrumbs-height-laptop $page-title auto 7vw $footer-height-laptop;
      background: linear-gradient(
        to right,
        white 0,
        white 54%,
        $bg-beige 54%,
        $bg-beige 76%,
        white 76%,
        white 100%
      );
      // End css-grid building for laptop

      .grid-area-items-list {
        .items-list {
          margin-bottom: 15px;
          max-height: calc(
            100vh - #{$header-height-laptop} - #{$breadcrumbs-height-laptop} - #{$page-title} -
              10vh - 1vw - #{$footer-height-laptop}
          );
        }
      }

      .grid-area-promocode {
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 2.083vw;

        .promocode-block {
          input {
            height: 43px;
            margin-right: 10px;
          }

          .icon {
            right: 20px;
          }

          .btn {
            height: 43px;
          }
        }
      }

      .grid-area-amount {
        @include flex-direction(column);
        padding-left: 10%;
        padding-top: 2.083vw;

        .price-block {
          width: 100%;
          @include display-flex;
          @include align-items(flex-start);
          @include justify-content(space-between);
          flex-direction: column;
          order: 2;
        }
      }

      .grid-area-additional-block {
        padding-left: 10%;
        padding-top: 2.083vw;
        flex-direction: column;

        .additional-wrap {
          width: 100%;
          @include align-items(flex-start);
          @include justify-content(space-between);

          &_mb-25 {
            margin-bottom: 25px;
          }

          .additional__value {
            margin-top: 0;
            font-size: 16px;
            line-height: inherit;
          }

          .attention-message {
            width: 65%;
            text-align: right;
            margin-top: 0;
            @include text-base(
              $family: 'Roboto Condensed',
              $weight: $bold,
              $color: $dark-brown,
              $size: 16px
            );
          }
        }
      }

      .grid-area-btn-next-page {
        padding-left: 10%;

        &_mt {
          padding-top: 25px;
        }
      }
    }
  }

  @media screen and (orientation: portrait) {
    $height-page-pointers: 2vw;
    .cart-page {
      &.grid {
        grid-template-rows: $header-height-laptop $breadcrumbs-height-laptop $page-title auto 10vh 10vh $height-page-pointers;
        background: linear-gradient(
          to right,
          white 0,
          white 54%,
          $bg-beige 54%,
          $bg-beige 74%,
          white 74%,
          white 100%
        );

        .grid-area-items-list {
          .items-list {
            max-height: calc(
              100vh - #{$header-height-laptop} - #{$breadcrumbs-height-laptop} -
                #{$page-title} - 10vh - 10vh - #{$height-page-pointers}
            );
          }
        }

        .grid-area-promocode {
          padding-top: 8px;
          padding-left: 6%;
        }
      }
    }
  }
}

@media (max-width: 823px) and (orientation: landscape) {
  .cart-page {
    &.grid {
      padding-left: 20px;
      padding-right: 20px;
      // Start css-grid building for laptop
      grid-template-areas:
        '. header header header header .'
        '. breadcrumbs breadcrumbs breadcrumbs breadcrumbs .'
        '. title title title title .'
        '. items items items items .'
        '. contact promocode amount additional-block .'
        '. contact promocode amount btn-next-page .';
      grid-template-columns: $margin-mobile 25vw auto auto auto $margin-mobile;
      grid-template-rows: $header-height-mobile $breadcrumbs-height-mobile $page-title auto 7vw $footer-height-mobile;

      background: linear-gradient(
        to right,
        white 0,
        white 28%,
        $bg-beige 26%,
        $bg-beige 60%,
        white 55%,
        white 100%
      );
      // End css-grid building for laptop

      .grid-area-items-list {
        .items-list {
          margin-bottom: 20px;
          max-height: calc(
            100vh - #{$header-height-mobile} - #{$breadcrumbs-height-mobile} - #{$page-title} -
              2vw - #{$footer-height-mobile}
          );
        }
      }

      .grid-area-additional-block {
        flex-direction: wrap;

        .additional-wrap {
          margin-bottom: 0;
          .attention-message {
            margin: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .cart-page {
    &.grid {
      // Start css-grid building for mobile
      grid-template-areas:
        '. header .'
        '. breadcrumbs .'
        '. title .'
        'items items items'
        'promocode promocode promocode'
        'additional-block additional-block additional-block'
        'amount amount amount'
        'contact contact contact'
        'btn-next-page btn-next-page btn-next-page';
      grid-template-columns: $margin-mobile auto $margin-mobile;
      grid-template-rows: $header-height-mobile $breadcrumbs-height-mobile min-content;
      // add -webkit-min-content for iPhone 5
      grid-template-rows: $header-height-mobile $breadcrumbs-height-mobile -webkit-min-content;
      background: #fff;
      // End css-grid building for mobile

      .grid-area-page-title {
        margin-bottom: $margin-mobile;
      }

      .grid-area-items-list {
        background-color: #fff;

        ::-webkit-scrollbar {
          width: 0;
        }

        .items-list {
          max-height: auto;
          box-shadow: inset 0 -7px 9px -7px rgba(0, 0, 0, 0.2);
        }
      }

      .mobile-cart-panel {
        @include display-flex();
        @include align-items(center);
        @include justify-content(space-around);
        z-index: 1;
        width: 100%;
        height: 50px;
        position: fixed;
        bottom: 0;
        background-color: white;
        box-shadow: 0 7px 9px 7px rgba(0, 0, 0, 0.2);

        &.shown {
          @include display-flex();
        }

        &.hidden {
          display: none;
        }

        .attention-message {
          color: $dark-brown;
          margin-top: 0;
        }
      }

      .grid-area-additional-block {
        padding: 0 $margin-mobile;
        background-color: #fff;
      }

      .grid-area-promocode {
        padding: $margin-mobile;
        background-color: #fff;

        .promocode-block {
          input {
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
              -webkit-box-shadow: 0 0 0 200px #fff inset;
            }
          }
        }
      }

      .grid-area-amount {
        @include flex-direction(column);
        padding: $margin-mobile;
        background-color: #fff;
        padding-top: 0;
        padding-bottom: 10px;

        .price-block {
          flex-direction: row;

          .diamond-amount {
            font-size: 24px;
          }
        }
      }

      .grid-area-btn-next-page {
        padding: $margin-mobile;
        background-color: #fff;
      }
    }
  }
}
