@import '../../../../styles/variables';
@import '../../../../styles/flex';
@import '../../../../styles/common';
@import '../../../../styles/icons';
@import '../../../../styles/animations';

.engrave-popup {
  --field--height: 2.604vw;
  --field-margin: 2.604vw;
  &.modal-window {
    padding: 2.083vw 5.208vw 4.167vw;

    @media (max-width: 1024px) {
      width: 851px;
      padding: 50px 80px;
      --field--height: 46px;
      --field-margin: 30px;
    }

    @media (max-width: 767px) {
      width: 485px;
      --field-margin: 20px;
    }

    @media (max-width: 620px) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &__content {
    width: 100%;
    display: grid;
    grid-template-areas:
      'description description'
      'font color'
      'message-input message-preview'
      'actions actions';
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: var(--field-margin);

    @media (max-width: 1024px) {
      grid-gap: 15px;
    }

    @media (max-width: 767px) {
      grid-template-areas:
        'description'
        'font'
        'color'
        'message-input'
        'message-preview'
        'actions';
      grid-template-columns: 1fr;
    }
  }

  &__description {
    grid-area: description;
    @include text-base($size: 0.833vw, $color: $dark-brown);
    text-align: center;
    width: 100%;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  &__message-wrapper {
    grid-area: message;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-top: 2.08vw;
  }

  &__message-input {
    grid-area: message-input;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__message-input-info {
    @include text-base($size: 0.938vw, $color: $dark-brown, $weight: bold);
    text-transform: uppercase;
    margin-bottom: 0.781vw;

    @media (max-width: 767px) {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  &__message-input-field-wrapper {
    position: relative;

    span {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
      @include text-base($size: 10px, $color: $beige2, $weight: 500);
    }
  }

  &__message-input-field {
    height: var(--field--height);
    width: 100%;
    border: 1px solid $gray3;
    padding: 0.625vw 50px 0.625vw 10px;
    box-sizing: border-box;
    @include text-base($size: 0.833vw, $color: $dark-brown);

    &::placeholder {
      color: $beige2;
    }

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  &__message-preview {
    grid-area: message-preview;
    flex-grow: 1;
    align-self: end;
    max-width: 100%;
    height: var(--field--height);
    padding: 0 5px;
    overflow-x: auto;
    background: linear-gradient(
      180deg,
      #c4c4c4 0%,
      #e7e6e6 48.44%,
      #c4c4c4 100%
    );

    @include text-base($size: 1.25vw, $color: #c5c5c5, $weight: bold);
    white-space: nowrap;
    line-height: var(--field--height);
    text-align: center;
    box-sizing: border-box;
    overflow-y: hidden;

    span {
      height: 100%;
      position: relative;
    }

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 43px;
    }
  }

  .custom-select {
    background: #fff;
    border: 1px solid $gray3;
    height: var(--field--height);
    padding: 0;
  }

  .select {
    height: var(--field--height);
    border-bottom: none;
    display: flex;
    align-items: center;
  }

  .select__inner {
    margin-top: 0 !important;
    padding: 0.625vw 0.52vw !important;

    @media (max-width: 767px) {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
  }

  &__font-field {
    grid-area: font;
    width: 100%;
    display: flex;
    flex-direction: column;

    .engrave-popup__message-input-field {
      max-width: 100%;
      line-height: 1.5;
    }
  }

  &__color-field {
    grid-area: color;
    flex-direction: column;
    display: flex;
    width: 100%;
  }

  &__color-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__color-item {
    display: inline-block;
    width: 1.146vw;
    height: 1.146vw;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    border: 0.06vw solid $dark-brown;
  }

  &__font-item {
    margin-right: 12px;

    @media (max-width: 767px) {
      margin-right: 5px;
    }
  }

  &__actions {
    grid-area: actions;
    display: flex;
    justify-content: center;

    button {
      max-height: none;
      height: 3.95vw;
      width: 12.5vw;
      margin: 0 0.417vw;
    }

    @media (max-width: 767px) {
      justify-content: space-between;

      button {
        width: calc(50% - 5px);
        margin: 0;
      }
    }
  }
}
