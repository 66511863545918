/* Variables for css-grid */
$header-height-desktop: 7.552vw;
$header-height-laptop: 100px;
$header-height-mobile: 60px;

$breadcrumbs-height-desktop: 37px;
$breadcrumbs-height-laptop: 35px;
$breadcrumbs-height-mobile: 30px;

$set-hint-height-desktop: calc(125px - #{$breadcrumbs-height-desktop});
$set-hint-height-mobile: auto;

$margin-desktop: 7.292vw;
$margin-laptop: 20px;
$margin-mobile: 20px;

$page-title: 3.5vw;
$page-title-height-mobile: 65px;

/* Additional variables for css-grid for Cart pages */
$amount-height-desktop: 4vw;
$amount-height-laptop: 15vh;

$footer-height-desktop: 8vw;
$footer-height-laptop: 10vh;
$footer-height-mobile: 20vh;
$footer-bottom-height-mobile: 50px;
$border-radius-circle: 100%;

/* Variables for account pages start */
$pt-desktop: 8.854vw;
$pt-tablet: 145px;
$pt-mobile: 100px;

$pl-desktop: 3.646vw;
$pl-tablet: 65px;
$pl-mobile: 90px;
/* Variables for account pages end */

$white: #ffffff;
$black: #000;
$beige: #fbfaf8;
$beige2: #c6c1c2;
$beige3: #ebe0d9;
$beige4: #f7f2e9;
$beige5: #f8f5f2;
$beige6: #ece9e8;
$beige7: #f0e0df;
$gray: #a39a9d;
$gray2: #928a8e;
$gray3: #d6d1d0;
$gray4: #d8d5d5;
$gray5: #dbdbdb;
$gray6: #939393;
$gray7: #808080;
$dark-gray: #707070;
$light-brown: #ab8971;
$yellow: #b1a07e;
$yellow-dark: #8e7646;
$dark-brown: #4d4048;
$darker-brown: #62545d;
$brown-black: #1c1c1f;
$red: #d67588;
$red2: #c1465e;
$red3: #913244;
$red4: #B5323B;
$pink: #f4ddd9;
$pink-dark: #ead2ce;
$orange: #eb954c;
$green: #7eb17e;

$bg-beige: $beige;
$bg-beige-85: rgba(251, 250, 248, 0.85);
$bg-mobile-beige: $beige4;

$black-16: rgba(0, 0, 0, 0.16);
$dark-brown-40: rgba(77, 64, 72, 0.4);
$yellow-20: rgba(177, 160, 126, 0.2);
$dark-blue-50: rgba(11, 12, 16, 0.5);

$shadow-top-xs: 0px -5px 5px rgba(0, 0, 0, 0.07);
$shadow-bottom-xs: 0px 5px 5px rgba(0, 0, 0, 0.07);
$shadow-bottom-sm: 0px 8px 10px rgba(0, 0, 0, 0.07);

$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

$xxxs: 12px;
$xxs: 14px;
$xs: 15px;
$s: 16px;
$m: 18px;
$l: 24px;
$xl: 48px;
$xxl: 74px;
$xxxl: 80px;

$animation-global: all 200ms ease-in;

$hint_desktop_width: 26vw;
$hint_laptop_width: 335px;
