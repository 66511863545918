@import '../../styles/variables';
@import '../../styles/common';

.forgotPassword {
  @include display-flex;
  @include flex-direction(column);
  @include align-items(center);
  justify-content: center;
  width: 100%;
  height: calc(100% - 7.552vw);
  background-color: $beige;
  padding: 0;

  .button {
    @include text-base(
      $size: 0.729vw,
      $family: 'Montserrat',
      $weight: $medium,
      $color: $white
    );
    background-color: #4d4048;
    margin-top: 2.92vw;
    width: 22.64vw;
    height: 3.123vw;
  }
}
