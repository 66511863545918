.form-friend-contacts__icon-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}

.form-friend-contacts__item {
  cursor: pointer;
  input {
    display: none;
  }

  .form-friend-contacts__icon-wrapper {
    svg {
      fill: #c6c1c2;
    }
  }

  &--checked {
    .form-friend-contacts__icon-wrapper {
      border-color: #b1a07e;
      svg {
        fill: #4d4048;
      }
    }
  }
}
