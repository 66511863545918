@import '../../styles/variables';
@import '../../styles/common';

.content {
  @include display-flex;
  @include flex-direction(column);
  min-height: 100%;
  background-color: white;

  &.grid {
    display: grid;
    min-height: 100%;
    grid-template-areas: '. header header .' '. title title .' '. info-bar form form';
    grid-template-columns: 0 1fr 0 0;
    grid-template-rows: 5vw 10vw;

    .header__actions {
      flex: 1 0 15%;
    }

    header.header {
      height: 5vw;
    }

    .grid-area {
      &-header {
        grid-area: header;
        padding-left: $margin-desktop;
        padding-right: $margin-desktop;
        background: white;
      }

      &-page-title {
        grid-area: title;
        padding-left: $margin-desktop;
        padding-right: $margin-desktop;
        text-align: center;
        background: white;
      }

      &-info-bar {
        grid-area: info-bar;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      &-form {
        grid-area: form;
        background-color: $bg-beige;
      }

      // End css-grid building for Desktop
    }

    .grid-area-page-title {
      @include display-flex();
      @include align-items(center);
      @include justify-content(center);
    }

    .grid-area-info-bar {
      padding-left: 28vw;
      padding-right: 28vw;
      background: #fbfaf8;

      .info-block {
        @include display-flex;
        @include align-items(center);
        margin-bottom: 2.083vw;

        &:last-child {
          margin-bottom: 0;
        }

        &__text {
          @include text-base($size: 0.938vw, $weight: $semibold);
        }

        &__text-block {
          @include text-base($size: 0.833vw, $weight: $regular);
          line-height: 1.75;
        }
      }
    }
  }

  input.autofill-bg-beige.shrink.invalid {
    padding-right: 18px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #fbfaf8 inset !important;
    background: transparent;
  }

  .header-signup {
    padding-right: $margin-desktop;
    padding-left: $margin-desktop;
  }
  .upper-part {
    @include display-flex;
    @include justify-content(center);
    background-color: white;
    width: 100%;

    .title {
      @include text-base(
        $size: 2.76vw,
        $family: 'Roboto Condensed',
        $weight: $bold
      );
      margin-top: 16.297vh;
      text-align: center;
      width: 18.4vw;
      margin-bottom: 1.51vw;
      text-transform: uppercase;
    }
  }

  .tabbed {
    @include display-flex;
    @include flex-direction(column);
    @include align-items(center);
    width: 100%;
    background-color: $beige;
    padding: 0;
  }

  .tabs {
    @include display-flex;
    @include justify-content(center);
    min-width: 461px;
    height: 2.166vw;
    margin-top: 2.604vw;

    &__item {
      margin-right: 3.073vw;
    }

    &__item:last-child {
      margin-right: 0;
    }
  }

  .tab {
    position: relative;

    label {
      @include display-flex;
      position: relative;
      text-align: center;
      cursor: pointer;
      .tabs__link {
        padding-top: 0.781vw;
        padding-bottom: 0.781vw;
        @include text-base(
          $size: 1.042vw,
          $family: 'DidotLH',
          $fallback-family: 'serif',
          $weight: $bold,
          $color: $gray7
        );
        text-decoration: none; /* Отменяем подчеркивание у ссылки */
      }

      &:after {
        border-bottom: 2px solid #ab8971;
        content: '';
        bottom: 0;
        width: 0;
        left: 50%;
        position: absolute;
        -webkit-transition: width 0.2s ease-in-out;
        -moz--transition: width 0.2s ease-in-out;
        transition: width 0.2s ease-in-out;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        transform: translateX(-50%);
      }

      &:hover {
        color: $gray7;
        text-decoration: none;

        &:after {
          width: 100%;
        }
      }

      &.active:after {
        width: 100%;
      }
    }
  }
  //---------> end

  //---->Start style for switchable content
  .tab-content {
    @include display-flex;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);

    .back {
      display: none;
    }

    .auth-alert {
      display: none;
      @include text-base($family: 'Montserrat', $weight: $bold, $color: $red);
      @include justify-content(center);
      @include align-self(center);
      @include flex-direction(column);
      width: 28vw;
      margin-top: 2.344vw;
      border-radius: 1.5625vw;
      padding: 2.084vw 2.9vw;
      &.show {
        @include display-flex;
      }

      &__title {
        font-size: 1.042vw;
        font-weight: $medium;
      }

      &__text {
        //width: 27.396vw;
        font-size: 0.833vw;
        font-weight: $regular;
        margin-top: 1.042vw;
        line-height: 2;
      }

      @media screen and (max-width: 767px) {
        margin-top: 0 !important;
      }
    }

    .form-navigation {
      @include display-flex;
      margin-top: 5.648vh;
      width: 100%;

      &__item {
        @include display-flex;
        @include text-base(
          $size: 0.833vw,
          $family: 'Montserrat',
          $weight: $medium
        );
        text-align: center;
        margin-right: 2.604vw;

        svg {
          width: 14px;
          height: 16px;
          margin-right: 0.5vw;

          path {
            fill: $gray2;
          }
        }

        .title {
          @include display-flex;
          @include flex-direction(column);
          @include justify-content(center);
        }
      }

      &__item:last-child {
        margin-right: 0;
      }
    }

    //-------> styles for form
    .form {
      @include display-flex;
      @include flex-direction(column);
      @include text-base(
        $size: 0.833vw,
        $family: 'Montserrat',
        $weight: $medium
      );
      margin-top: 1.406vw;
      width: 26.093vw;

      .form-title {
        @include text-base(
          $size: 0.833vw,
          $family: 'Montserrat',
          $weight: $medium
        );
        text-align: center;
      }

      .form-label {
        @include text-base(
          $size: 0.833vw,
          $family: 'Montserrat',
          $weight: $medium,
          $color: $gray2
        );
      }

      .checkbox-container {
        @include text-base($size: 0.833vw);
        padding-left: 1.5vw;
        margin-top: 1vw;
      }

      .forgot-pass {
        @include text-base(
          $size: 0.729vw,
          $family: 'Montserrat',
          $weight: $semibold,
          $color: $dark-brown
        );
        cursor: pointer;
        width: max-content;
        height: 1vw;
        padding-bottom: 0.26vw;
        margin-top: 1vw;
        border-bottom: 0.104vw solid $dark-brown;
      }

      .forgot-pass:hover {
        color: $gray2;
      }

      .activate {
        display: none;
        @include flex-direction(row);
        @include text-base(
          $size: 0.833vw,
          $family: 'Montserrat',
          $weight: $regular,
          $color: $red
        );
        margin-top: 2.344vw;
        line-height: 1.3;

        &.show {
          @include display-flex;
        }

        &__logo {
          height: 1.146vw;
          width: 1.25vw;
          margin-right: 0.78125vw;
        }

        &__content {
          @include flex-direction(column);

          &_text {
            margin-bottom: 0.26vw;
          }

          &_link {
            font-weight: $semibold;
            color: $red;
            text-decoration: none;
          }
        }
      }

      .required {
        @include text-base(
          $size: 0.729vw,
          $family: 'Montserrat',
          $weight: $medium,
          $color: $gray2
        );
        align-self: flex-start;
        width: 100%;
        margin-bottom: 2.778vh;

        &_error {
          color: $red;
        }
      }

      .submit {
        margin-top: 2.083vw;
        height: 3.958vw;
        border: none;
      }
      padding-bottom: 2.778vh;
    }
  }
  //-------> end of styles for form
  //------->end of styles for switchable content
}

@media screen and (max-width: 1024px) {
  .content {
    @include display-flex;
    @include flex-direction(column);
    min-height: 100%;

    &.grid {
      grid-template-rows: 7.552vw 10vw;
    }

    header.header {
      height: 8vh;
    }

    .upper-part {
      @include display-flex;
      @include flex-direction(column);
      @include justify-content(flex-end);
      @include align-items(center);
      padding-bottom: 20px;
      width: 100%;

      .title {
        @include text-base(
          $size: 25px,
          $family: 'Roboto Condensed',
          $weight: $bold
        );
        text-align: center;
        min-width: 200px;
        width: 18.4vw;
      }
    }

    .tabs {
      @include display-flex;
      @include justify-content(center);
      @include text-base(
        $size: 20px,
        $family: 'DidotLH',
        $fallback-family: 'serif',
        $weight: $bold,
        $color: $gray7
      );
      min-width: 350px;
      margin-top: 2.604vw;
      text-align: center;
      height: 40px;
    }

    .tabs .tab:checked {
      cursor: pointer;

      &:after {
        display: block;
        content: '';
        border-bottom: 2px solid #ab8971;
        top: 30px;
        width: 0;
        left: 50%;
        position: absolute;
        -webkit-transition: width 0.2s ease-in-out;
        -moz--transition: width 0.2s ease-in-out;
        transition: width 0.2s ease-in-out;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        transform: translateX(-50%);
      }

      &:hover {
        color: $gray7;
        text-decoration: none;

        &:after {
          width: 100%;
        }
      }
    }
    .tab > label {
      @include display-flex;
      @include justify-content(center);
      cursor: pointer;
      .tabs__link {
        @include text-base(
          $size: 20px,
          $family: 'DidotLH',
          $fallback-family: 'serif',
          $weight: $bold,
          $color: $gray7
        );
        height: 25px;
        padding-bottom: 10px;
      }

      &:after {
        margin-top: 0;
        //margin-bottom: 10px;
      }

      &:active {
        &:after {
          width: 100%;
        }
      }
    }

    .tab-content {
      .form-navigation {
        @include display-flex;
        @include justify-content(center);
        margin-top: 5.648vh;
        min-width: 584px;

        &__item {
          @include text-base(
            $size: 14px,
            $family: 'Montserrat',
            $weight: $medium
          );
        }
        svg {
          margin-right: 8px;
          height: 16px;
          width: 16px;
        }

        &__item:last-child {
          margin-right: 0;
        }
      }

      .auth-alert {
        @include text-base($family: 'Montserrat', $weight: $bold, $color: $red);
        @include justify-content(center);
        width: 600px;
        margin-top: 45px;
        border-radius: 20px;
        padding: 20px 30px;

        &__title {
          font-size: 20px;
          font-weight: $medium;
        }

        &__text {
          font-size: 16px;
          font-weight: $regular;
          margin-top: 10px;
          line-height: 2;
        }
      }

      .form {
        @include display-flex;
        @include flex-direction(column);
        @include text-base(
          $size: 16px,
          $family: 'Montserrat',
          $weight: $medium
        );
        margin-top: 1.406vw;
        width: 50vw;
        min-width: 380px;

        .form-title {
          @include text-base(
            $size: 16px,
            $family: 'Montserrat',
            $weight: $medium
          );
          text-align: center;
        }

        .checkbox-container {
          @include text-base($size: 13px);
          padding-left: 30px;
          margin-top: 4vh;

          .checkmark {
            height: 16px;
            width: 16px;

            &:after {
              left: 5px;
              top: 1px;
              width: 5px;
              height: 8px;
              border-width: 0 2px 2px 0;
            }
          }
        }

        .forgot-pass {
          @include text-base(
            $size: 12px,
            $family: 'Montserrat',
            $weight: $semibold,
            $color: $dark-brown
          );
          width: 145px;
          margin-top: 3vh;
          height: 15px;
          border-bottom: 0.104vw solid $dark-brown;
        }

        .forgot-pass:hover {
          color: $gray2;
        }

        .activate {
          font-size: 15px;
          margin-top: 20px;

          &__logo {
            height: 22px;
            width: 24px;
            margin-right: 15px;
          }

          &__content {
            @include flex-direction(column);

            &_text {
              margin-bottom: 5px;
            }

            &_link {
              font-weight: $semibold;
              color: $red;
              text-decoration: none;
            }
          }
        }

        .required {
          @include text-base(
            $size: 12px,
            $family: 'Montserrat',
            $weight: $medium,
            $color: $gray2
          );
          margin-top: 5px;
          width: 100%;
          margin-bottom: 2.778vh;

          &_error {
            color: $red;
          }
        }

        .submit {
          margin-top: 2vh;
          height: 50px;
        }
      }
      padding-bottom: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .content {
    @include display-flex;
    @include flex-direction(column);
    width: 100%;
    min-height: 100%;
    background-color: $beige;

    .page-title {
      font-size: 24px;
    }

    .upper-part {
      @include justify-content(flex-end);
      width: 100%;
      padding-bottom: 20px;
      .title {
        @include text-base(
          $size: 24px,
          $family: 'Roboto Condensed',
          $weight: $bold
        );
        text-align: center;
        min-width: 200px;
        width: 18.4vw;
      }
    }

    header.grid-area-header.header.header_sticky {
      height: 56px;
      .header__actions {
        flex: 1 0 30%;
      }
    }

    &.grid {
      grid-template-rows: 56px min-content !important;
      .grid-area-page-title {
        padding-top: 16px;
        padding-bottom: 24px;
      }
    }

    .tabs {
      @include display-flex;
      @include justify-content(center);
      @include text-base(
        $size: 16px,
        $family: 'DidotLH',
        $fallback-family: 'serif',
        $weight: $bold,
        $color: $dark-brown
      );
      display: flex;
      text-align: center;
      margin-bottom: 20px;
      z-index: 0;

      &.closed {
        width: 100%;
        margin-top: 30px;
      }

      &__item {
        height: 20px;

        label {
          opacity: 0.38;

          &.active {
            opacity: 1;
          }
        }
      }

      &__item:first-child {
        margin-top: 0;
      }
    }

    .tab > label {
      @include display-flex;
      text-align: center;
      cursor: pointer;
      .tabs__link {
        @include text-base(
          $size: 18px,
          $family: 'DidotLH',
          $fallback-family: 'serif',
          $weight: $bold,
          $color: $dark-brown
        );
        height: 20px;
        padding-bottom: 10px;
      }
    }

    .tab-content {
      width: 100%;

      .form-navigation {
        flex-direction: column;
        margin-top: 10px;
        min-width: 100%;

        .form-navigation__item {
          font-size: 14px;
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 10px 0;
        }
      }

      &.opened {
        @include display-flex;
      }

      .auth-alert {
        @include text-base($family: 'Montserrat', $weight: $bold, $color: $red);
        @include justify-content(center);
        width: 80%;
        margin-top: 45px;
        border-radius: 15px;
        padding: 15px 25px;

        &__title {
          font-size: 16px;
          font-weight: $medium;
        }

        &__text {
          font-size: 12px;
          font-weight: $regular;
          margin-top: 20px;
          line-height: 1.5;
        }
      }

      .form {
        @include display-flex;
        @include flex-direction(column);
        @include text-base(
          $size: 16px,
          $family: 'Montserrat',
          $weight: $medium
        );
        margin-top: 1.406vw;
        width: calc(100% - 32px);
        min-width: auto;
        max-width: 360px;
        padding-bottom: 0;

        .form-title {
          @include display-flex;
          @include flex-direction(column);
          @include justify-content(flex-end);
          @include align-items(center);
          width: 100%;
          top: 0;
          left: 0;

          p {
            @include text-base($size: 14px);
            text-align: center;
            min-width: 270px;
            width: 270px;
          }
        }

        .form-label {
          margin-bottom: 2.5vw;
        }

        .checkbox-container {
          margin-top: 4vh;
        }

        .forgot-pass {
          @include text-base(
            $size: 12px,
            $family: 'Montserrat',
            $weight: $semibold,
            $color: $dark-brown
          );
          border-bottom: 0.104vw solid $dark-brown;
          margin-top: 0;
        }

        .submit {
          margin-top: 4vh;
          height: 50px;
        }
      }

      .auth-btn {
        @include display-inline-flex;
        height: 50px;
        min-width: 300px;
        width: 80%;
      }

      padding-bottom: 30px;
    }
  }
}
