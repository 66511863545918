@import '../../../../styles/variables';
@import '../../../../styles/functions';

.matching-band-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 50px 0 30px;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    margin: 40px 0 20px 0;
    padding: 0 22px 0 0;
  }

  @media (max-width: 767px) {
    margin: 10px 0 20px 0;
    padding: 0 22px;
  }

  &__product {
    display: flex;
    align-items: center;
    padding: getVW(10);
    text-decoration: none;
    border: 1px solid transparent;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
      border-color: #d6d1d0;
    }

    &:active {
      border-color: #4d4048;
    }

    @media (max-width: 1024px) {
      padding: 5px;
    }

    @media (max-width: 767px) {
      width: 100%;
      background-color: #fff;
    }

    .img-container {
      width: 50px;
      height: 50px;
    }
  }

  &__title {
    margin-bottom: getVW(20);
    font-weight: 700;
    font-size: getVW(18);
    line-height: 1.25;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: $dark-brown;
    text-decoration: none;
    @media (max-width: 1024px) {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  &__subtitle {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: getVW(16);
    color: $dark-brown;
    text-decoration: none;
    line-height: 1.25;
    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }

  &__info-description {
    font-weight: 500;
    font-size: getVW(14);
    line-height: 1.3;
    color: $gray2;
    text-decoration: none;
    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }

  &__info-delimiter {
    display: inline-block;
    margin: 0 5px;
  }

  img {
    width: 50px;
    margin-right: 10px;
  }
}
