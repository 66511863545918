@import '../../../../styles/variables';
@import '../../../../styles/common';

.error-500-header {
  margin-bottom: 1.3vw;

  & > &__title {
    font-weight: 700;
    color: #d67588;

    @media screen and (max-width: 1024px) {
      size: 24px;
      line-height: 28.13px;
      color: #d67588;
    }
  }

  &__subtitle {
    size: calc(18 / 1920 * 100);
    font-weight: 700;
    line-height: 28.13px;
    text-align: center;

    @media screen and (max-width: 1024px) {
      size: 18px;
      line-height: 28.13px;
      color: #d67588;
    }
  }
}

.error-500-btn {
  width: 295px;
}
