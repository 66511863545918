@import '../../../../../../styles/flex';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/common';

.modal-window-body-wrap{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  .content-main_image{
    width: 50%;
    height: 100%;
    background-color: $beige7;
    background-image: url('../../../../../../img/mainPage/main-landing/promocode_girl.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: bottom;
    background-position-x: left;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .content-main-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    gap: 20px;
    @media (max-width: 1024px) {
      flex-grow: 1;
    }
    &_title {
      @include text-base(
              $size: 2.126vw,
              $family: 'DidotLH',
              $fallback-family: 'serif',
              $weight: $bold,
              $color: $dark-brown
      );
      text-transform: uppercase;
      align-self: center;
      text-align: center;

      font-size: 35px;
      line-height: 150%;

      @media (max-width: 1024px) {
        font-size: 28px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }

    &_description {
      @include text-base(
              $size: 0.865vw,
              $family: 'Montserrat',
              $fallback-family: 'serif',
              $weight: normal,
              $color: $gray2
      );
      line-height: 27px;
      margin-top: 2.976vh;

      align-self: center;
      text-align: center;

      max-width: 465px;
      font-size: 16px;
      line-height: 150%;

      @media (max-width: 1024px) {
        font-size: 14px;
        max-width: 300px;
      }

      @media (max-width: 767px) {
        font-size: 12px;
        max-width: 250px;
      }
    }

    &_action {
      width: 80%;
      align-self: center;
      text-align: center;

      .email-info-block{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        .grid-area-email{
          flex-grow: 1;
        }
        .cart-item__price{
          .cart-item__price-set{
            font-size: 12px;
            text-align: center;
          }
          align-self: flex-end;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }

      .grid-area-email {
        padding-top: 20px;
        position: relative;

        .email-block {
          @include display-flex;
          @include align-items(center);
          width: 100%;

          input {
            height: 2.24vw;
            margin-right: 0.521vw;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
              -webkit-box-shadow: 0 0 0 200px $bg-beige inset;
            }
          }

          .icon {
            right: 1.042vw;
          }

          .btn {
            height: 2.24vw;
          }
        }
      }
    }
  }
}


