@import '../../styles/variables';

$spacer: 1rem !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$border-radius: 0.25rem !default;
$border-radius-sm: 0.2rem !default;
$border-radius-lg: 0.3rem !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$border-width: 1px !default;

$close-font-size: $font-size-base * 1.5 !default;

// fusv-enable

$primary: $beige2 !default;
$success: $green !default;
$warning: $orange !default;
$danger: $red !default;

// scss-docs-start colors-map

$theme-colors: (
  'primary': $primary,
  'success': $success,
  'warning': $warning,
  'danger': $danger,
) !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: $spacer !default;
$alert-padding-x: $spacer !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: $border-width !default;

$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;

@mixin alert-variant($background, $border, $color) {
  color: $color;
  background-color: $background;
  border-color: $border;
}

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

@function color-level($color: $primary, $level: 0) {
  $color-base: if($level > 0, $black, $white);
  $level: abs($level);

  @return mix($color-base, $color, $level * $theme-color-interval);
}

//
// Base styles
//

.alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: $alert-border-width solid transparent;
  border-radius: $alert-border-radius;
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: $close-font-size + $alert-padding-x * 2;

  // Adjust close link position
  .close {
    position: absolute;
    top: 0;
    right: 0;
    //padding: $alert-padding-y $alert-padding-x;
    padding: 1rem 1.25rem;
    color: inherit;
    background: transparent;
    transition: none;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant(
      color-level($value, $alert-bg-level),
      color-level($value, $alert-border-level),
      color-level($value, $alert-color-level)
    );
  }
}
