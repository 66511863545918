@import '../../styles/variables';
@import '../../styles/common';

.confirmation-page {
  @include display-flex;
  @include flex-direction(column);
  @include align-items(center);
  justify-content: center;
  width: 100%;
  height: calc(100% - 7.552vw);
  background-color: $beige;
  padding: 0;
  &__logo-wrap {
    position: relative;
    max-width: 5.3125vw;
    margin-top: 6.146vw;
    .logo {
      width: 100%;
    }
    .logo-status {
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      transform: translate(50%, -50%);
      background-color: $beige;
      box-shadow: 0 0 0 3px $beige;
      position: absolute;
      right: 0;
      top: 0;
      &::before {
        content: '';
        display: none;
        width: 100%;
        height: 100%;
      }
      &_warning {
        &::before {
          display: block;
          background: url('../../img/svgicons/status-warning.svg') 50% no-repeat;
        }
      }
      &_completed {
        &::before {
          display: block;
          background: url('../../img/svgicons/status-completed.svg') 50%
            no-repeat;
        }
      }
    }
  }

  &__title {
    @include text-base(
      $size: 1.25vw,
      $family: 'Montserrat',
      $weight: $bold,
      $color: $dark-brown
    );
    margin-top: 1.615vw;
    text-align: center;
    &_email {
      margin-top: 1.667vw;
      margin-bottom: 1.563vw;
      width: 17.396vw;
    }
  }

  &__text {
    @include text-base(
      $size: 0.833vw,
      $family: 'Montserrat',
      $weight: $regular,
      $color: $gray2
    );
    margin-top: 2.604vw;
    width: 60%;
    text-align: center;
    line-height: 1.715vw;
    span {
      white-space: nowrap;
    }
    &_highlighted {
      color: $dark-brown;
    }
    &_email {
      margin-top: 1.042vw;
      margin-bottom: 0.521vw;
      p {
        font-weight: 700;
      }
    }
    &.color-red {
      color: $red;
    }
  }
  .button {
    @include text-base(
      $size: 0.729vw,
      $family: 'Montserrat',
      $weight: $medium,
      $color: $white
    );
    margin-top: 2.92vw;
    width: 22.64vw;
    height: 3.123vw;
    flex-flow: column-reverse;
    &_email {
      margin-top: 2.396vw;
    }
  }
}

@media screen and (max-width: 1024px) {
  .confirmation-page {
    &__logo-wrap {
      max-width: 86px;
      margin-top: 80px;
      .logo {
        width: 100%;
      }
    }

    &__title {
      @include text-base(
        $size: 16px,
        $family: 'Montserrat',
        $weight: $bold,
        $color: $dark-brown
      );
      margin-top: 30px;
      text-align: center;
      width: 172px;

      &_email {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 278px;
      }
    }

    &__text {
      @include text-base(
        $size: 14px,
        $family: 'Montserrat',
        $weight: $medium,
        $color: $dark-brown
      );
      font-weight: 400;
      line-height: 1.5;
      margin-top: 10px;
      width: 80%;
      text-align: center;

      &_highlighted {
        font-weight: 700;
      }
      &_email {
        margin-top: 10px;
        margin-bottom: 10px;
        p {
          margin: 12px 0;
        }
      }
      &.color-red {
        color: $red;
      }
    }
    .button {
      @include text-base(
        $size: 14px,
        $family: 'Montserrat',
        $weight: $medium,
        $color: $white
      );
      margin-top: 30px;
      margin-bottom: 10px;
      width: 334px;
      height: 60px;
      flex-flow: column-reverse;
    }
  }
}

@media screen and (max-width: 767px) {
  .confirmation-page {
    &__logo-wrap {
      margin-top: 64px;
    }
    &__title {
      font-size: 16px;
    }
    .button {
      width: 90%;
    }
  }
}
