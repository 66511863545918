@import '../../styles/variables';
@import '../../styles/common';

.hintpage {
  @include display-flex;
  @include flex-direction(column);
  min-height: 100%;
  background-color: white;

  &.grid {
    display: grid;
    min-height: 100%;
    grid-template-areas:
      '. header .'
      '. title .'
      '. form .';
    grid-template-columns: 0 1fr 0;
    grid-template-rows: 5vw 10vw;

    .grid-area {
      &-page-title {
        grid-area: title;
        @include display-flex();
        @include align-items(center);
        @include justify-content(center);
        padding-left: $margin-desktop;
        padding-right: $margin-desktop;
        text-align: center;
        background: white;
      }

      &-form {
        grid-area: form;
        padding: 0 7vw;
        background-color: $bg-beige;
      }

      &-contact {
        grid-area: contact;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 4.2vw 7vw 0;
      }

      // End css-grid building for Desktop
    }

    .grid-area-page-title {
      @include display-flex();
      @include align-items(center);
      @include justify-content(center);
    }
  }
}

@media screen and (max-width: 1024px) {
  .hintpage {
    @include display-flex;
    @include flex-direction(column);
    min-height: 100%;

    &.grid {
      grid-template-rows: 7.552vw 10vw;
    }
  }
}

@media screen and (max-width: 767px) {
  .hintpage {
    @include display-flex;
    @include flex-direction(column);
    width: 100%;
    min-height: 100%;
    background-color: $beige;

    .page-title {
      font-size: 24px;
    }

    &.grid {
      grid-template-rows: 90px min-content !important;
      .grid-area-page-title {
        padding-top: 16px;
        padding-bottom: 24px;
      }
    }
  }
}
