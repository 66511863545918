@import 'styles/_fonts';
@import 'styles/_normalize';
@import 'styles/_reset';
@import 'styles/awesome-notifications/style';

html,
body,
#root {
  height: 100%;
  width: 100vw;
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden;

  &.prevent-scroll {
    overflow: hidden;
  }
}
