@import '_common.scss';
@import '_flex';

@mixin btn-color($bg, $bg-hover, $bg-active, $text, $text-hover, $text-active) {
  background-color: $bg;
  color: $text;

  &:hover {
    background-color: $bg-hover;
    color: $text-hover;

    .icon {
      background-color: $text-hover;
    }

    svg {
      path {
        fill: $text-hover;
        transition: $animation-global;
      }
    }
  }

  @media (pointer: coarse) {
    &:hover {
      background-color: $bg;
      color: $text;

      .icon {
        background-color: $dark-brown;
      }

      svg {
        path {
          fill: $dark-brown;
          transition: $animation-global;
        }
      }
    }
  }

  &:active,
  &.active {
    background-color: $bg-active;
    color: $text-active;

    .icon {
      background-color: $text-active;
    }

    svg {
      path {
        fill: $text-active;
        transition: $animation-global;
      }
    }
  }
}

@mixin btn-bordered(
  $bg-color,
  $border-color,
  $text-color,
  $color-hover,
  $color-active
) {
  background-color: $bg-color;
  border: 0.052vw solid $border-color;
  color: $text-color;

  svg {
    path {
      transition: $animation-global;
    }
  }

  &:hover {
    background-color: $color-hover;
    border-color: $color-hover;
    color: $white;

    .icon {
      background-color: $white;
    }

    svg {
      path {
        fill: $white;
        transition: $animation-global;
      }
    }

    @media (pointer: coarse) {
      background-color: $bg-color;
      border-color: $border-color;
      color: $text-color;

      .icon {
        background-color: $dark-brown;
      }

      svg {
        path {
          fill: $white;
          transition: $animation-global;
        }
      }
    }
  }

  &:active,
  &.active {
    background-color: $color-active;
    border-color: $color-active;
    color: $white;

    .icon {
      background-color: $white;
    }

    svg {
      path {
        fill: $white;
        transition: $animation-global;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    border-width: 1px;
  }
}

@mixin btn-link($color, $color-hover, $color-active) {
  background-color: initial;

  a {
    color: $color;
    text-decoration: none;
    border-bottom: 2px solid $color;
    padding-bottom: 5px;
  }

  &:hover {
    cursor: pointer;

    a {
      color: $color-hover;
      border-bottom-color: $color-hover;

      @media (pointer: coarse) {
        color: $color;
        border-bottom-color: $color;
      }
    }
  }

  &:active,
  &.active {
    a {
      color: $color-active;
      border-bottom-color: $color-active;
    }
  }
}

// Btn basic
.btn {
  @include text-base($size: 0.729vw, $weight: $medium);
  padding: 0.964vw 0.938vw;
  @include display-inline-flex;
  @include align-items(center);
  @include justify-content(space-around);
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-width: 0;
  text-decoration: none;
  transition: $animation-global;

  // Btn basic
  &:hover {
    color: $gray2;

    .icon {
      background-color: $gray2;
    }

    svg {
      path {
        fill: $gray2;
        transition: $animation-global;
      }
    }
  }

  @media (pointer: coarse) {
    &:hover {
      color: $dark-brown;

      .icon {
        background-color: $dark-brown;
      }

      svg {
        path {
          fill: $dark-brown;
          transition: $animation-global;
        }
      }
    }
  }

  &:active,
  &.active {
    color: $brown-black;

    .icon {
      background-color: $brown-black;
    }

    svg {
      path {
        fill: $brown-black;
        transition: $animation-global;
      }
    }
  }

  // Btn size styles
  &_xs {
    padding: 0.808vw 1.972vw;
  }

  &_sm {
    padding: 0.885vw 3.49vw;
  }

  &_md {
    padding: 1.51vw 2.344vw;
  }

  &_lg {
    padding: 1.51vw 4.688vw;
  }

  &_lg_popup {
    padding: 1.51vw 4.688vw;
  }

  &_lg2 {
    padding: 19px 109px;
  }

  &_px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &_p-0 {
    padding: 0 !important; // For all @media
  }

  // Btn text styles
  &_text-sm {
    font-size: 0.729vw;
  }

  &_text-md {
    font-size: 0.833vw;
  }

  &_text-big {
    font-size: 0.885vw;
  }

  &_text-xl {
    font-size: 1.302vw;
  }

  &_text-bold {
    font-weight: bold;
  }

  &_font-montserrat {
    font-family: 'Montserrat', sans-serif;
  }

  &_font-roboto {
    font-family: 'Roboto Condensed', sans-serif;
  }

  // Btn colors
  &_default {
    @include btn-color(
      $white,
      $gray2,
      $dark-brown,
      $dark-brown,
      $white,
      $white
    );
  }

  &_disabled {
    @include btn-color($beige2, $gray2, $brown-black, $white, $white, $white);
  }

  &_brown {
    @include btn-color(
      $dark-brown,
      $gray2,
      $brown-black,
      $white,
      $white,
      $white
    );
  }

  &_gray {
    @include btn-color(
      $gray2,
      $dark-brown,
      $brown-black,
      $white,
      $white,
      $white
    );
  }

  &_danger {
    @include btn-color($red, $red2, $red3, $white, $white, $white);
  }

  &_bordered-default_without_border {
    @include btn-bordered(
      transparent,
      transparent,
      $brown-black,
      $dark-brown,
      $brown-black
    );
  }

  &_bordered-default_main_page {
    @include btn-bordered(
      transparent,
      transparent,
      $brown-black,
      $dark-brown,
      $brown-black
    );
  }

  &_bordered-default {
    @include btn-bordered(
      transparent,
      $dark-brown,
      $brown-black,
      $dark-brown,
      $brown-black
    );
  }

  &_bordered-opacity {
    @include btn-bordered(
      rgba(255, 255, 255, 0.8),
      transparent,
      $brown-black,
      rgba(255, 255, 255, 1),
      $brown-black
    );
  }

  &_bordered-brown {
    @include btn-bordered(
      $white,
      $dark-brown,
      $dark-brown,
      $dark-brown,
      $brown-black
    );
  }

  &_bordered-dark-gray {
    @include btn-bordered(
      $white,
      $dark-gray,
      $dark-brown,
      $dark-brown,
      $brown-black
    );
  }

  &_link-brown {
    @include btn-link($dark-brown, $dark-gray, $brown-black);
  }

  // Btn special properties
  &_fixed-height {
    height: 3.073vw;
    min-height: 3.073vw;
    max-height: 3.073vw;
  }

  &_stretch {
    width: 100%;
    //@include justify-content(center);
  }

  &_stretch-mobile {
    @media screen and (max-width: 767px) {
      width: 100%;
      @include justify-content(center);
    }
  }

  &_circle {
    padding: 0;
    width: 1.823vw;
    height: 1.823vw;
    border-radius: $border-radius-circle;

    .icon {
      max-width: 45%;
    }

    &-sm {
      width: 2.292vw;
      height: 2.292vw;
    }

    &-xl {
      width: 3.438vw;
      height: 3.438vw;

      .icon {
        max-width: 30%;
      }
    }
  }

  &_rounded {
    border-radius: 2.604vw;
  }

  &.disabled,
  &:disabled {
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.disabled-loading {
    background-color: $gray3;
    color: transparent;
  }

  // Btn margins
  &_my-sm {
    margin-top: 0.521vw;
    margin-bottom: 0.521vw;
  }

  &_my-md {
    margin-top: 1.042vw;
    margin-bottom: 1.042vw;
  }

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    padding: 17px 15px;

    &_xs {
      padding: 12px 23px;
    }

    &_sm {
      padding: 19px 40px;
    }

    &_md {
      padding: 29px 45px;
    }

    &_lg {
      padding: 29px 90px;
    }

    &_lg_popup {
      padding: 16px 65px;
    }

    &_text-big {
      font-size: 13px;
    }

    &_text-xl {
      font-size: 25px;
    }

    &_fixed-height {
      height: 40px;
      min-height: 40px;
      max-height: 40px;
    }

    &_circle {
      padding: 0;
      width: 30px;
      height: 30px;

      &-sm {
        width: 44px;
        height: 44px;
      }

      &-xl {
        width: 52px;
        height: 52px;
      }
    }

    &_rounded {
      border-radius: 50px;
    }

    &_my-sm {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &_my-md {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    &_xs {
      padding: 10px 10px;
    }

    &_md {
      padding: 15px 20px;
    }

    &_text-xl {
      font-size: 20px;
    }
  }
}

.btn_expanded {
  padding: 0.964vw 0.938vw;

  .btn_expanded_front {
    @include display-flex();
    @include align-items(center);
    @include justify-content(center);
    width: 1.302vw;
    height: 1.302vw;
  }

  .btn_expanded_behind {
    @include display-flex();
    @include align-items(center);
    @include justify-content(center);
    height: 1.302vw;
    max-width: 0;
    overflow: hidden;
    transition: $animation-global;
  }

  @media screen and (max-width: 1024px) {
    padding: 15px;

    .btn_expanded_front {
      width: 25px;
      height: 25px;
    }

    .btn_expanded_behind {
      height: 25px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 7px;
  }
}

@mixin btn_expanded($icon, $icon-active) {
  .btn_expanded_front {
    .btn_expanded_icon {
      // Mask to replace svg-icons
      mask: url('../../src/img/svgicons/#{$icon}.svg');
      -webkit-mask: url('../../src/img/svgicons/#{$icon}.svg');
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-size: auto;
      -webkit-mask-size: auto;
    }
  }

  &:active,
  &.active {
    .btn_expanded_front {
      .btn_expanded_icon {
        mask: url('../../src/img/svgicons/#{$icon-active}.svg');
        -webkit-mask: url('../../src/img/svgicons/#{$icon-active}.svg');
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-size: auto;
        -webkit-mask-size: auto;
        transition: $animation-global;
      }
    }

    .btn_expanded_behind {
      max-width: 600px;
      transition: $animation-global;
    }
  }
}
