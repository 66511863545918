@import '../../../styles/variables';
@import '../../../styles/common';

.custom-input {
  &_disabled {
    cursor: default;

    > .label-text {
      cursor: default !important;
    }
  }

  &__svg {
    position: relative;

    .custom-input-field {
      padding-right: 15px;
    }

    svg {
      width: 1.198vw;
      height: 1.042vw;
      position: absolute;
      top: 2vw;
      right: 5px;
      cursor: pointer;
      @media (max-width: 1024px) {
        width: 14px;
        height: 12px;
        top: 4vw;
      }
      @media (max-width: 1024px) and (orientation: portrait) {
        top: 5.5vw;
      }
      @media (max-width: 900px) and (orientation: landscape) {
        top: 5vw;
      }
      @media (max-width: 767px) and (orientation: portrait) {
        top: 11vw;
      }

      &:hover {
        path {
          fill: $dark-brown;
        }
      }

      path {
        fill: $gray2;
      }
    }
  }
}
