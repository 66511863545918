@import '../../../../styles/flex';
@import '../../../../styles/variables';
@import '../../../../styles/common';

.content-main {
  &_block {
    //brighter-block
    &__brighter {
      @include display-flex;
      @include flex-direction(row);
      @include justify-content(space-evenly);
      flex-wrap: wrap;
      padding-right: 2vw;
      padding-left: 2vw;
      background-color: transparent;
      @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        max-width: 100% !important;
        height: fit-content !important;
        @include flex-wrap(nowrap);
        margin-bottom: 72px;
      }
      @media only screen and (max-width: 1366px) and (orientation: landscape) {
        max-width: 100% !important;
        height: auto !important;
        @include flex-wrap(nowrap);
        margin-bottom: 72px;
      }
      @media (max-width: 1024px) {
        height: 80%;
        width: 55%;
        @include justify-content(center);
        @include align-content(center);
        background-color: transparent;
      }
      @media (max-width: 767px) {
        max-width: 80%;
        height: auto;
      }

      .images-block {
        @include display-flex;
        @include flex-direction(column);
        @include justify-content(space-between);
        width: 25%;
        max-height: 37.5vw;
        padding: 2vw;
        box-sizing: border-box;
        @media (max-width: 1024px) {
          padding: 15px;
          width: 50%;
          height: 100%;
        }
        @media (max-width: 767px) {
          padding: 10px;
        }

        .img-container {
          display: flex;
          width: 100%;

          @media only screen and (max-width: 1366px) and (orientation: landscape) {
            height: auto;
          }
          @media (max-width: 1024px) {
            max-width: 200px;
            height: 100%;
          }
          @media (max-width: 767px) {
            border: 1px solid transparent;
            max-width: 150px;
          }

          > img {
            height: 100%;
          }

          > svg {
            width: 100%;
            height: auto;
            position: absolute;
          }

          &.br2_2,
          &.br2_1 {
            height: 44%;
            align-items: flex-start;

            svg {
              height: 100%;
            }
          }

          &.br2_2 {
            align-items: flex-end;
          }

          .light-loader__container {
            width: 200px;
          }
        }
      }
    }

    //brighter-block end
  }

  .main-slide {
    &_brighter {
      min-height: 568px;
      height: 1100px;

      @media screen and (max-width: 767px) {
        height: 700px;
      }

      @media screen and (max-width: 400px) {
        height: 700px;
      }
    }
  }
}

#brighter {
  justify-content: center;
}
