@import '../../styles/variables';
@import '../../styles/common';

.page404 {
  &.grid {
    display: grid;
    min-height: 100%;
    grid-template-areas: '. header header header .' '. . content . .';
    grid-template-columns: $margin-desktop 1fr 47vw 1fr $margin-desktop;
    grid-template-rows: minmax(190px, 20vh);
    background-image: linear-gradient(to right, $bg-beige 50%, $beige5 50%);

    .grid-area {
      &-header {
        grid-area: header;
      }
      &-content {
        grid-area: content;
      }
    }
  }
}
