@import '_flex';
@import '_variables';

@mixin transition($params...) {
  -webkit-transition: $params;
  -o-transition: $params;
  -moz-transition: $params;
  transition: $params;
}

@mixin transform($params...) {
  -webkit-transform: $params;
  -moz-transform: $params;
  -ms-transform: $params;
  -o-transform: $params;
  transform: $params;
}

@mixin text-base(
  $size: 12px,
  $family: 'Montserrat',
  $weight: $medium,
  $style: normal,
  $color: $dark-brown,
  $fallback-family: 'sans-serif'
) {
  font-size: $size;
  font-family: $family, $fallback-family;
  font-weight: $weight;
  font-style: $style;
  color: $color;
}

.bold {
  font-weight: $bold !important;
}

.page-title {
  @include text-base($size: 2.5vw, $family: 'Roboto Condensed', $weight: $bold);
  text-transform: uppercase;
  //margin-bottom: 1.563vw;

  @media screen and (max-width: 767px) {
    font-size: 38px;
  }
}

.diamond-sku {
  @include text-base(0.885vw);
  line-height: 2.5vw;

  &_mb-17 {
    margin-bottom: 17px;
  }

  &_mb-20 {
    margin-bottom: 20px;
  }

  &_large-bold {
    font-size: 1.25vw;
    font-weight: bold;
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;

    &_large-bold {
      font-size: 16px;
    }
  }
}

.diamond-price {
  @include text-base(
    $size: 1.25vw,
    $family: 'Roboto Condensed',
    $weight: $bold
  );

  &_medium {
    font-size: 2.5vw;
  }

  &_large {
    font-size: 4.167vw;
  }

  &_mb-22 {
    margin-bottom: 1.146vw;
  }

  @media screen and (max-width: 1024px) {
    font-size: 24px;

    &_medium {
      font-size: 38px;
    }

    &_large {
      font-size: 3.646vw;
    }
  }

  @media screen and (max-width: 767px) {
    &_large {
      font-size: 38px;
    }
  }
}

.diamond-old-price{
  color: $red;
  text-decoration: line-through;
  align-self: end;
}

.diamond-amount {
  @include text-base($size: 2.5vw, $family: 'Roboto Condensed', $weight: $bold);

  &_mb-25 {
    margin-bottom: 25px;
  }

  &_small {
    font-size: 1.25vw;
  }

  &_gold {
    color: $yellow;
  }

  &_line-through {
    text-decoration: line-through;
  }

  @media screen and (max-width: 1024px) {
    font-size: 3.711vw;

    &_small {
      font-size: 24px;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 38px;

    &_small {
      font-size: 18px;
    }

    &_line-through {
      font-size: 20px;
    }
  }
}

.title-label {
  @include text-base($size: 0.729vw, $color: $gray2);
  line-height: 1.7;

  &_mb-20 {
    margin-bottom: 20px;
  }

  &_mr-50 {
    margin-right: 50px;
  }

  &_mr-15 {
    margin-right: 15px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
}

.text-brown {
  color: $dark-brown;
}

.expanded {
  letter-spacing: 3px;
}

.expanded-strong {
  letter-spacing: 25px;
}

.uppercase {
  text-transform: uppercase;
}

.mt-30 {
  margin-top: 30px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-20 {
  margin-bottom: 20px;
}

.float-right {
  float: right;
}

.position-relative {
  position: relative;
}

.hidden-for-desktop {
  @media screen and (min-width: 1025px) {
    display: none;
  }
}

.hidden-for-laptop {
  display: block;
  @media (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
}

.show-for-laptop {
  display: none;
  @media (min-width: 768px) and (max-width: 1024px) {
    display: block;
  }
}

.show-for-mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.decoration {
  position: relative;

  &:after {
    display: block;
    content: '';
    height: 0.208vw;
    width: 2.083vw;
    min-height: 2px;
    min-width: 40px;
    position: absolute;
    bottom: -17px;
    left: 0;
    background-color: $dark-brown;
  }
}

.decoration-line {
  border-bottom: 0.052vw solid #4d4048;

  @media screen and (max-width: 1024px) {
    border-bottom: 1px solid #4d4048;
  }
}

.decoration-underline {
  text-decoration: underline;
}

.icons-container {
  @include display-flex;

  button {
    margin: 0.25vw;
  }
}

.area-around-icon_margins {
  margin-top: 18px;
  margin-bottom: 18px;

  &:first-child {
    margin-right: 18px;
  }
}

ul.diamond-characteristics {
  list-style: none;

  li {
    display: inline;
    @include text-base(0.885vw);

    & + li:before {
      content: '';
      display: inline-block;
      width: 0.052vw;
      min-width: 1px;
      height: 1.042vw;
      position: relative;
      top: 0.156vw;
      right: 0;
      margin-left: 1.042vw;
      margin-right: 1.042vw;
      background-color: $gray3;
    }
  }

  @at-root &_margin {
    margin-bottom: 2.083vw;
  }
  @at-root &_small-font {
    li {
      font-size: 0.729vw;
    }
  }

  @media screen and (max-width: 1024px) {
    li {
      font-size: 14px;

      & + li:before {
        width: 1px;
        height: 20px;
        top: 4px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  // Only for mobile
  &_with-lines {
    li {
      span:first-child {
        display: none;
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 10px !important;

      li {
        span {
          font-size: 10px;
        }
      }
    }
  }
}

@mixin page-pointer {
  position: relative;
  box-sizing: border-box;

  a {
    @include text-base(2.5vw, $family: 'Roboto Condensed');
    text-transform: uppercase;
    color: $dark-brown;
    text-decoration: none;

    &:after {
      content: '';
      display: block;
      width: 2.604vw;
      height: 0.208vw;
      min-width: 30px;
      min-height: 4px;
      position: absolute;
      top: 1.146vw;
      background-color: $dark-brown;
    }

    &:hover {
      color: $gray2;
      cursor: pointer;

      &:after {
        background-color: $gray2;
      }
    }

    @media (pointer: coarse) {
      &:hover {
        color: $dark-brown;

        &:after {
          background-color: $dark-brown;
        }
      }
    }

    &:active {
      color: $gray2;

      &:after {
        background-color: $gray2;
      }
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
      color: $gray2;

      &:after {
        background-color: $gray2;
      }
    }

    @media screen and (max-width: 1024px) {
      font-size: 25px;
    }
  }

  &_small {
    a {
      font-size: 1.302vw;

      &:after {
        top: 0.521vw;
      }

      @media screen and (max-width: 1024px) {
        font-size: 25px;

        &:after {
          top: 10px;
        }
      }
    }
  }

  // For mobile some page-pointers should look like buttons
  @media screen and (max-width: 767px) {
    &_btn-mobile {
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;

      a {
        box-sizing: border-box;
        width: 100%;
        display: block;
        @include text-base($size: 14px, $weight: $regular, $color: $white);
        text-align: center;
        padding: 15px 20px;
        background-color: $dark-brown;

        &:after {
          display: none;
        }

        &:hover {
          cursor: pointer;
          color: white;
          background-color: $gray2;
        }

        @media (pointer: coarse) {
          &:hover {
            background-color: $dark-brown;
          }
        }

        &:active,
        &.active {
          background-color: $gray2;
        }
      }
    }
  }
}

.next-page-pointer {
  @include page-pointer;
  padding-right: 3.906vw; //set the padding-right for block to :after does not go outside the grid

  &__link {
    float: right;

    &:after {
      right: 0;
    }
  }

  @media screen and (max-width: 1024px) {
    padding-right: 50px;
  }
}

.prev-page-pointer {
  @include page-pointer;
  padding-left: 3.906vw; //set the padding-left for block to :after does not go outside the grid

  &__link {
    &:after {
      left: 0;
    }
  }

  @media screen and (max-width: 1024px) {
    padding-left: 50px;
  }
}

ul.grid-area-breadcrumbs {
  list-style: none;
  z-index: 9;

  li {
    display: inline;
    @include text-base($size: 0.729vw, $color: $gray2, $weight: $semibold);
    text-decoration: none;
    text-transform: uppercase;

    & + li:before {
      content: '';
      display: inline-block;
      width: 0.052vw;
      height: 0.729vw;
      position: relative;
      top: 0.104vw;
      right: 0;
      margin-left: 1.042vw;
      margin-right: 1.042vw;
      background-color: $gray2;
      transform: rotate(20grad);
    }

    a {
      @include text-base($size: 0.729vw, $color: $gray2, $weight: $semibold);
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        color: $dark-gray;
        cursor: pointer;
      }

      &:active {
        color: $dark-brown;
      }
    }

    &:last-child {
      color: $dark-brown;
    }
  }

  @media screen and (max-width: 1024px) {
    li {
      font-size: 12px;

      & + li:before {
        width: 1px;
        height: 14px;
        top: 2px;
        margin-left: 10px;
        margin-right: 10px;
        transform: rotate(20grad);
      }

      a {
        font-size: 12px;
      }
    }
  }
}

//Custom Checkbox
.checkbox-container {
  @include text-base($size: 0.833vw);
  display: block;
  width: fit-content;
  position: relative;
  padding-left: 1.823vw;
  margin-bottom: 0.625vw;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $dark-brown;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: -0.18vw;
    left: 0;
    width: 0.94vw;
    height: 0.94vw;
    background-color: $white;
    border: 0.052vw solid $dark-brown;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 0.299vw;
      top: 0.139vw;
      width: 0.18vw;
      height: 0.42vw;
      border: solid white;
      border-width: 0 0.14vw 0.15vw 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &.disabled {
    color: $gray3;
    pointer-events: none;

    input[type='checkbox'] {
      &:checked ~ .checkmark {
        background-color: $gray3;
      }
    }

    .checkmark {
      border-color: $gray3;
    }
  }

  &:hover input ~ .checkmark {
    background-color: $beige;
  }

  &_mt-30 {
    margin-top: 1.563vw;
  }

  @media screen and (max-width: 1024px) {
    @include text-base($size: 16px);
    display: block;
    width: fit-content;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background-color: $dark-brown;

        &:after {
          display: block;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: -2px;
      left: 0;
      width: 16px;
      height: 16px;
      background-color: $white;
      border: 1px solid $dark-brown;

      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 5px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &:hover input ~ .checkmark {
      background-color: $beige;
    }

    &_mt-30 {
      margin-top: 30px;
    }
  }
}

/* Custom Radio Button */
.radio-button-container {
  @include text-base($size: 0.833vw);
  width: 100%;
  box-sizing: border-box;
  @include display-flex;
  @include justify-content(space-between);
  position: relative;
  padding-left: 1.823vw;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  //align-items: center;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: -0.156vw;
    left: 0;
    height: 0.938vw;
    width: 0.938vw;
    background-color: #eee;
    border-radius: $border-radius-circle;

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 0.313vw;
      left: 0.313vw;
      width: 0.313vw;
      height: 0.313vw;
      border-radius: $border-radius-circle;
      background: white;
    }
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  span {
    display: block;

    &.radio-button_right-part {
      @include text-base(
        $size: 0.833vw,
        $family: 'Roboto Condensed',
        $weight: $bold
      );
      min-width: fit-content;
    }
  }

  &_mb-30 {
    margin-bottom: 1.563vw;
  }

  @media screen and (max-width: 1024px) {
    font-size: 14px;
    padding-left: 30px;

    .checkmark {
      top: -3px;
      left: 0;
      height: 18px;
      width: 18px;

      &:after {
        top: 6px;
        left: 6px;
        width: 6px;
        height: 6px;
      }
    }

    span {
      &.radio-button_right-part {
        font-size: 16px;
      }
    }

    &_mb-30 {
      margin-bottom: 2.93vw;
    }
  }
}

.radio-button-container input:checked ~ .checkmark {
  background-color: $dark-brown;
}

.radio-button-container input:checked ~ .checkmark:after {
  display: block;
}

// Info-Circle on page Details
.info-circle {
  width: 0.729vw;
  height: 0.729vw;
  min-width: 14px;
  min-height: 14px;
  border-radius: $border-radius-circle;
  background-color: $beige3;
  font-size: 0.521vw;
  @include display-inline-flex;
  @include align-items(center);
  @include justify-content(center);
  //margin-left: 0.521vw;
  cursor: pointer;
  position: relative;
  bottom: 0.156vw;
  color: $dark-brown;

  @media screen and (max-width: 1024px) {
    font-size: 10px;
    min-width: 20px;
    min-height: 20px;
    bottom: 3px;
  }
}

.active-info-circle {
  background-color: $gray2;
  color: $white;
}

.tooltip-base {
  width: 1.563vw;
  height: 1.563vw;
  min-width: 25px;
  min-height: 25px;
  @include display-inline-flex;
  @include align-items(center);
  @include justify-content(center);
  border-radius: $border-radius-circle;
  background-color: $white;
  position: relative;
  -webkit-tap-highlight-color: rgba(
    0,
    0,
    0,
    0
  ); // Delete blue area on mobile when tapped
  transition: $animation-global;

  &_ml-15 {
    margin-left: 15px;
  }
}

.active-tooltip {
  background-color: $beige3;
  color: $white;
  cursor: pointer;
  transition: $animation-global;
}

.custom-tooltip {
  .react-tooltip-lite {
    z-index: auto !important;
    @include text-base($size: 0.625vw, $weight: $regular);
    max-width: 17.969vw !important;
    padding: 15px !important;
    line-height: 1.7;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 1px 7px 20px 0 rgba(0, 0, 0, 0.07);
  }

  .react-tooltip-lite-arrow {
    display: none;
  }

  &_ml-15 {
    margin-left: 0.781vw;
  }

  @media screen and (max-width: 1024px) {
    .react-tooltip-lite {
      font-size: 12px;
      max-width: 345px !important;
    }

    &_ml-15 {
      margin-left: 15px;
    }
  }
  @media screen and (max-width: 1300px) {
    .react-tooltip-lite {
      font-size: 10px;
    }
  }
}

.quantity-items {
  list-style: none;
  text-align: right;

  li {
    display: inline;
    padding: 0.521vw;
    margin-left: 0.26vw;
    text-decoration: none;

    &:last-of-type {
      margin-right: 0.938vw;
    }

    span {
      @include text-base($size: 0.885vw, $weight: $medium, $color: $gray2);
      padding: 0.521vw 0;
      border-bottom: 0.104vw solid transparent;
    }

    &:hover {
      cursor: pointer;

      span {
        color: $dark-brown;
        cursor: pointer;
        border-bottom-color: $yellow;
      }

      @media (pointer: coarse) {
        span {
          color: $gray2;
          border-bottom-color: transparent;
        }
      }
    }

    &.active {
      cursor: pointer;

      span {
        color: $dark-brown;
        cursor: pointer;
        border-bottom-color: $yellow;
      }
    }
  }

  &__label {
    @include text-base($size: 0.885vw, $weight: $medium, $color: $dark-brown);
  }

  &_mb-40 {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1024px) {
    li {
      padding: 7px;
      margin-left: 5px;

      span {
        font-size: 12px;
        padding: 7px 0;
      }
    }

    &__label {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 767px) {
    li {
      &:last-of-type {
        padding-right: 0;
        margin-right: 0;
      }
    }

    &__label {
      display: none;
    }
  }
}

.pagination {
  list-style: none;
  text-align: right;

  li {
    display: inline-block;
    margin-left: 0.521vw;

    &:last-of-type {
      margin-right: 0.521vw;
    }

    a {
      @include text-base($size: 0.729vw, $weight: $medium, $color: $gray2);
      display: inline-block;
      padding: 0.521vw;
      text-decoration: none;

      &:hover {
        color: $dark-brown;
        cursor: pointer;

        @media (pointer: coarse) {
          color: $gray2;
        }
      }
    }

    span {
      @include text-base($size: 0.729vw, $weight: $medium, $color: $dark-brown);
      padding: 0.521vw;
    }
  }

  span.arrow {
    @include text-base($size: 0.938vw, $weight: $medium, $color: $gray2);
    padding: 0.521vw;

    &:hover {
      color: $dark-brown;
      cursor: pointer;
    }
  }

  &_my-50 {
    margin: 2.604vw 0;
  }

  &_mb-40 {
    margin-bottom: 40px;
  }

  &_mt-30 {
    margin-top: 30px;
  }

  &_my-30 {
    margin-bottom: 90px;
  }

  @media screen and (max-width: 1024px) {
    li {
      margin-left: 5px;

      &:last-of-type {
        margin-right: 5px;
      }

      a {
        font-size: 14px;
        padding: 7px;
      }

      span {
        font-size: 14px;
        padding: 7px;
      }
    }

    span.arrow {
      padding: 7px;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    text-align: center;

    li {
      margin-left: 2px;

      &:last-of-type {
        margin-right: 2px;
      }

      a {
        font-size: 11px;
        padding: 5px;
      }

      span {
        font-size: 11px;
        padding: 5px;
      }
    }

    span.arrow {
      padding: 5px;
    }
  }
}

@mixin slider-padding($cw, $rw, $rh, $c: null) {
  position: relative;
  width: $cw;
  height: 0;
  padding: (($rh / $rw) * $cw) 0 0 0;

  @if $c != null {
    #{$c} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.attention-message {
  @include text-base($size: 0.729vw, $weight: $regular, $color: $gray);
  line-height: 1.7;

  &_text-center {
    text-align: center;
  }

  // TODO: rename classes like at the bootstrap
  &_warning {
    color: $red;
  }

  &_orange {
    color: $orange;
  }

  &_bold {
    font-weight: $bold;
  }

  &_m-20 {
    margin: 20px;
  }

  &_mt-5 {
    margin-top: 5px;
  }

  &_mt-10 {
    margin-top: 0.521vw;
  }

  &_mt-20 {
    margin-top: 1.042vw;
  }

  &_mb-10 {
    margin-bottom: 0.521vw;
  }

  &_mb-15 {
    margin-bottom: 0.781vw;
  }

  &_mb-30 {
    margin-bottom: 1.563vw;
  }

  &_mb-35 {
    margin-top: 1.823vw;
  }

  &_pr-110 {
    padding-right: 110px;
  }

  &_off-stream {
    position: absolute;
  }

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    margin-top: 20px;

    &_mt-10 {
      margin-top: 10px;
    }

    &_mb-15 {
      margin-bottom: 15px;
    }
  }
}

.common-link {
  color: $dark-brown;
  text-decoration: none;
  padding-bottom: 0.156vw;
  border-bottom: 0.052vw solid $dark-brown;

  &:hover {
    color: $dark-gray;
    border-bottom-color: $dark-gray;
  }

  &:active {
    color: $gray2;
    border-bottom-color: $gray2;
  }

  @media screen and (max-width: 1024px) {
    padding-bottom: 2px;
    border-bottom-width: 1px;
  }
}

.tabs {
  height: 100%;
  @include display-flex;
  @include flex-wrap(wrap);
  position: relative;
  box-sizing: border-box;

  .input {
    position: absolute;
    opacity: 0;
  }

  .label {
    width: auto;
    cursor: pointer;
    margin-right: 3.646vw;
    box-sizing: border-box;

    .label-inner {
      @include text-base($size: 0.833vw, $weight: bold);
      letter-spacing: 0.104vw;
      transition: background 0.1s, color 0.1s;
      padding-top: 0.521vw;
      padding-bottom: 0.521vw;

      .icon {
        margin-right: 0.625vw;
        vertical-align: bottom;
      }
    }
  }

  .input:focus + .label {
    z-index: 1;
  }

  .input:checked + .label .label-inner {
    background: #fff;
    border-bottom: 0.104vw solid $yellow;
  }

  //@media (min-width: 100px) {
  //  .label {
  //    width: auto;
  //  }
  //}

  .panel {
    display: none;
    width: 100%;
    padding: 10px 0 10px;
    background: #fff;
    box-sizing: border-box;
    order: 99;
  }

  .input:checked + .label + .panel {
    display: block;
  }

  @media screen and (max-width: 1024px) {
    .label {
      width: calc(100% / 3);
      margin-right: 0;

      .label-inner {
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: fit-content;
      }
    }

    .input:checked + .label .label-inner {
      border-bottom: 1px solid $yellow;
    }
  }
}

.form-wrap {
  @include display-flex;
  @include align-items(center);
  @include justify-content(space-between);
  padding-right: 10px;

  .col {
    width: 50%;
    @include align-self(flex-start);

    &:first-child {
      margin-right: 4.688vw;
    }
  }

  @media screen and (max-width: 1024px) {
    @include flex-wrap(wrap);

    .col {
      width: 73%;

      &:first-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .col {
      width: 100%;
    }
  }
}

/* Change Autocomplete bg color in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 200px white inset;
}

input.autofill-bg-beige {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 200px $bg-beige inset;
  }
}

// TODO: redo
.form {
  font-family: Montserrat;

  .form-title {
    @include text-base($size: 1.25vw, $weight: bold, $color: $brown-black);
    margin-top: 1.042vw;
    margin-bottom: 1.042vw;
  }

  .select-container {
    margin-bottom: 0.5vw;

    &__error {
      position: relative;
      padding-top: 0.4vw;
      font-size: 0.6vw;
      color: $red;
    }

    &_mb-30 {
      margin-bottom: 1.563vw;
    }

    @media screen and (max-width: 1024px) {
      &_mb-30 {
        margin-bottom: 30px;
      }
    }
  }

  .form-label {
    display: block;
    position: relative; // remove .label-text outside of the flow
    margin-bottom: 0.5vw;

    // hack
    &_with-textarea {
      z-index: 1;
    }

    &__error {
      position: absolute;
      padding-top: 0.4vw;
      font-size: 0.6vw;
      color: $red;
      &_blocky {
        position: relative !important;
        display: block;
        margin: 2px 0;
      }
    }

    .label-text {
      @include text-base($size: 0.833vw, $color: $gray2);
      cursor: text;
      line-height: 1.25vw;
      -moz-transform: translate3d(0, -1.771vw, 0);
      -ms-transform: translate3d(0, -1.771vw, 0);
      -webkit-transform: translate3d(0, -1.771vw, 0);
      transform: translate3d(0, -1.771vw, 0);
      -webkit-transform-origin-x: 0;
      transition: all 0.3s;
      position: absolute; // remove .label-text outside of the flow

      &:hover {
        cursor: pointer;
      }

      // hack
      &_for-textarea {
        z-index: -1;
      }
    }

    .form-label__textarea {
      @include text-base($size: 0.833vw, $color: $brown-black);
      min-width: 100%;
      max-width: 100%;
      resize: none;
      box-sizing: border-box;
      border-color: transparent;
      border-bottom: 0.052vw solid $beige2;
      background-color: transparent;
      margin-top: 2vw;
      border-radius: 0; //For iOS Safari
      transition: all 0.3s;
      line-height: 1.3;
    }

    input {
      @include text-base($size: 0.833vw, $color: $brown-black);
      line-height: 1.042vw;
      border: 0;
      background-color: transparent;
      border-bottom: 0.052vw solid $beige2;
      outline: 0;
      border-radius: 0; //For iOS Safari
      padding: 0 0 0.56vw;
      transition: all 0.3s;
      width: 100%;
      box-sizing: border-box;
      margin-top: 1.719vw;
    }

    input:not(.animation-disabled):focus,
    .form-label__textarea:not(.animation-disabled):focus {
      border-bottom: 0.052vw solid $dark-brown;

      & + .label-text {
        -moz-transform: translate3d(0, -2.917vw, 0) scale(0.9);
        -ms-transform: translate3d(0, -2.917vw, 0) scale(0.9);
        -webkit-transform: translate3d(0, -2.917vw, 0) scale(0.9);
        transform: translate3d(0, -2.917vw, 0) scale(0.9);
      }
    }

    input.with-value,
    .form-label__textarea.with-value {
      & + .label-text {
        -moz-transform: translate3d(0, -2.917vw, 0) scale(0.9);
        -ms-transform: translate3d(0, -2.917vw, 0) scale(0.9);
        -webkit-transform: translate3d(0, -2.917vw, 0) scale(0.9);
        transform: translate3d(0, -2.917vw, 0) scale(0.9);
      }
    }

    //.animation-disabled {
    //  padding-right: 1vw;
    //}

    input:not(.animation-disabled).shrink,
    .form-label__textarea:not(.animation-disabled).shrink {
      & + .label-text {
        -moz-transform: translate3d(0, -2.917vw, 0) scale(0.9);
        -ms-transform: translate3d(0, -2.917vw, 0) scale(0.9);
        -webkit-transform: translate3d(0, -2.917vw, 0) scale(0.9);
        transform: translate3d(0, -2.917vw, 0) scale(0.9);
      }
    }

    input.invalid,
    .form-label__textarea.invalid {
      border-bottom-color: $red !important;

      & + .label-text {
        color: $red;
      }
    }

    input.invalid::-webkit-input-placeholder {
      color: $red !important;
    }

    &_width-50 {
      width: 50%;
    }

    &_with-static-label {
      display: block;
      padding-top: 0;

      .label-text {
        -moz-transform: translateY(-1.971vw);
        -ms-transform: translateY(-1.971vw);
        -webkit-transform: translateY(-1.971vw);
        transform: translateY(-1.971vw);
      }

      input {
        padding: 0.833vw 0;
        padding-left: 50%;
        padding-right: 1vw;
        text-align: right;
        margin-top: 0.573vw;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .form-title {
      font-size: 14px;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .form-label {
      &__error {
        position: absolute;
        padding-top: 5px;
        font-size: 10px;
        color: $red;
      }

      .label-text {
        font-size: 13px;
        line-height: 24px;
        -moz-transform: translateY(-34px);
        -ms-transform: translateY(-34px);
        -webkit-transform: translateY(-34px);
        transform: translateY(-34px);
      }

      .form-label__textarea {
        font-size: 14px;
        border-bottom-width: 1px;
        margin-top: 37px;
      }

      input {
        font-size: 14px;
        line-height: 20px;
        padding: 0 0 8px;
        border-bottom-width: 1px;
        margin-top: 34px;
      }

      input:not(.animation-disabled):focus,
      .form-label__textarea:not(.animation-disabled):focus {
        border-bottom-width: 1px;

        & + .label-text {
          font-size: 13px;
          -moz-transform: translateY(-56px);
          -ms-transform: translateY(-56px);
          -webkit-transform: translateY(-56px);
          transform: translateY(-56px);
        }
      }

      input.with-value,
      .form-label__textarea.with-value {
        & + .label-text {
          font-size: 13px;
          -moz-transform: translateY(-56px);
          -ms-transform: translateY(-56px);
          -webkit-transform: translateY(-56px);
          transform: translateY(-56px);
        }
      }

      input:not(.animation-disabled).shrink,
      .form-label__textarea:not(.animation-disabled).shrink {
        & + .label-text {
          font-size: 13px;
          -moz-transform: translateY(-56px);
          -ms-transform: translateY(-56px);
          -webkit-transform: translateY(-56px);
          transform: translateY(-56px);
        }
      }

      input.invalid,
      .form-label__textarea.invalid {
        border-bottom-width: 1px;

        & + .label-text {
          color: $red;
        }
      }

      &_with-static-label {
        padding-top: 20px;

        input {
          padding: 9px 0;
          margin-top: 0;
        }
      }
    }
  }
}

.form-textarea {
  .label-text {
    @include text-base($size: 0.833vw, $color: $gray2);
  }

  textarea {
    @include text-base($size: 0.833vw);
    min-width: 100%;
    max-width: 100%;
    min-height: 2.604vw;
    max-height: 5.208vw;
    box-sizing: border-box;
    border-color: transparent;
    border-bottom: 0.052vw solid $beige2;
    background-color: transparent;
    margin-top: 0.521vw;
    border-radius: 0; //For iOS Safari
  }

  &_mt-30 {
    margin-top: 1.563vw;
  }

  &_mt-15 {
    margin-top: 1.563vw;
  }

  @media screen and (max-width: 1024px) {
    .label-text {
      font-size: 13px;
    }

    textarea {
      font-size: 14px;
      min-height: 60px;
      max-height: 100px;
      border-bottom-width: 1px;
      margin-top: 10px;
    }

    &_mt-30 {
      margin-top: 30px;
    }

    &_mt-15 {
      margin-top: 15px;
    }
  }
}

input.single-input[type='text'] {
  @include text-base($size: 0.833vw, $color: $dark-brown);
  width: 100%;
  padding: 0.521vw 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  border-bottom: 0.052vw solid $beige2;
  box-sizing: border-box;
  border-radius: 0; // For iOS Safari
  min-width: 0; // for FireFox

  &::placeholder {
    color: $gray2;
  }

  &:focus {
    border-bottom-color: $dark-brown;
  }

  @media screen and (max-width: 1024px) {
    font-size: 14px;
    padding: 10px 0;
    border-bottom: 1px solid $beige2;
    &::placeholder {
      font-size: 13px;
    }
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px; // style for horizontal scrollbar

  &-track {
    background: $gray3;
    border-radius: 20px;
  }

  &-thumb {
    background: $gray;
    border-radius: 20px;

    &:hover {
      background: $gray2;
      cursor: pointer;
    }
  }
}

.block-scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px; // style for horizontal scrollbar

    &-track {
      border-radius: 10px;
      background: #f1f1f1;
    }

    &-thumb {
      background: $gray4;
      border-radius: 3px;

      &:hover {
        background: $gray;
        cursor: pointer;
      }
    }
  }

  &_margin-right {
    margin-right: -2.604vw;
    padding-right: 2.604vw;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      padding-right: 0;
    }
  }
}

.no-scrollbar {
  scrollbar-width: none; // For FireFox
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.select-container {
  select.select {
    // Use select`s background for drawing angles (do not use html elements - the underlying select will not receive the focus)
    background-size: 0.6em;
    background-repeat: no-repeat;
    //There will be an error due to path problems with importing _common.scss to deep nesting files
    //background: url('../../../img/svgicons/angle-down-regular.svg') no-repeat;
  }

  &_angle-down {
    select.select {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-angle-down fa-w-10 fa-2x'%3E%3Cpath fill='%23928A8E' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z' class=''%3E%3C/path%3E%3C/svg%3E");
      background-position: calc(100% - 1.3em) calc(100% - 1.1em);
    }

    &:hover {
      select.select {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-angle-down fa-w-10 fa-2x'%3E%3Cpath fill='%2362545d' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z' class=''%3E%3C/path%3E%3C/svg%3E");
      }
    }
  }

  &_angle-up {
    select.select {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-up' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-angle-up fa-w-10 fa-2x'%3E%3Cpath fill='%23928A8E' d='M168.5 164.2l148 146.8c4.7 4.7 4.7 12.3 0 17l-19.8 19.8c-4.7 4.7-12.3 4.7-17 0L160 229.3 40.3 347.8c-4.7 4.7-12.3 4.7-17 0L3.5 328c-4.7-4.7-4.7-12.3 0-17l148-146.8c4.7-4.7 12.3-4.7 17 0z' class=''%3E%3C/path%3E%3C/svg%3E");
      background-position: calc(100% - 1.3em) calc(100% - 1em);
    }

    //Do not animate if finger touch events due to select peculiar properties on touch-screen devices
    @media (pointer: coarse) {
      select.select {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-angle-down fa-w-10 fa-2x'%3E%3Cpath fill='%23928A8E' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z' class=''%3E%3C/path%3E%3C/svg%3E");
        background-position: calc(100% - 1.3em) calc(100% - 1.1em);
      }
    }
  }
}

select.select {
  @include text-base($size: 0.833vw, $color: $gray2);
  display: inline-block;
  width: 100%;
  padding: 1.563vw 1.042vw 0.677vw 0;
  border-radius: 0;
  border: none;
  border-bottom: 0.052vw solid $beige2;
  background-color: $white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:hover {
    cursor: pointer;
  }

  &.no-value,
  &.valid,
  &.invalid-init {
    border-bottom-color: $beige2;
    color: $gray2;
  }

  &.invalid {
    border-bottom-color: $red;
    color: $red;
  }

  &_mb-30 {
    margin-bottom: 1.563vw;
  }

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    padding: 40px 20px 13px 0;
    border-bottom-width: 1px;

    &_mb-30 {
      margin-bottom: 30px;
    }
  }
}

// Styles for custom select without label end

.select-card {
  position: relative;

  .single-input {
    width: 100% !important;

    &.no-value,
    &.valid,
    &.invalid-init {
      border-bottom: 0.052vw solid $beige2;
      color: $dark-brown;
    }

    &.invalid {
      border-bottom-color: $red;
    }
  }

  .custom-select {
    position: absolute;
    top: 0;
    right: 0;
    width: 27%;

    .selected-item {
      padding: 0.781vw 1.042vw;
      border-bottom: none;
    }
  }

  @media screen and (max-width: 1024px) {
    .single-input {
      padding: 15px 0 !important;
    }

    .custom-select {
      .selected-item {
        padding: 15px 20px;
      }
    }
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  align-items: center;
  justify-content: center;

  &.open {
    display: flex;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &-window {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;
    background-color: $white;
    padding: 2.083vw 4.6vw;
    width: 45%;
    box-shadow: 0 3.125vw 5.156vw #00000029;
    position: relative;

    &_md {
      width: 35%;
      padding: 2.083vw 4.375vw 3.083vw;
    }

    &_mmd {
      width: 65vw;
      height: 75vh;
      padding: 0;
      overflow-y: hidden;
    }

    &_lg {
      width: 98vw;
      height: 98vh;
      padding: 0;
      overflow-y: auto;
    }

    &_no-padding {
      padding: 0;
      max-height: 98%;
    }

    &_full-screen {
      width: 100vw;
      height: 100vh;
      padding: 0;
      overflow-y: auto;
    }

    &-header {
      text-align: center;

      &__title {
        @include text-base(
          $size: 2.5vw,
          $family: 'Roboto Condensed',
          $weight: $bold
        );
        text-align: center;
        margin: 1.563vw 0;

        @media screen and (max-width: 1024px) {
          @include text-base(
            $size: 24px,
            $family: 'Roboto Condensed',
            $weight: bold
          );
          line-height: 24px;
        }

        @media screen and (max-width: 767px) {
          @include text-base(
            $size: 22px,
            $family: 'Roboto Condensed',
            $weight: bold
          );
          line-height: 24px;
        }
      }
    }

    &-body {
      // And add .block-scrollbar to make the scrollbar look beautiful
      &_scrollable {
        padding-right: 1.563vw;
        margin-right: -1.563vw;
        overflow: auto;
      }

      &__text {
        @include text-base($size: 1.2vw);
        line-height: 1.25vw;

        @media screen and (max-width: 1024px) {
          @include text-base($size: 1.8vw);
          line-height: 2vw;
        }

        @media screen and (max-width: 767px) {
          @include text-base($size: 13px);
          line-height: 16px;
        }

        &_medium {
          @include text-base(
            $size: 13px,
            $family: 'Montserrat',
            $weight: $medium
          );
        }

        &__small-text {
          @include text-base(
            $size: 14px,
            $family: 'Montserrat',
            $weight: $medium
          );
        }

        &_big {
          @include text-base(
            $size: 18px,
            $family: 'Montserrat',
            $weight: $bold
          );
          line-height: 1.3;
        }

        &_center {
          text-align: center;
        }

        &_light {
          color: $gray;
        }

        &_my-10 {
          margin-top: 0.521vw;
          margin-bottom: 0.521vw;
        }
      }

      &-btn-wrap {
        @include display-flex();
        @include justify-content(center);
        margin: 30px 0;

        .btn:not(:last-child) {
          margin-right: 1.042vw;
        }

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: center;

          .btn {
            width: 100%;
            &:not(:last-child) {
              margin-right: 0;
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    &-scroll-area {
      padding-right: 1.563vw;
      margin-right: -1.563vw;
      overflow-y: auto;
    }

    &__title {
      @include text-base(
        $size: 2.5vw,
        $family: 'Roboto Condensed',
        $weight: $bold
      );
      text-align: center;
      margin: 1.563vw 0;
      &_black {
        color: $black;
      }
    }

    &__text {
      @include text-base($size: 0.729vw);
      line-height: 1.25vw;

      &_center {
        text-align: center;
      }

      &_light {
        color: $gray;
      }

      &_black {
        color: $black;
      }

      &_my-10 {
        margin-top: 0.521vw;
        margin-bottom: 0.521vw;
      }

      &_my-md {
        margin-top: 1.042vw;
        margin-bottom: 1.042vw;
      }
    }

    &-btn-wrap {
      @include display-flex();
      @include justify-content(center);
      margin: 1.563vw 0;
    }

    &__btn-close {
      width: 2.395vw;
      height: 2.395vw;
      margin: 1.042vw;
      position: absolute;
      top: 0;
      right: 0;
      background-color: $beige5;

      &:hover {
        background-color: $dark-brown;

        > svg {
          path {
            fill: #fff;
            transition: $animation-global;
          }
        }
      }

      svg {
        path {
          fill: $dark-brown;
          transition: $animation-global;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &-window {
      height: 90%;
      width: 60%;
      box-shadow: 0 60px 99px #00000029;

      &_mmd {
        height: 50vh;
      }

      &_full-screen {
        width: 100vw;
        height: 100vh;
        padding: 0;
        overflow-y: auto;
      }

      &-inner {
        &__title {
          font-size: 25px;
        }

        &__text {
          font-size: 12px;
          line-height: 18px;

          &_medium {
            @include text-base(
              $size: 16px,
              $family: 'Montserrat',
              $weight: $medium
            );
          }

          &_my-10 {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }

        &-btn-wrap {
          margin: 30px 0;

          .btn {
            padding-top: 20px;
            padding-bottom: 20px;

            &:not(:last-child) {
              margin-right: 15px;
            }
          }
        }
      }

      &__title {
        font-size: 25px;
      }

      &__text {
        font-size: 12px;
        line-height: 18px;

        &_my-10 {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      &-btn-wrap {
        margin: 30px 0;

        .btn {
          padding: 17px 50px;
        }
      }

      &__btn-close {
        margin: 20px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &.open {
      display: flex;
      backdrop-filter: blur(30px);
      background-color: rgba(0, 0, 0, 0.3);
      overflow: hidden;
      height: 100vh;
    }

    &-window {
      width: auto;
      height: auto;
      max-width: 90%;
      max-height: 90%;
      padding: 50px 17px 18px 17px;
      overflow-y: auto;

      &.small-window {
        width: 70%;
        height: 40%;
      }

      &_mmd {
       height: 60vh;
      }

      &_full-screen {
        width: 100%;
        padding: 0;
      }

      &-inner {
        &_scrollable {
          padding-right: 10px;
          margin-right: -10px;
        }

        &_title {
          margin: 10px 0;
        }
      }

      &-scroll-area {
        padding-right: 10px;
        margin-right: -10px;
        margin-top: 20px; // Indent from the close-button
      }

      &_title {
        margin: 10px 0;
      }

      &__btn-close {
        width: 32px;
        height: 32px;
        margin: 10px;
      }
    }
  }

  @media screen and (max-width: 400px) {
    &-window {
      &_mmd {
        height: 80vh;
      }
    }
  }


    @media (max-width: 768px) and (orientation: landscape) {
    &-window {
      &.small-window {
        width: 90%;
        height: 90%;
      }
    }
  }
  .modal_bottom {
    position: absolute;
    text-align: center;
    padding: 0.781vw 0 0.781vw 0;
    bottom: 0;
    left: 0;
    background: $beige5 !important;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;
    @include text-base($size: 0.729vw, $color: $gray2);
  }
}

.list {
  &_unordered {
    padding-left: 2.083vw;
    list-style: disc;

    li {
      margin: 0.521vw 0;
    }

    @media screen and (max-width: 1024px) {
      padding-left: 30px;
      li {
        margin: 5px;
      }
    }
  }
}

.cropped-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.accordion {
  .accordion-item {
    width: 100%;
    overflow: hidden;

    input {
      position: absolute;
      display: none;
    }

    &__label {
      @include display-flex();
      @include justify-content(space-between);

      &::after {
        content: '\276F';
        width: 1em;
        text-align: center;
        transition: all 0.3s;
      }
    }

    &__content {
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: opacity 0.35s ease-out;
    }
  }

  input:checked {
    + .accordion-item__label {
      &::after {
        transform: rotate(90deg);
      }
    }

    ~ .accordion-item__content {
      opacity: 1;
      height: auto;
    }
  }
}

.screen-saver {
  text-align: center;

  .icon {
    margin-bottom: 1.563vw;
  }

  &__heading {
    @include text-base($size: 1.25vw, $weight: $bold);
    text-transform: uppercase;
  }

  @media screen and (max-width: 1024px) {
    .icon {
      margin-bottom: 30px;
    }

    &__heading {
      font-size: 24px;
    }
  }

  @media (max-width: 767px) {
    .icon {
      margin-bottom: 20px;
    }

    &__heading {
      font-size: 14px;
    }
  }
}

/* Styles for container for grid columns start
Use .row with grid columns (for example .col-xs-6) */
.row {
  @include display-flex;
  @include flex-wrap(wrap);
  margin-right: -15px;
  margin-left: -15px;
}

/* Styles for container for grid columns end */
