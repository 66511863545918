@import '../../../styles/variables';
@import '../../../styles/common';

.cart-item__container_search{
  gap: 20px;
  border-bottom: 0.052vw solid #c6c1c2;
  cursor: default;


.cart-item__characteristics-header{
  padding-left: 25px;
}
  .cart-item__actions{
    flex-grow: 1;
    text-align: end;
    .grid-area-actions{
      justify-content: flex-end;
    }

  }
}

.modal-window-wrapper{
  width: 60% !important;
  max-height: 80%;
}

.category-block{

  .category-block-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px ;
    margin-bottom: 10px ;
  }

  .category-block-value {
    padding-right: 20px;
  }
.custom-select-labelled{
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 5px;
  &:hover {
    color: rgba(146, 138, 142, 0.77)
  }
}
}
