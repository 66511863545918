@import '../../../../../styles/flex';
@import '../../../../../styles/variables';
@import '../../../../../styles/common';

.table-orders {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: 1px;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    width: 3px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    width: 3px;
    background-color: $gray3;
  }
  &__table {
    width: 100%;
    margin-top: 1.563vw;
    background-color: $white;
    @include text-base(
      $size: 0.833vw,
      $family: 'Montserrat',
      $color: $dark-brown
    );
    @media screen and (max-width: 1024px) {
      margin-top: 20px;
      font-size: 14px;
      min-width: 900px;
    }
  }

  &__table-head {
    min-height: 48px;
  }
  &__table-head,
  &__table-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    border-bottom: 0.052vw solid $beige2;

    @media screen and (max-width: 1024px) {
      border-bottom: 1px solid $beige2;
    }

    &_active {
      .table-orders__table-row-item span::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &_details {
      .table-orders__table-row-item {
        flex-basis: 15%;
      }
    }
  }

  &__table-head-title,
  &__table-row-item {
    padding: 0.833vw 0;
    &:first-of-type {
      padding-left: 2.083vw;
    }
    @media screen and (max-width: 1024px) {
      padding: 8px 0;
      &:first-of-type {
        padding-left: 20px;
      }
    }
  }
  &__table-row-item {
    @media screen and (max-width: 1024px) {
      &:first-of-type {
        padding-left: 28px;
      }
    }
  }

  &__table-head-title,
  &__table-row-item {
    font-weight: 400;
    flex-basis: 10%;
    &_order-number {
      flex-basis: 17%;
    }
    &_status {
      flex-basis: 17%;
      @media screen and (max-width: 1024px) {
        flex-basis: 20%;
      }
    }
    &_create-date {
      @media screen and (max-width: 1024px) {
        flex-basis: 11%;
      }
    }
  }

  &__table-row-item-title {
    font-size: 0.833vw;
    font-weight: 400;
    padding: 0.26vw 0;
    @media screen and (max-width: 1024px) {
      font-size: 14px;
      padding: 0;
    }
  }

  &__table-row-item {
    font-weight: $semibold;
    height: 5.729vw;
    width: 10%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-around;
    @media screen and (max-width: 1024px) {
      height: 70px;
    }
    &_order-number {
      cursor: pointer;
      span {
        margin-left: 0.26vw;
        border-bottom: 0.052vw solid $black;
        padding-bottom: 0.104vw;
        position: relative;
        @media screen and (max-width: 1024px) {
          margin-left: 4px;
          border-bottom: 1px solid $black;
          padding-bottom: 2px;
          &::before {
            left: -5px !important;
          }
          &::after {
            right: -14px !important;
          }
        }
        &::before {
          content: '#';
          position: absolute;
          left: -0.26vw;
          transform: translateX(-100%);
        }
        &::after {
          content: '\25BC';
          position: absolute;
          display: block;
          top: 50%;
          transform: translateY(-50%);
          right: -0.833vw;
        }
      }
    }
    &_status {
      text-transform: uppercase;
      color: $white;
      width: 17%;
      span {
        padding: 0.104vw 0.26vw;
        background-color: $green;
        @media screen and (max-width: 1024px) {
          padding: 2px 5px;
        }
      }
    }
    &_total,
    &_stone-total {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      font-size: 1.041vw;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
      span {
        width: 100%;
        color: $yellow;
      }
    }
    &_stone-total {
      width: 15%;
    }
  }
  &__table-row-image {
    display: block;
    width: 3.646vw;
    height: 3.646vw;
    margin-left: 2.083vw;
    margin-top: 0.52vw;
    @media screen and (max-width: 1024px) {
      width: 42px;
      height: 42px;
      margin-left: 2px;
      margin-top: 8px;
    }
  }
  &__table-row-details {
    margin-left: 1.042vw;
    margin-right: 2.084vw;
    width: 30%;
    @media screen and (max-width: 1024px) {
      width: 40%;
      font-size: 12px;
      margin-right: 15px;
    }
  }
  &__details-title {
    font-size: 1.25vw;
    font-weight: $bold;
    margin-bottom: 1.042vw;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    @media screen and (max-width: 1024px) {
      font-size: 14px;
      margin-bottom: 12px;
    }
  }
  &__details-info {
    span {
      height: 1.042vw;
      padding: 0 0.938vw;
      border-right: 0.052vw solid $beige2;
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        border-right: none;
      }
      @media screen and (max-width: 1024px) {
        font-size: 14px;
        border-right: 1px solid $beige2;
        padding: 0 10px;
      }
    }
  }
  &__table-row-actions {
    display: flex;
  }
  &__table-row-action {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.438vw;
    height: 3.438vw;
    border-radius: 50%;
    border: 0.052vw solid $dark-brown;
    margin-left: 1.042vw;
    @media screen and (max-width: 1024px) {
      width: 48px;
      height: 48px;
      border: 1px solid $dark-brown;
      margin-left: 10px;
    }

    img {
      width: 1.042vw;
      height: auto;
      @media screen and (max-width: 1024px) {
        width: 16px;
      }
    }
  }
}
