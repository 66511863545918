@import '../../../../styles/variables';
@import '../../../../styles/flex';
@import '../../../../styles/common';
@import '../../../../styles/icons';

.cart-item {
  border-bottom: 0.052vw solid $beige2;
  cursor: default;

  &__container {
    @include display-flex();
    @include align-items(center);
    @include justify-content(space-between);
    padding-top: 1.302vw;
    padding-bottom: 1.302vw;
    @media screen and (max-width: 1024px) {
      @include flex-wrap(no-wrap);
      padding-top: 14px;
      padding-bottom: 14px;
    }
    @media (max-width: 767px) {
      @include justify-content(flex-start);
      gap: 20px;
      padding-left: $margin-mobile;
      padding-right: $margin-mobile;
      padding-top: 10px;
      padding-bottom: 0px;
      .cart-item__children{
        padding-bottom: 10px;
      }
    }
  }

  &__image {
    width: auto;
    padding-left: 1vw;

    .img-container {
      position: relative;
      top: 12px;
      @media screen and (max-width: 1024px) {
        max-height: 8vw;
        top: 12px;
      }


      img {
        width: auto;
        max-height: 5.469vw;
        @media screen and (max-width: 767px) {
          max-height: 8vw !important;
          top: 12px;
        }
        @media screen and (max-width: 550px) {
          max-height: 10vw !important;
          top: 12px;
        }
      }
    }

    .light-loader__container,
    .icon_no_img {
      position: relative;
      max-width: 40%;
      height: auto;
    }
  }

  &__characteristics {
    width: 45%;
    .cart-item__price-set{
      font-size: 9px;
    }
    @media screen and (max-width: 1024px) {
      width: 40%;

      .diamond-characteristics {
        li {
          font-size: 1.45vw;
        }
      }
    }
    @media screen and (max-width: 767px) {
      flex-grow: 1;
    }



    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 5vh;
      @media screen and (max-width: 1024px) {
        padding-right: 3vh;
      }

      @media (max-width: 767px) {
       flex-basis: 0;
      }

      &-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
          width: 7px;
          margin-left: 10px;
          transform: rotate(0);
          transition: $animation-global;
        }
      }

      .diamond-sku {
        line-height: inherit;
      }

      .cart-item__price {
        display: flex;
        align-items: center;
        height: fit-content;

        .cart-item__price-title {
          @include text-base(
            $size: 0.7vw,
            $family: 'Montserrat',
            $weight: $regular,
            $color: $dark-brown
          );
          margin-right: 5px;
        }

        .diamond-price {
          font-size: 0.7vw;
          font-weight: $semibold;
        }
      }
    }
  }

  &__price-set {
    @include text-base(
      $size: 0.7vw,
      $family: 'Montserrat',
      $weight: $regular,
      $color: $dark-brown
    );
  }

  .flex-wrapper {
    width: 41%;
    @include display-flex;
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(space-between);
    @media screen and (max-width: 1024px) {
      width: 44%;
    }
    @media (max-width: 767px) {
      width: auto;
      align-self: flex-end;
      text-align: start;
      @include justify-content(start);
    }

    .cart-item__price {
      width: 30%;
      @media screen and (max-width: 1024px) {
        width: 44%;
      }

      .diamond-price {
        @media screen and (max-width: 1024px) {
          font-size: 21px;
        }
        @media (max-width: 767px) {
        }
      }

      .changer-quantity {
        @include text-base($size: 0.833vw, $color: $gray2);
        @media screen and (max-width: 1024px) {
          font-size: 12px;
          margin-top: 15px;
          margin-bottom: 15px;
        }

        span {
          padding: 0 0.521vw;
          cursor: pointer;
          color: $dark-brown;
          @media screen and (max-width: 1024px) {
            padding: 0 15px;

            &.changer-quantity__remove {
              padding-left: 0;
            }

            &.changer-quantity__add {
              padding-right: 0;
            }
          }
        }
      }
    }

    .cart-item__net-cost {

      &-title {
        @include text-base(
          $size: 14px,
          $family: 'Montserrat',
          $weight: $light,
          $color: #020202
        );
        margin-bottom: 4px;
      }

      &-price {
        @include text-base(
          $family: 'Roboto Condensed',
          $size: 16px,
          $weight: $bold,
          $color: $dark-brown
        );
      }
    }

    .cart-item__actions {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      @media screen and (max-width: 1024px) {
        margin-top: 10px;
      }

      .btn {
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          svg {
            path {
              fill: #fff;
              transition: $animation-global;
            }
          }
        }

        svg {
          max-width: 30%;

          path {
            fill: $dark-brown;
            transition: $animation-global;
          }
        }
      }
    }
  }

  &__set {
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &_active {
      .cart-item__characteristics-header {
        svg {
          transform: rotate(180deg);
          transition: $animation-global;
        }
      }
    }
  }

  &__children {
    position: relative;
    justify-content: flex-start;
    flex-wrap: nowrap;

    &:before {
      content: '';
      width: 95%;
      position: absolute;
      top: 0;
      left: 0;
      border-top: 0.052vw solid $beige2;
    }

    &-included {
      @include text-base(
        $size: 0.7vw,
        $family: 'Montserrat',
        $weight: $regular,
        $color: #928a8e
      );
      margin-right: 5px;
      @media (max-width: 767px) {
       font-size: 9px;
      }
    }

    &-image {
      padding-left: 4vw;

      img {
        max-height: 4.5vw;
        @media screen and (max-width: 1024px) {
          max-height: 5vw;
        }
      }
    }

    &-characteristics {
      &-header {
        @media screen and (max-width: 1024px) {
          padding-right: 2vh;
        }

        .diamond-sku {
          font-size: 0.7vw;
          letter-spacing: 0.9px;
          text-transform: uppercase;
          line-height: inherit;
          @media (max-width: 767px) {
            font-size: 10px;
          }
        }
      }
    }

    .flex-wrapper {
      width: auto;
    }
  }
}

.cart-item-preview {
  border-bottom: 0.052vw solid $beige2;

  .cart-item__container {
    position: relative;
    margin-bottom: 0.521vw;
    padding: 0;
  }

  .cart-item__image {
    flex: 0 0 4.208vw;
    padding: 5px 10px;

    img {
      width: auto;
      max-height: 70px;
    }
  }

  .cart-item__characteristics {
    width: auto;

    &-header {
      width: 100%;
      margin-bottom: 0.521vw;

      .diamond-sku {
        font-size: 0.757vw;
      }
    }

    li {
      & + li:before {
        content: '';
        display: inline-block;
        width: 0.052vw;
        min-width: 1px;
        height: 1.042vw;
        position: relative;
        top: 0.156vw;
        right: 0;
        margin-left: 5px;
        margin-right: 5px;
        background-color: $gray3;
      }

      span {
        color: $gray2;
        font-size: 0.649vw;
        line-height: 15px;
        white-space: nowrap;
        max-width: 2.604vw;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .cart-item__children-characteristics {
    &-header {
      margin-bottom: 0.521vw;
    }
  }

  .cart-item__children-image {
    display: flex;
    justify-content: flex-end;

    img {
      max-height: 50px;
    }
  }

  .flex-wrapper {
    width: auto;
    flex-direction: column-reverse;
    align-items: flex-end;

    .cart-item__price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 5px;

      .diamond-price {
        font-size: 0.865vw;
      }
    }
  }
}

.cart-item-checkout {
  .cart-item__children {
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }

  .cart-item__image {
    width: auto;
    padding-left: 0;

    img {
      max-height: 4.5vw;
    }

    &.cart-item__children-image {
      width: 14%;
      padding-left: 1.3vw;
    }
  }

  .cart-item__characteristics {
    width: 65%;

    &-header {
      flex-direction: column;
      align-items: flex-start;
      padding-right: 2vw;

      &-title {
        margin-bottom: 5px;
      }
    }

    > .diamond-characteristics {
      li {
        &:before {
          content: '';
          margin-left: 0.4vw;
          margin-right: 0.4vw;
        }

        &:first-of-type {
          &:before {
            content: '';
            display: none;
          }
        }
      }
    }
  }

  .cart-item__children-characteristics {
    width: 80%;
    &-header {
      .diamond-sku {
        margin-bottom: 5px;
      }
    }
  }

  .flex-wrapper {
    width: auto;
    justify-content: flex-end;

    .cart-item__price {
      width: auto;
      text-align: right;
    }

    .cart-item__actions {
      display: none;
    }
  }
}
