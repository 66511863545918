@mixin display-flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-size($grow, $shrink, $basis) {
  -webkit-box-flex: $grow $shrink $basis;
  -moz-box-flex: $grow $shrink $basis;
  -webkit-flex: $grow $shrink $basis;
  -ms-flex: $grow $shrink $basis;
  flex: $grow $shrink $basis;
}

@mixin flex-grow($value) {
  -webkit-box-flex: $value;
  -webkit-flex-grow: $value;
  -moz-box-flex: $value;
  -ms-flex-positive: $value;
  flex-grow: $value;
}

@mixin align-items($align) {
  -webkit-box-align: $align;
  -webkit-align-items: $align;
  -moz-box-align: $align;
  -ms-flex-align: $align;
  align-items: $align;
}

@mixin justify-content($align) {
  -webkit-box-pack: $align;
  -webkit-justify-content: $align;
  -moz-box-pack: $align;
  -ms-flex-pack: $align;
  justify-content: $align;
}

@mixin align-content($align) {
  -webkit-align-content: $align;
  -ms-flex-line-pack: $align;
  align-content: $align;
}

@mixin flex-wrap($value) {
  -webkit-flex-wrap: $value;
  -ms-flex-wrap: $value;
  flex-wrap: $value;
}

@mixin flex-direction($direction) {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: $direction;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin order($order) {
  -webkit-box-ordinal-group: $order;
  -webkit-order: $order;
  -moz-box-ordinal-group: $order;
  -ms-flex-order: $order;
  order: $order;
}

@mixin align-self($value) {
  -webkit-align-self: $value;
  -ms-flex-item-align: $value;
  -ms-grid-row-align: $value;
  align-self: $value;
}

@mixin display-inline-flex() {
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
