@import '../../../styles/_variables';

@media (min-width: 768px) {
  .parent {
    height: 40%;
    width: 60%;
    white-space: nowrap;
    text-align: center;
    font-size: 0;

    &:before {
      height: 100%;
      display: inline-block;
      vertical-align: middle;
      content: '';
    }
  }
}

@media (max-width: 767px) {
  .parent {
    width: inherit;
    height: 80vh;
    white-space: nowrap;
    text-align: center;
    font-size: 0;

    &:before {
      height: 100%;
      display: inline-block;
      vertical-align: middle;
      content: '';
    }
  }
}

.parent-loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 15px 0;

  svg {
    transform: scale(1);
    animation: scale 3s ease-out infinite;
    transform-origin: top center;
  }

  div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: $beige3;
    border-radius: 50%;
    animation: loader 1.2s linear infinite;

    &:nth-child(1) {
      animation-delay: 0s;
      top: 37px;
      left: 66px;
    }

    &:nth-child(2) {
      animation-delay: -0.1s;
      top: 22px;
      left: 62px;
    }

    &:nth-child(3) {
      animation-delay: -0.2s;
      top: 11px;
      left: 52px;
    }

    &:nth-child(4) {
      animation-delay: -0.3s;
      top: 7px;
      left: 37px;
    }

    &:nth-child(5) {
      animation-delay: -0.4s;
      top: 11px;
      left: 22px;
    }

    &:nth-child(6) {
      animation-delay: -0.5s;
      top: 22px;
      left: 11px;
    }

    &:nth-child(7) {
      animation-delay: -0.6s;
      top: 37px;
      left: 7px;
    }

    &:nth-child(8) {
      animation-delay: -0.7s;
      top: 52px;
      left: 11px;
    }

    &:nth-child(9) {
      animation-delay: -0.8s;
      top: 62px;
      left: 22px;
    }

    &:nth-child(10) {
      animation-delay: -0.9s;
      top: 66px;
      left: 37px;
    }

    &:nth-child(11) {
      animation-delay: -1s;
      top: 62px;
      left: 52px;
    }

    &:nth-child(12) {
      animation-delay: -1.1s;
      top: 52px;
      left: 62px;
    }
  }

  @keyframes loader {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
}

#first {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash1000 2s ease-out infinite;
}
#second {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash1000 2s ease-out infinite;
}
#third {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash1000 2s ease-out infinite;
}
#fourth {
  stroke-dasharray: 75;
  stroke-dashoffset: 75;
  animation: dash75 2s ease-out infinite;
}
#fifth {
  stroke-dasharray: 110;
  stroke-dashoffset: 110;
  animation: dash110 2s ease-out infinite;
}
#sixth {
  stroke-dasharray: 110;
  stroke-dashoffset: 110;
  animation: dash110 2s ease-out infinite;
}
#seventh {
  stroke-dasharray: 72;
  stroke-dashoffset: 72;
  animation: dash72 2s ease-out infinite;
}
#eighth {
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  animation: dash300 2s ease-out infinite;
}
#ninth {
  stroke-dasharray: 101;
  stroke-dashoffset: 101;
  animation: dash101 2s ease-out infinite;
}
#tenth {
  stroke-dasharray: 157;
  stroke-dashoffset: 157;
  animation: dash157 2s ease-out infinite;
}
#eleventh {
  stroke-dasharray: 106;
  stroke-dashoffset: 106;
  animation: dash106 2s ease-out infinite;
}
#twelfth {
  stroke-dasharray: 231;
  stroke-dashoffset: 231;
  animation: dash231 2s ease-out infinite;
}
#thirteenth {
  stroke-dasharray: 145;
  stroke-dashoffset: 145;
  animation: dash145 2s ease-out infinite;
}
@keyframes dash1000 {
  0% {
    stroke-dashoffset: 1000;
  }
  40% {
    stroke-dashoffset: 0;
  }
  50%,
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash75 {
  0% {
    stroke-dashoffset: 75;
  }
  40% {
    stroke-dashoffset: 0;
  }
  50%,
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash110 {
  0% {
    stroke-dashoffset: 110;
  }
  40% {
    stroke-dashoffset: 0;
  }
  50%,
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash72 {
  0% {
    stroke-dashoffset: 72;
  }
  40% {
    stroke-dashoffset: 0;
  }
  50%,
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash300 {
  0% {
    stroke-dashoffset: 300;
  }
  40% {
    stroke-dashoffset: 0;
  }
  50%,
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash101 {
  0% {
    stroke-dashoffset: 101;
  }
  40% {
    stroke-dashoffset: 0;
  }
  50%,
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash157 {
  0% {
    stroke-dashoffset: 157;
  }
  40% {
    stroke-dashoffset: 0;
  }
  50%,
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash106 {
  0% {
    stroke-dashoffset: 106;
  }
  40% {
    stroke-dashoffset: 0;
  }
  50%,
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash231 {
  0% {
    stroke-dashoffset: 231;
  }
  40% {
    stroke-dashoffset: 0;
  }
  50%,
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash145 {
  0% {
    stroke-dashoffset: 145;
  }
  40% {
    stroke-dashoffset: 0;
  }
  50%,
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
  }
}
