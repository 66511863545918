@import '../../styles/variables';
@import '../../styles/common';
@import '../../styles/animations';

$title-height: 7vw;

.contacts-page {
  &.grid {
    // Start css-grid building for Desktop
    display: grid;
    min-height: 100%;
    grid-template-areas: '. header header .' '. title title .' '. info-bar form form';
    grid-template-columns: $margin-desktop 1fr 1fr $margin-desktop;
    grid-template-rows: $header-height-desktop $title-height;

    .grid-area {
      &-header {
        grid-area: header;
        padding-left: $margin-desktop;
        padding-right: $margin-desktop;
      }

      &-page-title {
        grid-area: title;
      }

      &-info-bar {
        grid-area: info-bar;
      }

      &-form {
        grid-area: form;
        background-color: $bg-beige;
      }

      // End css-grid building for Desktop
    }

    .grid-area-page-title {
      @include display-flex();
      @include align-items(center);
      @include justify-content(center);
    }

    .grid-area-info-bar {
      padding-top: 1.563vw;
      padding-left: 7.188vw;
      padding-right: 7.2vw;

      .info-block {
        @include display-flex;
        @include align-items(center);
        margin-bottom: 2.083vw;

        &:last-child {
          margin-bottom: 0;
        }

        &__text {
          @include text-base($size: 0.938vw, $weight: $semibold);
        }

        &__text-block {
          @include text-base($size: 0.833vw, $weight: $regular);
          line-height: 1.75;
        }

        svg {
          height: 18px;
          width: 18px;

          path {
            fill: #b1a07e;
          }
        }
      }
    }

    .grid-area-form {
      padding-right: $margin-desktop;
      padding-top: 1vw;
      padding-left: 4.688vw;

      .form-block {
        .form {
          width: 26vw;

          .btn {
            margin-bottom: 1.563vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .contacts-page {
    &.grid {
      // Start css-grid building for Laptop
      grid-template-columns: $margin-laptop 1fr 1fr $margin-laptop;
      grid-template-rows: $header-height-laptop $title-height;
      // End css-grid building for Laptop

      .grid-area-header {
        padding-left: $margin-laptop;
        padding-right: $margin-laptop;
      }

      .grid-area-info-bar {
        padding-top: 30px;
        padding-left: 0;
        padding-right: 30px;

        .info-block {
          margin-bottom: 20px;

          &__text {
            font-size: 16px;
          }

          &__text-block {
            font-size: 14px;
          }
        }
      }

      .grid-area-form {
        padding-right: $margin-laptop;
        padding-top: 30px;
        padding-left: 30px;

        .form-block {
          .form {
            width: 100%;

            .btn {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .contacts-page {
    &.grid {
      // Start css-grid building for Mobile
      grid-template-areas: '. header .' '. title .' 'form form form' 'info-bar info-bar info-bar';
      grid-template-columns: $margin-mobile auto $margin-mobile;
      grid-template-rows: $header-height-mobile min-content;
      background-color: $bg-mobile-beige;
      // End css-grid building for Mobile

      .grid-area-header {
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
      }

      .grid-area-page-title {
        @include align-items(flex-start);
        @include justify-content(flex-start);
        margin-bottom: $margin-mobile;
      }

      .grid-area-info-bar {
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
        background-color: $white;

        .info-block {
          margin-bottom: $margin-mobile;
        }
      }

      .grid-area-form {
        padding-right: $margin-mobile;
        padding-left: $margin-mobile;
        background-color: $white;

        .form-block {
          .form {
            .attention-message {
              margin-top: $margin-mobile;
              margin-bottom: $margin-mobile;
            }

            .btn {
              margin-bottom: $margin-mobile;
            }
          }
        }
      }
    }
  }
}
