.img-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  &_no-img {
    opacity: 0;
  }

  .icon_logo_mobile {
    position: absolute;
    width: 60%;
    height: auto;
    left: 15%;

    path {
      fill: #4d404854;
    }
  }

  .light-loader__container {
    z-index: 0;
    left: -5%;

    svg {
      width: 90%;
      height: 75%;
    }
  }
}
