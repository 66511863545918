.scrollable-tabs {
  .scrollable-tabs__arrow {
    position: absolute;
    display: block;
    text-decoration: none;
    cursor: pointer;

    &_left {
      left: 0;
      .scrollable-tabs__arrow-icon {
        transform: rotateY(180deg);
      }
    }

    &_right {
      right: 0;
    }

    &:hover {
      svg {
        path {
          fill: #ab8971;
        }
      }
    }
  }

  .slider-wrap {
    margin: 0 25px;
    overflow: hidden;

    .slider {
      white-space: nowrap;
      display: inline-block;
      list-style: none;
      transition: transform 300ms ease-in;

      .slider-item {
        display: inline-block;
        padding: 0 10px;
        list-style: none;
      }
    }
  }
}
