@import '../../../../styles/flex';
@import '../../../../styles/variables';
@import '../../../../styles/common';

.content-main {
  &_block {
    //carousel-block
    &__best-sellers {
      $level2-max-height: 12.878vw;
      $level2-height: 60%;
      $level2-width: 10.281vw;

      $level1-max-height: 15.584vw;
      $level1-height: 80%;
      $level1-width: 12.445vw;

      $level0-max-height: 18.939vw;
      $level0-height: 100%;
      $level0-width: 15.151vw;

      $level-3-left: 80vw;
      $level-2-left: 75vw;
      $level-1-left: 60.138vw;
      $level0-left: 42.7vw;
      $level1-left: 28vw;
      $level2-left: 15.5vw;
      $level3-left: 10.5vw;

      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 3.2vw 0;

      @media (max-width: 1024px) {
        height: 40%;
        position: initial;
      }

      @media (max-width: 767px) {
        height: auto;
        width: 300px;
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-around;

        .carousel-item {
          position: relative;
          margin-right: 0;
          width: 177px;
          height: 220px;
          background-color: white;
        }
      }

      #carousel {
        height: 100%;
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        padding: 0 9.416vw;

        @media (max-width: 1024px) {
          margin-bottom: 0;
        }
      }

      .arrow {
        width: 30px;
        height: 30px;
        text-align: center;
        font-size: 25px;
        cursor: pointer;
        color: #228291;
        line-height: 30px;
        margin-top: 85px;
        z-index: 1000;

        @media screen and (max-width: 1024px) {
          margin-top: 50px;
        }

        .icon-arrow_right::before {
          right: 0;
          left: auto;
        }

        .icon-arrow_left::before {
          right: auto;
          left: 0;
        }
      }

      .item {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 40px;
        position: absolute;
        max-height: 100%;
        //transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s,
        //  background-color 1s;
        background-color: white;
        @media screen and (max-width: 767px) {
          height: inherit;
        }

        .img-container {
          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }

          .light-loader__container {
            @media screen and (max-width: 767px) {
              position: relative;
            }
          }

          .icon_no_img {
            width: 65%;
            height: 80%;
            position: absolute;
            @media screen and (max-width: 767px) {
              position: relative;
            }
          }
        }
      }

      .level-3 {
        max-height: $level2-max-height;
        height: $level2-height;
        width: $level2-width;
        left: $level-2-left;
        margin-top: 25px;
        opacity: 0;
      }

      .level-2 {
        max-height: $level2-max-height;
        height: $level2-height;
        width: $level2-width;
        left: $level-2-left;
        margin-top: 25px;
      }

      .level-1 {
        max-height: $level1-max-height;
        height: $level1-height;
        width: $level1-width;
        left: $level-1-left;
        margin-top: 10px;
      }

      .level0 {
        max-height: $level0-max-height;
        height: $level0-height;
        width: $level0-width;
        left: $level0-left;
      }

      .level1 {
        max-height: $level1-max-height;
        height: $level1-height;
        width: $level1-width;
        margin-top: 10px;
        left: $level1-left;
      }

      .level2 {
        max-height: $level2-max-height;
        height: $level2-height;
        width: $level2-width;
        margin-top: 25px;
        left: $level2-left;
      }

      .level3 {
        max-height: $level2-max-height;
        height: $level2-height;
        width: $level2-width;
        margin-top: 25px;
        left: $level2-left;
        opacity: 0;
      }

      .noselect {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }

    //carousel-block end
  }

  .main-slide {
    &:last-child {
      position: relative;
      min-height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 0 !important;

      @media screen and (max-width: 767px) {
        height: 100vh !important;
      }

      .content-main_block {
        min-height: auto;

        &__title {
          @media screen and (max-width: 767px) {
            margin-top: 64px;
          }
        }
      }

      .footer-block-mobile {
        @media screen and (max-width: 768px) {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .footer-bottom {
            margin-top: -50px;
          }
        }
      }
    }
  }
}

#bestSellers {
  height: 1100px;

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    justify-content: center;
    height: 700px;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

#bestSellersMobile {
  display: none;

  @media (max-width: 767px) {
    height: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
