@import '_variables';
@import '_flex';

@mixin icon(
  $name,
  $width,
  $height,
  $color: $dark-brown,
  $x: center,
  $y: center
) {
  display: inline-block;
  width: $width;
  height: $height;
  background: $color;
  transition: $animation-global;

  // Mask to replace svg-icons and then change their color
  mask: url('../../src/img/svgicons/#{$name}.svg') #{$x} #{$y}; /*./src/img/svgicons/#{$name}.svg*/
  -webkit-mask: url('../../src/img/svgicons/#{$name}.svg') #{$x} #{$y};
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-size: contain;
}

// Add icon-hover mixin if want the icon to have hover effect
@mixin icon-hover($color: $light-brown) {
  &:hover {
    cursor: pointer;
    background: $color;
  }
}

// Add icon-active mixin if want the icon to have active effect
@mixin icon-active($name, $color) {
  &:active,
  &.active {
    cursor: pointer;
    mask: url('../../src/img/svgicons/#{$name}.svg');
    background: $color;
  }
}

// Add adaptability to icon if want the icon to have adaptability
// Do not use vw for svg icons
@mixin icon-adaptability(
  $width,
  $height,
  $width-sm,
  $height-sm,
  $width-md: '',
  $height-md: ''
) {
  @media screen and (min-width: 2900px) {
    width: $width;
    height: $height;
  }
  @media screen and (max-width: 1500px) {
    width: #{$width-md};
    height: #{$height-md};
  }
  @media screen and (max-width: 1024px) {
    width: $width-sm;
    height: $height-sm;
  }
}

.icon {
  &_mr-xs {
    margin-right: 0.521vw;
  }

  &_mr-sm {
    margin-right: 1.042vw;
  }

  &_ml-sm {
    margin-left: 1.042vw;
  }

  @media screen and (max-width: 1024px) {
    &_mr-xs {
      margin-right: 10px;
    }

    &_mr-sm {
      margin-right: 20px;
    }

    &_ml-sm {
      margin-left: 20px;
    }
  }
}

// .icon_heart-light {
//   @include icon('heart-light', $width: 26px, $height: 24px);
//   @include icon-adaptability(52px, 48px, 26px, 24px, 26px, 24px);
// }

// .icon_heart-regular {
//   @include icon('heart-regular', $width: 1.198vw, $height: 1.198vw);
//   @include icon-hover;
//   @include icon-active('heart-solid', $color: $dark-brown);
//   min-width: 16px;
//   min-height: 16px;
// }

// .icon_angle-up-regular {
//   @include icon('angle-up-regular', $width: 10px, $height: 16px);
//   @include icon-adaptability(10px, 16px, 10px, 16px);
// }

// .icon_angle-down-regular {
//   @include icon('angle-down-regular', $width: 10px, $height: 16px);
//   @include icon-adaptability(10px, 16px, 10px, 16px);
// }

@mixin area-around-icon(
  $diameter: 1.563vw,
  $min-diameter: 20px,
  $color: $white
) {
  width: $diameter;
  height: $diameter;
  min-width: $min-diameter;
  min-height: $min-diameter;
  @include display-inline-flex;
  @include align-items(center);
  @include justify-content(center);
  border-radius: $border-radius-circle;
  background-color: $white;
  transition: $animation-global;
}

.area-around-icon_small {
  @include area-around-icon;
}

.area-around-icon_middle {
  @include area-around-icon($diameter: 1.563vw, $min-diameter: 30px);
}

.area-around-icon_large {
  @include area-around-icon($diameter: 2.292vw, $min-diameter: 44px);
}

.area-around-icon_big {
  @include area-around-icon($diameter: 3.438vw, $min-diameter: 50px);
}

.area-around-icon_bordered {
  border: 0.052vw solid $brown-black;
  @media screen and (max-width: 1024px) {
    border-width: 1px;
  }
}

.area-around-icon_light-brown {
  background-color: $beige;
  @media (max-width: 767px) {
    background-color: $bg-mobile-beige;
  }
}

// Mixin that change only the color of svg-icons
@mixin area-actions($name) {
  .area-around-icon {
    cursor: pointer;

    &:hover {
      background-color: $dark-brown;

      .icon_#{$name} {
        background-color: $white;
      }

      @media (pointer: coarse) {
        background-color: $white;

        .icon_#{$name} {
          background-color: $dark-brown;
        }
      }
    }

    &:active {
      background-color: $white;

      .icon_#{$name} {
        background-color: $dark-brown;
      }
    }
  }
}

// Mixin that change the icons from regular to solid while hover or active.
// And while hover already .active icon
@mixin area-actions-icons-regular($name) {
  .area-around-icon {
    cursor: pointer;

    &:hover {
      background-color: $dark-brown;

      .icon_#{$name}-regular {
        background-color: $white;
      }

      @media (pointer: coarse) {
        background-color: $white;

        .icon_#{$name}-regular {
          background-color: $dark-brown;
        }
      }
    }

    //class .active means the diamond added to shopping bag or favorite
    &:active,
    &.active {
      background-color: $white;

      .icon_#{$name}-regular {
        mask: url('../../src/img/svgicons/#{$name}-solid.svg');
        background-color: $dark-brown;
      }
    }

    &.active {
      &:hover {
        background-color: $dark-brown;

        .icon_#{$name}-regular {
          background-color: $white;
        }

        @media (pointer: coarse) {
          background-color: $white;

          .icon_#{$name}-regular {
            background-color: $dark-brown;
          }
        }
      }

      &:active {
        background-color: $white;

        .icon_#{$name}-regular {
          mask: url('../../src/img/svgicons/#{$name}-regular.svg');
          background-color: $dark-brown;
        }
      }
    }
  }
}

// Mixin that change the icons from light to solid while hover or active.
// And while hover already .active icon
@mixin area-actions-icons-light($name) {
  .area-around-icon {
    cursor: pointer;

    &:hover {
      background-color: $dark-brown;

      .icon_#{$name}-light {
        background-color: $white;
      }

      @media (pointer: coarse) {
        background-color: $white;

        .icon_#{$name}-light {
          background-color: $dark-brown;
        }
      }
    }

    //class .active means the diamond already added to shopping bag or favorite
    &:active,
    &.active {
      background-color: $white;

      .icon_#{$name}-light {
        mask: url('../../src/img/svgicons/#{$name}-solid.svg');
        background-color: $dark-brown;
      }
    }

    &.active {
      &:hover {
        background-color: $dark-brown;

        .icon_#{$name}-light {
          background-color: $white;
        }

        @media (pointer: coarse) {
          background-color: $white;

          .icon_#{$name}-light {
            background-color: $dark-brown;
          }
        }
      }

      &:active {
        background-color: $white;

        .icon_#{$name}-light {
          mask: url('../../src/img/svgicons/#{$name}-light.svg');
          background-color: $dark-brown;
        }
      }
    }
  }
}

// .icon_noshape {
//   display: inline-block;
//   width: 1.198vw;
//   height: 1.198vw;
//   min-width: 20px;
//   min-height: 20px;
//   background: url('../../src/img/default_images/venori_no_image.png') 0 0 /
//     contain no-repeat;
// }

// .icon_big {
//   width: 2.552vw;
//   height: 2.552vw;
//   min-width: 40px;
//   min-height: 40px;
// }

.icon_arrow-circle-up {
  @include icon('arrow-circle-up-light', $width: 35px, $height: 35px);
  @include icon-hover;
  @include icon-adaptability(60px, 60px, 25px, 25px, 35px, 35px);
}

.icon_arrow-up-light {
  @include icon('arrow-up-light', $width: 35px, $height: 35px);
  @include icon-hover;
  @include icon-adaptability(60px, 60px, 25px, 25px, 35px, 35px);
}

.icon_success {
  content: '';
  position: relative;
  display: block;
  width: 0.313vw;
  height: 0.521vw;
  min-width: 6px;
  min-height: 10px;
  border: solid $gray2;
  border-width: 0 0.104vw 0.104vw 0;
  transform: rotate(45deg);
  @media screen and (max-width: 1024px) {
    border-width: 0 2px 2px 0;
  }
}

.icon-arrow {
  display: inline-block;
  width: 2.083vw;
  height: 0;
  border-bottom: 1px solid $dark-brown; // Stay 1px (not 0.052vw) to prevent border disappearing on small screens
  position: relative;


  &:before {
    position: absolute;
    content: '';
    top: -0.208vw;
    display: inline-block;
    width: 0.417vw;
    height: 0.417vw;
    border-right: 0.052vw solid $dark-brown;
    border-top: 0.052vw solid $dark-brown;
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &_left {
    &:before {
      left: 0;
      transform: rotate(225deg);
    }
  }

  &_right {
    &:before {
      left: 1.563vw;
      transform: rotate(45deg);
    }
  }

  @media screen and (max-width: 1024px) {
    width: 40px;
    &:before {
      top: -4px;
      width: 8px;
      height: 8px;
      border-right: 1px solid $dark-brown;
      border-top: 1px solid $dark-brown;
    }

    &_right {
      &:before {
        left: 30px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 20px;
    &:before {
      top: -2px;
      width: 4px;
      height: 4px;
    }

    &_right {
      &:before {
        left: 15px;
      }
    }
  }
}

@mixin circle-around-icon($diameter: 2.917vw, $min-diameter: 40px) {
  width: $diameter;
  height: $diameter;
  min-width: $min-diameter;
  min-height: $min-diameter;
  @include display-inline-flex;
  @include align-items(center);
  @include justify-content(center);
  border-radius: $border-radius-circle;
  border: 0.052vw solid $yellow;
  background-color: $white;
  margin-right: 1.302vw;
  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 1024px) {
    border-width: 1px;
    margin-right: 20px;
  }
}

.circle-around-icon {
  @include circle-around-icon;
}

.icon_info-circle-notifier {
  @include icon(
    'info-circle-notifier',
    $width: 30px,
    $height: 35px,
    $color: $gray2
  );
}

.icon_warning-notifier {
  @include icon(
    'warning-notifier',
    $width: 30px,
    $height: 35px,
    $color: $gray2
  );
}

.icon_warning-circle-notifier {
  @include icon(
    'warning-circle-notifier',
    $width: 30px,
    $height: 35px,
    $color: $gray2
  );
}

.icon_question-notifier {
  @include icon(
    'question-notifier',
    $width: 30px,
    $height: 35px,
    $color: $gray2
  );
}

.icon_check-notifier {
  @include icon('check-notifier', $width: 30px, $height: 35px, $color: $gray2);
}
