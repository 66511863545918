@import '../../../../styles/variables';
@import '../../../../styles/common';

.add-set-to-cart-popup {
  width: 980px !important;
  max-width: calc(100% - 50px);
  background-color: $beige !important;
}

.add-set-to-cart-popup__set-info {
  display: flex;
  justify-content: flex-end;

  span {
    padding: 10px 40px;
    background: $beige3;
    line-height: 15px;
    @include text-base($size: 12px, $weight: 400, $color: $dark-brown);
  }
}

.add-set-to-cart-popup__list {
  width: 100%;
  @include text-base($size: 12px, $weight: 400, $color: $dark-brown);
  background: #fff;
  box-sizing: border-box;

  .text {
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    line-height: 9px;
    color: $yellow;
  }
}

.add-set-to-cart-popup__table {
  width: 100%;

  * {
    box-sizing: border-box;
  }

  tr:first-child {
    td {
      border-bottom: 1px solid $beige2;
    }
  }

  td {
    padding: 25px 0;
    vertical-align: middle;

    > div {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 86px;
      border-right: 1px solid $beige2;
    }

    &:first-child {
      width: auto;
      padding-right: 0;
      padding-left: 60px;

      > div {
        padding-right: 10px;
      }
    }

    &:nth-child(2) {
      > div {
        padding-left: 30px;
      }
    }

    &:last-child {
      padding-right: 60px;

      > div {
        padding-left: 10px;
        border: none;
      }
    }
  }

  .add-set-to-cart-popup__price-block {
    width: 100%;
  }
}

.add-set-to-cart-popup__title {
  margin-bottom: 4px;
  @include text-base($size: 24px, $weight: 700, $color: $dark-brown);
  line-height: 29px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.add-set-to-cart-popup__item {
  box-sizing: border-box;
}

.add-set-to-cart-popup__image {
  flex-shrink: 0;
  width: 80px;
  svg {
    width: 80px;
    height: 80px;
  }
}

.add-set-to-cart-popup__info {
  width: 100%;
}

.add-set-to-cart-popup__info-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .icon {
    margin-right: 5px;
  }
}

.add-set-to-cart-popup__price-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  justify-content: center;
  // width: 100%;
}

.add-set-to-cart-popup__price-block-info {
  color: $gray2;
}

.add-set-to-cart-popup__price-block-price {
  margin: 6px 0 5px;
  @include text-base($size: 24px, $weight: 700, $color: $dark-brown);
  line-height: 29px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.add-set-to-cart-popup__total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 40px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $gray2;
  text-align: right;

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }

  span {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.2px;
    text-transform: uppercase;
    color: $brown-black;
    @include text-base($size: 24px, $weight: 700, $color: $dark-brown);
    margin-left: 30px;
  }
}

.add-set-to-cart-popup__btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-set-to-cart-popup__button {
  width: 210px;
  max-width: calc(50% - 20px);
  margin: 0 10px;
}
