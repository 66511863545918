@import '../../styles/variables';
@import '../../styles/common';

$footer-desktop: 6vw;
$footer-tablet: 130px;

.bg-image_behind {
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    display: inline-block;
    z-index: -1;
    background: url('../../img/shapes/princess.png');
    background-repeat: no-repeat;
    background-position: center 11vw;
    background-size: 13.021vw;
    filter: blur(0.208vw);
  }
}

.advantages-page {
  width: 100%;
  height: 100%;
  // The decision for adaptive height on some tablets with hidden/shown browser toolbars
  // https://developers.google.com/web/updates/2016/12/url-bar-resizing
  position: fixed;

  &.grid {
    // Start css-grid building for Desktop
    display: grid;
    grid-template-areas: '. header .' '. advantages .';
    grid-template-columns: $margin-desktop 1fr $margin-desktop;
    grid-template-rows: $header-height-desktop;
    background-image: linear-gradient(
      to right,
      rgba(248, 245, 242, 0.7) 0%,
      rgba(248, 245, 242, 0.7) 50%,
      rgba(251, 252, 248, 0.7) 50%,
      rgba(251, 252, 248, 0.7) 82%,
      $beige6 82%,
      $beige6 100%
    );

    .grid-area {
      &-header {
        grid-area: header;
        padding-left: $margin-desktop;
        padding-right: $margin-desktop;
      }

      &-breadcrumbs {
        grid-area: breadcrumbs;
      }

      &-advantages {
        grid-area: advantages;
      }
    }

    .bg-image_in-front {
      z-index: 1;
      position: fixed;
      top: 15vw;
      right: 0;
      transform-style: preserve-3d;

      &:before {
        content: '';
        width: 40vw;
        height: 40vw;
        position: absolute;
        display: inline-block;
        background: url('../../img/shapes/radiant.png');
        background-repeat: no-repeat;
        background-size: 30vw;
        // Do not make positive value for transform: translateZ(-1px); because bg-image will hide Mobile menu on Safari
        transform: translateZ(-1px);
      }

      &:after {
        content: '';
        width: 40vw;
        height: 40vw;
        position: relative;
        display: inline-block;
        background: radial-gradient(
          closest-side,
          rgb(255, 255, 255) 70%,
          rgba(255, 255, 255, 0)
        );
        background-repeat: no-repeat;
        top: -8vw;
        left: -5vw;
        // Do not make positive value for transform: translateZ(-1px); because bg-image will hide Mobile menu on Safari
        transform: translateZ(-2px);
      }
    }

    // End css-grid building for Desktop

    .grid-area-advantages {
      margin-top: 5.208vw;
      overflow-y: auto;
      padding-bottom: $footer-desktop;

      .advantages-header {
        direction: ltr;
        margin-bottom: 2.604vw;
        box-sizing: border-box;

        svg {
          margin-bottom: 1.042vw;
        }

        &__title {
          @include text-base(
            $size: 2.76vw,
            $family: 'Roboto Condensed',
            $weight: $bold
          );
          text-transform: uppercase;
          letter-spacing: 0.2vw;
          line-height: 1;
        }
      }

      .advantages-list {
        direction: ltr;
        list-style: none;
        position: relative;
        z-index: 2;

        &-item {
          margin-bottom: 1.042vw;

          &__title {
            @include text-base($size: 0.729vw, $weight: bold);
            width: fit-content;
            text-transform: uppercase;
            line-height: 1;
            padding-bottom: 0.521vw;
            border-bottom: 2px solid inherit;

            &:hover {
              cursor: pointer;
              color: $gray2;
              padding-bottom: 0.417vw;
              border-bottom: 2px solid $yellow;
            }

            @media (pointer: coarse) {
              &:hover {
                color: $dark-brown;
              }
            }

            &:active {
              color: $gray2;
            }
          }

          &__info {
            @include text-base(
              $family: 'Montserrat',
              $size: 0.625vw,
              $weight: $regular,
              $color: $dark-brown
            );
            margin-top: 1.042vw;
            padding: 0.782vw;
            line-height: 0.989vw;
            width: 35.417vw;
            background-color: $white;
            border-radius: 0.782vw;
          }
        }
      }

      .accordion {
        display: none;
      }

      &.block-scrollbar {
        // Deduct width of block-scrollbar
        margin-left: calc(-2.604vw - 3px);
        padding-left: 2.604vw;
        direction: rtl;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .bg-image_behind {
    display: none;
  }

  .advantages-page {
    &.grid {
      // Start css-grid building for Tablet
      grid-template-areas: 'header header header' 'advantages advantages .';
      grid-template-columns: $margin-laptop auto $margin-laptop;
      grid-template-rows: $header-height-laptop;
      background-image: linear-gradient(
        to right,
        rgba(251, 252, 248, 0.7) 0%,
        rgba(251, 252, 248, 0.7) 25%,
        rgba(248, 245, 242, 0.7) 25%,
        rgba(248, 245, 242, 0.7) 100%
      );

      .grid-area-header {
        padding-left: $margin-laptop;
        padding-right: $margin-laptop;
      }

      .bg-image_in-front {
        top: 30%;
        right: auto;
        left: -15vh;

        // Changed values to vh for better adaptability on tablets
        &:before {
          width: 50vh;
          height: 50vh;
          background-size: 45vh;
        }

        &:after {
          width: 55vh;
          height: 55vh;
          top: -11vh;
          left: -7vh;
        }
      }

      .grid-area-advantages {
        margin-top: 50px;
        // Important added to rewrite padding-left of .block-scrollbar on left side
        padding-left: calc(#{$margin-laptop} + 35vw) !important;
        padding-bottom: $footer-tablet;

        .advantages-header {
          margin-bottom: 40px;

          svg {
            margin-bottom: 20px;
          }

          &__title {
            font-size: 24px;
          }
        }

        .advantages-list {
          &-item {
            margin-bottom: 26px;

            &__title {
              font-size: 14px;
            }

            &__info {
              margin-top: 20px;
              padding: 15px;
              font-size: 12px;
              line-height: 19px;
              border-radius: 15px;
            }
          }
        }

        &.block-scrollbar {
          margin-left: 0;
          margin-right: -2.604vw;
          padding-right: 2.604vw;
          direction: ltr;
        }
      }

      // End css-grid building for Tablet
    }
  }
}

@media screen and (max-width: 767px) {
  .advantages-page {
    position: initial;

    &.grid {
      // Start css-grid building for Mobile
      grid-template-areas: 'header header header' 'advantages advantages advantages';
      grid-template-columns: $margin-mobile auto $margin-mobile;
      grid-template-rows: $header-height-mobile;
      background: $bg-mobile-beige;
      // End css-grid building for Mobile

      &:before {
        display: none;
      }

      .grid-area-header {
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
      }

      .bg-image_in-front {
        display: none;
      }

      .grid-area-advantages {
        height: initial;
        overflow-y: initial;
        background-color: $white;
        padding-left: 0 !important;
        margin-top: 0;

        .advantages-header {
          width: 100%;
          background: $bg-mobile-beige;
          padding: 0 $margin-mobile $margin-mobile;
          margin-bottom: 0;

          svg {
            display: none;
          }

          &__title {
            font-size: 38px;
          }
        }

        .advantages-list {
          display: none;
        }

        .accordion {
          display: block;
          padding-left: $margin-mobile;
          padding-right: $margin-mobile;
          padding-top: $margin-mobile;

          &_mobile {
            margin-bottom: 40px;

            .accordion-item {
              border-bottom: 1px solid $gray3;

              &__label {
                height: 70px;
                @include align-items(center);
                @include text-base($size: 14px, $weight: bold);
                line-height: 1.3;
                cursor: pointer;

                &::after {
                  font-size: 10px;
                }
              }

              &__content {
                @include text-base($size: 14px, $weight: $regular);
                line-height: 1.5;

                p {
                  margin-bottom: 20px;
                }
              }
            }

            input:checked {
              + .accordion-item__label {
                color: $gray2;
              }
            }
          }
        }

        &.block-scrollbar {
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }
}
