@import '../../../../../../styles/variables';
@import '../../../../../../styles/common';

.button-img-type {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray2;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    border: 1px solid $dark-brown;
    transition: all 0.2s;

    svg {
      path {
        fill: $dark-brown;
        transition: all 0.2s;
      }
    }
  }

  &:first-of-type {
    margin-right: 10px;
  }

  &.active {
    width: auto;
    border-radius: 100px;
    padding: 0 30px;

    &:hover {
      .button-img-type__txt {
        color: $dark-brown;
        transition: all 0.2s;
      }
    }

    .button-img-type__txt {
      @include text-base(
        $size: 0.8vw,
        $family: 'Montserrat',
        $weight: $light,
        $color: $gray2
      );

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    .button-img-type__icon {
      margin-right: 10px;
    }
  }

  svg {
    path {
      fill: $gray2;
      transition: all 0.2s;
    }
  }
}
