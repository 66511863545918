@import '../../../../../../styles/variables';

.add-set-to-cart-popup__mobile-block {
  padding: 0 15px;
}

.add-set-to-cart-popup__mobile-block__image-cost {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.add-set-to-cart-popup__mobile-block__item {
  padding: 15px 0;
  border-bottom: 1px solid $beige2;

  &:last-child {
    border: none;
  }
}
