@import '../../../styles/_variables';

.scroll-up-button {
  position: fixed;
  top: 5vw;
  right: 2.6vw;
  visibility: visible;
  opacity: 1;

  &_hide {
    visibility: hidden;
    opacity: 0;
  }

  @media screen and (max-width: 1024px) {
    right: 25px;
  }

  @media screen and (max-width: 767px) {
    right: 15px;
    bottom: 15px;
  }
}
