@import '../../../../../styles/variables';
@import '../../../../../styles/common';
@import '../../../../../styles/animations';

.hint {
  .product-info {
    margin-top: auto;
    padding-top: 25px;
    background-color: $beige;

    .item {
      @include display-flex();
      @include align-items(center);
      @include justify-content(flex-start);
      width: $hint_desktop_width;
      margin: auto;
      padding-top: 0;
      padding-bottom: 2vw;
      border-bottom: none;

      .item-image {
        margin-right: 3px;
        img {
          max-height: 3.469vw;
          position: relative;
        }
      }

      .item-characteristics {
        h2 {
          margin: 0;
          line-height: 1.5;
        }
      }

      .flex-wrapper {
        @include display-flex;
        @include flex-direction(row);
        @include align-items(center);
        @include justify-content(space-between);
        margin-left: auto;

        .item-price {
          text-align: center;

          .diamond-price {
            &.no-item {
              color: $gray2;
            }
          }
        }
      }
    }

    .info-message {
      width: $hint_desktop_width;
      margin: auto;
      padding-bottom: 4.271vw;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1024px) {
  .hint {
    .product-info {
      .item {
        width: $hint_laptop_width;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      }

      .info-message {
        width: $hint_laptop_width;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .hint {
    ul.diamond-characteristics_with-lines li {
      display: inline;
      margin: 0 5px;
      border: none;

      span:first-child {
        display: none;
      }
    }

    .product-info {
      .item {
        .item-image {
          img {
            max-height: 40px;
          }
        }
      }
    }
  }
}
