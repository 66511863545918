@import '../../../../../../styles/flex';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/common';

.content-main {
  &_block {
    &__start {
      .button-block {
        @include display-flex;
        @include flex-direction(row);
        vertical-align: bottom;
        justify-content: space-around;
        position: absolute;
        bottom: 0;
        transform: translate(-50%, 0);
        width: 100%;
        background-color: $beige;

        .btn {
          margin: 0;
          padding: 20px 0;
          font-size: 0.757vw;
          justify-content: center;
          width: 17.316vw;
          height: 37px;

          &:hover {
            background-color: $beige5;
            color: $brown-black;

            .icon {
              background-color: $brown-black;
            }
          }

          @media (max-width: 1024px) {
            font-size: 0.9vw;
            width: 22vw;
            height: 30px;
          }

          @media (max-width: 767px) {
            width: auto;
            height: var(--button-height);
            font-size: 12px;
            padding: 0 14px;

            &:not(:last-child) {
              margin-bottom: 0;
            }
          }
        }

        @media (max-width: 767px) {
          width: fit-content;
          max-width: 100%;
          position: static;
          // position: absolute;
          // left: 50%;
          // bottom: var(--button-block-vertical-margin);
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 26px;
          transform: translate(0, 0);
          box-sizing: border-box;
          margin-top: 20px;
        }
      }
    }
  }
}
