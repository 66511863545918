@import '../../../../styles/_variables';
@import '../../../../styles/_common';
@import '../../../../styles/_icons';

.stone-main-page {
  width: 100%;
}

.main-page {
  overflow: hidden;

  &.grid {
    // Start css-grid building
    display: grid;
    min-height: 100%;
    grid-template-areas: '. header header header .' '. slider1 title slider2 .' 'carousel carousel carousel carousel carousel' '. . pagination . .' '. . description next-page .';
    grid-template-columns: $margin-desktop 1fr 47vw 1fr $margin-desktop;
    grid-template-rows:
      minmax(190px, 20vh) minmax(105px, 8vh) minmax(380px, 40vh)
      2vw auto;
    background-image: linear-gradient(to right, $bg-beige 50%, $beige5 50%);

    .grid-area {
      &-header {
        grid-area: header;
      }

      &-slider-title-1 {
        grid-area: slider1;
      }

      &-title {
        grid-area: title;
      }

      &-slider-title-2 {
        grid-area: slider2;
      }

      &-carousel {
        grid-area: carousel;
      }

      &-pagination {
        grid-area: pagination;
      }

      &-slider-description {
        grid-area: description;
      }

      &-next-page {
        grid-area: next-page;
      }
    }

    // End css-grid building

    .grid-area-slider-title {
      @include display-flex;
      @include align-items(center);

      &.grid-area-slider-title-2 {
        @include justify-content(flex-end);
      }

      .slider-title {
        @include text-base($size: 1.042vw);
        display: inline;
        text-decoration: none;
        padding-bottom: 0.521vw;
        margin-right: 50px;
        cursor: pointer;
        border-bottom: 0.104vw solid transparent;

        &:hover {
          border-bottom-color: $yellow;

          @media (pointer: coarse) {
            border-bottom-color: transparent;
          }
        }

        &.active {
          border-bottom-color: $yellow;
        }
      }
    }

    .grid-area-title {
      @include display-flex;
      @include align-items(center);
      @include justify-content(center);

      .main-title {
        @include text-base(4.167vw, $family: 'DidotLH', $weight: $bold);
        text-align: center;
      }
    }

    .grid-area-carousel {
      &.carousel {
        position: relative;
        overflow: hidden;

        .slider-size-control {
          // Change this three properties for working with carousel
          @include slider-padding(38%, 2100, 1100, '.slider');
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .slider {
            box-sizing: border-box;

            .slider-inner {
              position: relative;
              height: 100%;
              margin: 0;
              transform: translate3d(0, 0, 0);
              text-align: left;
              font-size: 0;
              @include transition(transform 700ms ease-in);

              .slider-image-wrap {
                float: left;
                height: 100%;
                position: relative;

                .slider__image {
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 50%;
                  @include transform(translate(-50%, 0) scale(0.6));
                  @include transition(all 700ms ease-in);

                  &_initial {
                    opacity: 0;
                  }

                  &_blurred {
                    opacity: 1;
                  }

                  &:hover {
                    cursor: pointer;
                  }

                  .slider__image-img {
                    width: auto;
                    height: 100%;
                  }
                }

                &.active {
                  .slider__image {
                    @include transform(translate(-50%, 7%) scale(1));

                    &_initial {
                      opacity: 1;
                    }

                    &_blurred {
                      opacity: 0;
                    }
                  }
                }
              }
            }
          }
        }

        .arrow {
          width: 11vw;
          height: 11vw;
          @include display-flex;
          @include align-items(center);
          @include justify-content(center);
          position: absolute;
          top: 50%;
          @include transform(translateY(-62%));

          &:hover {
            cursor: pointer;
          }

          &_left {
            left: 0;
            margin-left: $margin-desktop;
          }

          &_right {
            right: 0;
            margin-right: $margin-desktop;
          }
        }
      }
    }

    .grid-area-pagination {
      @include display-flex;
      @include align-items(center);
      @include justify-content(center);
      position: relative;
      top: -1.563vw;

      .pagination {
        @include display-flex;
        @include align-items(center);
        @include justify-content(center);
        position: relative;
        transform: translate3d(0, 0, 0);
        @include transition(transform 700ms ease-in);

        &-item {
          width: 0.781vw;
          height: 0.781vw;
          @include display-flex;
          @include align-items(center);
          @include justify-content(center);
          margin: 0 0.26vw;
          @include transition(margin 700ms ease-in);

          &:before {
            content: '';
            display: block;
            width: 0.365vw;
            height: 0.365vw;
            min-width: 6px;
            min-height: 6px;
            background-color: $gray3;
            border-radius: $border-radius-circle;
          }

          &:hover {
            cursor: pointer;

            &:before {
              background-color: $dark-brown;
              @include transition(background-color 300ms ease-in);
            }
          }

          &.active {
            margin: 0 1.302vw;

            &:before {
              background-color: $dark-brown;
              @include transition(
                background-color 700ms ease-in,
                margin 700ms ease-in
              );
            }
          }
        }
      }
    }

    .grid-area-slider-description {
      text-align: center;
      padding: 0 1.563vw;

      .description-title-wrap {
        @include display-flex;
        @include align-items(center);
        @include justify-content(center);

        .description-title {
          @include text-base($size: 1.875vw, $family: 'Roboto Condensed');
          @include display-flex;
          @include justify-content(center);
          position: relative;
          margin: 0.521vw 0;

          .custom-tooltip {
            right: 0;
            transform: translate(3vw, -0.2vw);
          }
        }
      }

      .description-wrap {
        .description-input {
          display: none;
        }

        .description-text {
          @include text-base($size: 0.833vw, $weight: $regular);
          line-height: 1.9;
        }

        .description-label {
          @include text-base($size: 12px);
          display: none;
          margin-top: 14px;
          margin-bottom: 10px;

          &:hover {
            cursor: pointer;
            color: $light-brown;
          }

          &:before {
            content: 'Show More';
          }
        }
      }
    }

    .grid-area-next-page {
      @include display-flex;
      @include align-items(flex-start);
      @include justify-content(flex-end);
      padding-top: 6.771vw;

      .next-page-pointer {
        margin: 0.521vw 0;
      }
    }
  }
}

// Width 1280px for Samsung Tab S2
@media screen and (max-width: 1280px) {
  .main-page {
    &.grid {
      // Start css-grid building for laptop
      grid-template-areas: '. header header header .' '. slider1 title slider2 .' '. . title . .' 'carousel carousel carousel carousel carousel' '. . pagination . .' '. description description description .' '. next-page next-page next-page .';
      grid-template-columns: $margin-laptop 1fr 1fr 1fr $margin-laptop;
      grid-template-rows: 90px 8vh 50px minmax(42vh, 57vh) 0 auto 10vh;
      @media screen and (max-width: 900px) {
        grid-template-rows: 90px 15vh 50px minmax(220px, auto) 50px auto 10vh;
      }
      // End css-grid building for laptop

      .grid-area-slider-title {
        @include display-flex;
        @include align-items(center);

        &.grid-area-slider-title-2 {
          @include justify-content(flex-end);
        }

        .slider-title {
          font-size: 12px;
          padding-bottom: 10px;
          border-bottom-width: 2px;

          &:hover,
          &.active {
            border-bottom-width: 2px;
          }
        }
      }

      .grid-area-title {
        .main-title {
          font-size: 48px;
          @media screen and (max-width: 820px) {
            font-size: 35px;
          }
        }
      }

      .grid-area-carousel {
        &.carousel {
          .slider-size-control {
            // Change this three properties for working with carousel
            @include slider-padding(37%, 2500, 1500, '.slider');
          }

          .arrow {
            width: 15vw;
            height: 15vw;
          }
        }
      }

      .grid-area-slider-description {
        padding: 0 30px;

        .description-title-wrap {
          .description-title {
            font-size: 25px;
            margin: 10px 0;

            .custom-tooltip {
              transform: translate(50px, -3px);
            }
          }
        }

        .description-wrap {
          .description-text {
            font-size: 14px;
          }
        }
      }

      .grid-area-next-page {
        padding-top: 0;

        .next-page-pointer {
          margin: 10px 0;
        }
      }
    }
  }

  @media screen and (max-height: 650px) {
    .main-page {
      // Revert position "fixed" for tablets with small height to scroll appears
      position: initial;
    }
  }
}

@media (max-width: 767px) {
  .main-page {
    // Revert position "fixed" for mobiles
    position: initial;

    &.grid {
      // Start css-grid building for mobile
      min-height: auto;
      grid-template-areas: '. header header .' '. slider1 slider2 .' '. title title .' 'carousel carousel carousel carousel' '. description description .' '. next-page next-page .';
      grid-template-columns: $margin-mobile 1fr 1fr $margin-mobile;
      grid-template-rows: 80px 12vh 15vh minmax(150px, 54vw) minmax(15vh, auto) 10vh;
      // End css-grid building for mobile

      .grid-area-title {
        .main-title {
          font-size: 35px;
        }
      }

      .grid-area-carousel {
        &.carousel {
          .slider-size-control {
            // Change this three properties for working with carousel
            @include slider-padding(48%, 1000, 1000, '.slider');
          }
          //calc(65px + 17vw * 2);

          .arrow {
            width: 15vw;
            height: 25vw;

            &_left {
              margin-left: 0;
            }

            &_right {
              margin-right: 0;
            }
          }
        }
      }

      .grid-area-pagination {
        display: none;
      }

      .grid-area-slider-description {
        padding: 0 30px;
        padding-bottom: 50px;

        .description-title-wrap {
          .description-title {
            font-size: 25px;
            margin: 10px 0;
          }
        }

        .description-wrap {
          .description-input {
            &:checked + p {
              -webkit-line-clamp: unset;

              & + label {
                &:before {
                  content: 'Show Less';
                }
              }
            }
          }

          .description-text {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 4;
          }

          .description-label {
            display: block;
          }
        }
      }

      .grid-area-next-page {
        width: 100%;
        height: 50px;
        //  position: fixed;
        bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        background-image: linear-gradient(to right, $bg-beige 50%, $beige5 50%);
      }
    }
  }

  // Decision for mobiles with small height
  @media screen and (max-height: 600px) {
    .main-page {
      &.grid {
        .grid-area-slider-description {
          .description-wrap {
            .description-text {
              -webkit-line-clamp: 2;

              // For devices with extra-small height
              @media screen and (max-height: 550px) {
                -webkit-line-clamp: 1;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (orientation: landscape) {
    .main-page {
      &.grid {
        grid-template-areas: '. header header .' '. slider1 slider2 .' '. title title .' 'carousel carousel carousel carousel' '. description description .' '. next-page next-page .';
        grid-template-rows: 80px 14vh 17vh 50vh auto auto;

        .grid-area-carousel {
          &.carousel {
            .slider-size-control {
              // Change this three properties for working with carousel
              @include slider-padding(48%, 2000, 900, '.slider');
            }

            .arrow {
              width: 10vw;
              height: 16vw;
            }
          }
        }

        .grid-area-slider-description {
          padding-bottom: 0;
        }

        .grid-area-next-page {
          position: initial;
        }
      }
    }
  }
}

/* ----------- for landscape orientation for iPhone X, Pixel 2 XL ----------- */
@media only screen and (max-device-width: 823px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .main-page {
    &.grid {
      .grid-area-carousel {
        &.carousel {
          .slider-size-control {
            // Change this three properties for working with carousel
            @include slider-padding(48%, 2000, 800, '.slider');
          }

          .arrow {
            width: 8vw;
            height: 12vw;

            &_left {
              margin-left: 0;
            }

            &_right {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
