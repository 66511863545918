@import '../../styles/variables';
@import '../../styles/flex';
@import '../../styles/common';
@import '../../styles/icons';

//The grid contains nesting grid

$compare-item-header-desktop: 10.417vw;
$compare-item-header-laptop: 200px;

$item-width-desktop: 18.229vw;
$item-width-tablet: 33vw;

.compare-page {
  &.grid {
    // Start css-grid building for Desktop
    display: grid;
    min-height: 100%;
    grid-template-areas: '. header .' '. content content';
    grid-template-columns: $margin-desktop auto $margin-desktop;
    grid-template-rows: $header-height-desktop auto;
    background: linear-gradient(
      to right,
      $bg-beige 0%,
      $bg-beige 22%,
      white 22%,
      white 100%
    );

    .grid-area {
      &-header {
        grid-area: header;
      }

      &-content {
        // Nesting grid
        .grid-area-breadcrumbs {
          grid-area: breadcrumbs;
        }

        .grid-area-page-title {
          grid-area: page-title;
        }

        .grid-area-left-bar {
          grid-area: left-bar;
          z-index: 2;
        }

        .grid-area-comparison-block {
          grid-area: comparison-block;
          z-index: 2;
        }

        grid-area: content;
        display: grid;
        grid-template-areas: 'breadcrumbs comparison-block' 'page-title comparison-block' 'left-bar comparison-block';
        grid-template-columns: 14.583vw auto;
        grid-template-rows: $breadcrumbs-height-desktop $page-title auto;
        padding-bottom: 5.208vw;
      }
    }

    // End css-grid building for Desktop

    .grid-area-content {
      position: relative;

      .grid-area-left-bar {
        .left-bar-checkbox-wrap {
          width: 100%;
          height: calc(
            #{$compare-item-header-desktop} - #{$breadcrumbs-height-desktop} - #{$page-title}
          );
          @include display-flex();
          @include align-items(center);
        }

        .left-bar-list {
          width: 100%; //So that the lines go out the block
          position: absolute;
          top: $compare-item-header-desktop;
          cursor: default;

          li {
            @include display-flex();
            @include align-items(center);
            @include text-base(
              $size: 0.833vw,
              $weight: $regular,
              $color: $gray
            );
            height: 3.75vw;
            border-bottom: 0.052vw solid $gray5;

            .checkbox-container {
              margin-bottom: 0;
            }

            &.equal {
              display: none;
            }
          }
        }
      }

      .grid-area-comparison-block {
        position: relative;
        @include display-flex();
        @include align-items(flex-start);
        @include justify-content(center);

        .comparison-block-items {
          width: 46.875vw;
          height: 100%;
          display: flex;
          overflow: hidden; // Prevent the user to scroll by hands when there are slider controls

          .carousel-wrap {
            display: flex;
            @include transition(transform 500ms ease-in);

            .comparison-block-item {
              display: flex;
              flex-direction: column;
              padding-left: 1.458vw;
              box-sizing: border-box;
              width: $item-width-desktop;
              flex-shrink: 0;
              cursor: default;

              &.active {
                visibility: visible;
              }

              &-header {
                min-height: $compare-item-header-desktop;

                &-img {
                  position: relative;
                  padding-top: 0.781vw;

                  img {
                    width: 7.813vw;
                  }

                  .btn {
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                }

                &__sku {
                  @include text-base($size: 0.938vw, $weight: $bold);
                }
              }

              &-features {
                li {
                  @include display-flex();
                  @include align-items(center);
                  @include text-base($size: 0.885vw, $color: $brown-black);
                  height: 3.75vw;
                  border-bottom: 0.052vw solid transparent;

                  .label {
                    display: none;
                  }

                  &.feature-price {
                    @include text-base(
                      $size: 0.938vw,
                      $weight: $bold,
                      $color: $yellow
                    );
                    .no-item {
                      color: $red;
                    }
                  }

                  .common-link {
                    svg {
                      margin-left: 0.365vw;
                    }

                    &:hover {
                      svg {
                        path {
                          fill: $dark-gray;
                        }
                      }
                    }

                    &:active {
                      svg {
                        path {
                          fill: $gray2;
                        }
                      }
                    }
                  }

                  &.equal {
                    display: none;
                  }
                }
              }
            }
          }
          .items-loader {
            width: 100%;
          }
        }

        .arrow {
          height: 7vw;
          padding: 0.521vw;
          @include display-flex();
          @include align-items(center);
          @include justify-content(center);
          position: absolute;

          &__text {
            @include text-base($size: 0.833vw, $family: 'Roboto Condensed');
          }

          &:hover {
            cursor: pointer;

            .arrow__text {
              color: $gray2;
            }

            .icon_arrow {
              border-bottom-color: $gray2;

              &:before {
                border-right-color: $gray2;
                border-top-color: $gray2;
              }
            }
          }

          &.disabled {
            pointer-events: none;

            .arrow__text {
              color: $gray3;
            }

            .icon_arrow {
              border-bottom-color: $gray3;

              &:before {
                border-right-color: $gray3;
                border-top-color: $gray3;
              }
            }
          }

          &_left {
            left: 3.646vw;

            .arrow__text {
              margin-left: 0.521vw;
            }
          }

          &_right {
            right: 6.771vw;

            .arrow__text {
              margin-right: 0.521vw;
            }
          }
        }

        .btn_clear {
          display: none;
        }

        .screen-saver {
          align-self: center;
        }
      }
    }
  }

  .btn_clear_sticky {
    position: sticky;
    z-index: 3;
    top: 100vh;
    left: 100vw;
    width: max-content;
    height: max-content;
    margin-bottom: 1.042vw;
    margin-right: 1.042vw;
  }

  .btn {
    svg.icon_trash_light {
      width: 13px;
      height: 15px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .compare-page {
    &.grid {
      // Start css-grid building for laptop
      grid-template-columns: $margin-laptop auto $margin-laptop;
      grid-template-rows: $header-height-laptop auto;
      background: linear-gradient(
        to right,
        $bg-beige 0,
        $bg-beige 300px,
        white 300px,
        white 100%
      );

      .grid-area {
        &-content {
          grid-template-areas: 'breadcrumbs comparison-block' 'page-title comparison-block' 'left-bar comparison-block';
          grid-template-columns: calc(300px - #{$margin-laptop}) auto;
          grid-template-rows: $breadcrumbs-height-laptop $page-title auto;
        }
      }

      .grid-area-content {
        padding-bottom: 100px;

        .grid-area-left-bar {
          .left-bar-checkbox-wrap {
            height: calc(
              #{$compare-item-header-laptop} - #{$breadcrumbs-height-desktop} -
                #{$page-title}
            );
          }

          .left-bar-list {
            top: $compare-item-header-laptop;

            li {
              font-size: 16px;
              height: 72px;
              border-bottom-width: 1px;
            }
          }
        }

        .grid-area-comparison-block {
          .comparison-block-items {
            width: calc(100vw - 300px);
            height: 100%;
            overflow: auto; // Allow the user to scroll by hands when there are no slider controls

            .carousel-wrap {
              .comparison-block-item {
                padding-left: 28px;
                width: $item-width-tablet;

                &-header {
                  min-height: $compare-item-header-laptop;

                  &-img {
                    padding-top: 15px;

                    img {
                      width: 150px;
                    }
                  }

                  &__sku {
                    font-size: 18px;
                  }
                }

                &-features {
                  li {
                    font-size: 17px;
                    height: 72px;
                    border-bottom-width: 1px;

                    &.feature-price {
                      font-size: 18px;
                    }

                    .common-link {
                      svg {
                        margin-left: 7px;
                      }
                    }
                  }
                }
              }
            }
          }

          .arrow {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .compare-page {
    &.grid {
      // Start css-grid building for laptop
      grid-template-areas: '. header .' 'content content content';
      grid-template-columns: $margin-mobile auto $margin-mobile;
      grid-template-rows: $header-height-mobile auto;
      background: $bg-mobile-beige;

      .grid-area-content {
        grid-template-areas: 'breadcrumbs' 'page-title' 'left-bar' 'comparison-block';
        grid-template-columns: auto;
        grid-template-rows: $breadcrumbs-height-mobile $page-title-height-mobile 40px auto;
        padding-bottom: 0;

        .grid-area-breadcrumbs {
          margin-left: $margin-mobile;
          margin-right: $margin-mobile;
        }

        .grid-area-page-title {
          text-align: center;
          margin-left: $margin-mobile;
          margin-right: $margin-mobile;
        }

        .grid-area-left-bar {
          margin-left: $margin-mobile;
          margin-right: $margin-mobile;

          .left-bar-checkbox-wrap {
            justify-content: center;
            height: auto;
          }

          .left-bar-list {
            display: none;
          }
        }

        .grid-area-comparison-block {
          .comparison-block-items {
            width: 100vw;
            padding-bottom: 100px;

            .carousel-wrap {
              .comparison-block-item {
                padding-left: 0;
                width: 100vw;

                &-header {
                  padding-top: 15px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: space-between;
                  background: white;
                  padding-left: $margin-mobile;
                  padding-right: $margin-mobile;

                  &-img {
                    img {
                      margin: 10px;
                    }
                  }

                  &__sku {
                    font-size: 14px;
                  }
                }

                &-features {
                  background-color: $white;

                  li {
                    font-size: 14px;
                    height: 55px;
                    border-bottom: 1px solid $gray5;
                    padding-left: $margin-mobile;
                    padding-right: $margin-mobile;
                    justify-content: space-between;

                    .label {
                      display: inline-block;
                      color: $gray;
                      font-weight: $regular;
                    }

                    &.feature-price {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }

          .btn_clear {
            z-index: 3;
            display: block;
            position: absolute;
            bottom: 20px;
            left: 50%;
            right: auto;
            transform: translate(-50%, 0);
          }

          .screen-saver {
            align-self: flex-start;
          }
        }
      }
      .btn_clear_sticky {
        display: none;
      }
    }
  }
}
