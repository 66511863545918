@import '../../../../styles/flex';
@import '../../../../styles/variables';
@import '../../../../styles/common';

.content-main {
  &_block {
    &__rings {
      @include display-flex;
      @include justify-content(center);
      flex-grow: 1;
      margin-bottom: 15vh;
      position: relative;
      align-items: center;
      width: 100%;
      @media screen and (max-width: 767px) {
        align-items: center;
        flex-grow: 0.5;
      }

      .img-container {
        height: 100%;
        max-height: 50.344vh;
        position: absolute;
        @media screen and (max-width: 1024px) {
          max-width: 100%;
        }
        @media screen and (max-width: 767px) {
          height: 200px;
        }

        img {
          height: 100%;
          width: auto;
        }

        .light-loader__container {
          width: 45%;
          position: relative;
        }
      }
    }
  }

  #rings {
    .content-main_btn {
      position: absolute;
      top: 90vh;

      @media screen and (max-width: 1024px) {
        height: 80%;
      }

      @media screen and (max-width: 767px) {
        top: 75%;
        height: auto;
      }

      .btn_lg2 {
        padding: 19px 109px;
      }
    }
  }

  .main-slide {
    &_rings {
      min-height: 535px;
      height: 1200px;

      @media screen and (max-width: 1024px) {
        height: 1300px;
      }

      @media screen and (max-width: 767px) {
        height: 800px;
      }

      @media screen and (max-width: 400px) {
        height: 700px;
      }
    }
  }

  .main-slide-next {
    #rings {
      box-shadow: none !important;
    }
  }

  .main-slide-prev {
    #rings {
      box-shadow: none !important;
    }
  }
}
