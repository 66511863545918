.toggle-enter {
  opacity: 0;
}

.toggle-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.toggle-exit {
  opacity: 1;
}

.toggle-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.zoom {
  &-appear,
  &-enter {
    opacity: 0;
    transform: scale3d(0.7, 0.7, 0.7);
  }

  &-appear-active,
  &-enter-active {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transition: transform 0.7s cubic-bezier(0.38, 0.84, 0.83, 0.67),
      opacity 0.7s ease;
  }
}

// Animations for Main Page
.arise-appear {
  opacity: 0;
}

.arise-appear-active {
  opacity: 1;
  transition-delay: 1s;
  transition-duration: 1s;
}

.fadeInUp-appear {
  opacity: 0;
  transform: translate3d(0, 120%, 0) scale3d(1.2, 1.2, 1.2);
}

.fadeInUp-appear-active {
  opacity: 1;
  transform: translate3d(0, 80%, 0) scale3d(1.2, 1.2, 1.2);
  transition-duration: 1s;
}

.fadeInUp-appear-done {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transition-duration: 1s;
}

.fadeInDown-appear {
  opacity: 0;
  -webkit-transform: translate3d(0, -10%, 0);
  transform: translate3d(0, -10%, 0);
}

.fadeInDown-appear-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-delay: 2s;
  transition-duration: 0.7s;
}

.fadeInDown-fast-appear {
  opacity: 0;
  -webkit-transform: translate3d(0, -10%, 0);
  transform: translate3d(0, -10%, 0);
}

.fadeInDown-fast-appear-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-delay: 0.4s;
  transition-duration: 0.7s;
}

.fadeInDown-slow-appear {
  opacity: 0;
  -webkit-transform: translate3d(0, -3%, 0);
  transform: translate3d(0, -3%, 0);
}

.fadeInDown-slow-appear-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-duration: 0.7s;
}

.fadeInLeft-appear {
  opacity: 0;
  -webkit-transform: translate3d(-10%, 0, 0);
  transform: translate3d(-10%, 0, 0);
}

.fadeInLeft-appear-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-delay: 2s;
  transition-duration: 0.7s;
}

.fadeInRight-appear {
  opacity: 0;
  -webkit-transform: translate3d(10%, 0, 0);
  transform: translate3d(10%, 0, 0);
}

.fadeInRight-appear-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-delay: 2s;
  transition-duration: 0.7s;
}

.fadeInUp2-appear {
  opacity: 0;
  -webkit-transform: translate3d(0, 20%, 0);
  transform: translate3d(0, 20%, 0);
}

.fadeInUp2-appear-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
  transition-delay: 2s;
  transition-duration: 0.7s;
}

.switch-transparency {
  &-appear,
  &-enter {
    opacity: 0;
  }

  &-appear-active,
  &-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-out;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 400ms ease-out;
  }
}

.fadeInRight2-enter {
  opacity: 0;
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
}

.fadeInRight2-enter-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-duration: 0.5s;
}

.fadeInRight2-exit {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.fadeInRight2-exit-active {
  opacity: 0;
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
  transition-duration: 0.5s;
}

.fadeInRight2-exit-done {
  opacity: 0;
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
  transition-duration: 0.5s;
}

.expand-height-width {
  &-enter {
    opacity: 0;
    transform: scale(0);
    transform-origin: top right;
    transition-delay: 0.5s;

    &-active {
      opacity: 1;
      transform: scale(1);
      transition: all 0.5s ease-in;
      transition-delay: 0.5s;
    }
  }

  &-exit {
    opacity: 1;
    transform: scale(1);
    transform-origin: top right;
    transition-delay: 0.5s;

    &-active {
      opacity: 0;
      transform: scale(0);
      transition: all 0.2s ease-in;
      transition-delay: 0.5s;
    }
  }
}
