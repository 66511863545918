@import '../../../styles/variables';
@import '../../../styles/common';

.custom-select-labelled {
  @include text-base($size: 0.833vw, $color: $gray2);
  line-height: 2.6em;
  position: relative;
  padding-top: 0.521vw;

  .select {
    white-space: nowrap;
    border-bottom: 0.052vw solid $beige2;
    background-color: inherit;

    &:hover {
      border-bottom-color: $brown-black;

      @media (pointer: coarse) {
        border-bottom-color: $beige2;
      }
    }

    &:active {
      border-bottom-color: $brown-black;
    }

    // The solution how to make the input floating width
    &__label {
      float: left;
      cursor: pointer;
    }

    // The solution how to make the input floating width
    .select-inner-wrap {
      display: block;
      overflow: hidden;

      // You can apply .select__inner to div or input
      .select__inner {
        width: 90%;
        display: inline-block;
        border: none;
        text-align: right;
        padding-right: 1.042vw;
        color: $brown-black;
        cursor: pointer;
      }
    }
  }



  .options {
    z-index: 1;
    display: none;
    position: absolute;
    width: 100%;
    max-height: 9.375vw;
    box-sizing: border-box;
    padding: 0.521vw 0;
    background-color: inherit;
    box-shadow: $shadow-bottom-sm;

    &-list {
      overflow-y: auto;
      max-height: 8.333vw;
      width: 100%;
      box-sizing: border-box;

      &__item {
        line-height: 1.3em;
        padding: 0.5em;
        color: $dark-brown;

        &:hover {
          cursor: pointer;
          background-color: $white;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;

    &-track {
      border-radius: 10px;
      background: $beige2;
    }

    &-thumb {
      background: $dark-brown;
      border-radius: 3px;

      &:hover {
        background: $brown-black;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    padding-top: 10px;

    .select {
      border-bottom-width: 1px;

      .select-inner-wrap {
        .select__inner {
          padding-right: 20px;
        }
      }
    }

    .options {
      max-height: 180px;
      padding: 7px 0;

      &-list {
        max-height: 160px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
}

.options-list__item{
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

