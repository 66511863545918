@import '../../styles/variables';
@import '../../styles/common';
@import '../../styles/animations';

$title-height: 3vw;

$footer-desktop: 6vw;
$footer-laptop: 130px;

.faq-page {
  width: 100%;
  height: 100%;
  // The decision for adaptive height on Android tablets and mobiles with hidden/shown browser toolbars
  // https://developers.google.com/web/updates/2016/12/url-bar-resizing

  &.grid {
    // Start css-grid building for Desktop
    display: grid;
    min-height: 100%;
    grid-template-areas: 'header header header' 'title title title' 'content content content';
    grid-template-columns: $margin-desktop auto $margin-desktop;
    grid-template-rows: $header-height-desktop $title-height;

    .grid-area {
      &-header {
        grid-area: header;
        width: 100%;
        padding-left: $margin-desktop;
        padding-right: $margin-desktop;
      }

      &-page-title {
        grid-area: title;
      }

      &-content {
        grid-area: content;
        background-color: $bg-beige;
        padding-left: $margin-desktop;
        padding-right: $margin-desktop;
        overflow-x: hidden;
      }

      &-attention {
        display: none;
      }

      // End css-grid building for Desktop
    }

    .grid-area-page-title {
      text-align: center;
    }

    .grid-area-content {
      .tabs_container {
        padding: 0 7.813vw;
        box-sizing: border-box;

        .tabs-links-wrap {
          position: relative;
          height: 5.208vw;

          .scrollable-tabs {
            &__arrow {
              height: 100%;
              @include display-flex();
              @include justify-content(center);
              @include align-items(center);
            }

            .slider-wrap {
              .slider {
                .slider-item {
                  margin-top: 2.083vw;
                  margin-bottom: 2.083vw;

                  &__link {
                    // DidotLH isn't exist, serif loaded instead
                    @include text-base(
                      $size: 0.833vw,
                      $family: 'DidotLH',
                      $fallback-family: 'serif',
                      $weight: bold,
                      $color: $gray2
                    );
                    line-height: 1.2;
                    letter-spacing: 0.052vw;
                    transition: background 0.1s, color 0.1s;
                    padding-bottom: 0.521vw;
                    text-decoration: none;
                    border-bottom: 0.104vw solid transparent;

                    &:hover {
                      border-bottom-color: $yellow;

                      @media (pointer: coarse) {
                        border-bottom-color: transparent;
                      }
                    }

                    &.active {
                      border-bottom-color: $yellow;
                    }

                    // font for sensor devices
                    @media (pointer: coarse) {
                      @include text-base(
                        $size: 0.833vw,
                        $family: 'DidotLH',
                        $fallback-family: 'serif',
                        $weight: bold,
                        $color: $gray2
                      );
                    }
                  }
                }
              }
            }
          }
        }

        .tabs-panels {
          height: calc(
            100vh - #{$header-height-desktop} - #{$title-height} - 5.208vw
          );
          overflow-y: auto;
          padding-left: 3.385vw;

          .dialog-venori-says-wrap {
            width: 100%;
            position: relative;
            box-sizing: border-box;
            padding: 0.391vw 0.26vw;

            .dialog__venori-says {
              @include display-inline-flex();
              @include align-items(center);
              @include text-base($size: 16px, $weight: $regular);
              line-height: 1.2;
              box-sizing: border-box;
              height: 3.542vw;
              border-radius: 2.604vw;
              background-color: $beige3;
              border: 0.078vw solid $beige3;
              padding: 0.521vw 1.563vw;
              position: relative;

              &.answer {
                @include flex-direction(column);
                @include align-items(start);
                line-height: 2;
                height: auto;
                width: 100%;
                border-radius: 10px;
                padding: 24px 30px;

                .answer__title {
                  font-weight: $semibold;
                  line-height: 2;
                  margin-bottom: 1.563vw;
                }
              }
            }

            .dialog-venori-says-icon {
              background-color: $dark-brown;
              position: absolute;
              display: flex;
              flex-shrink: 0;
              justify-content: center;
              align-items: center;
              border-radius: $border-radius-circle;
              height: 2.344vw;
              width: 2.344vw;
              left: -2.78vw;
              top: 11px;
              z-index: 1;

              @media screen and (max-width: 1024px) {
                height: 36px;
                width: 36px;
                left: -45px;
                top: 18px;
              }

              svg {
                width: 1.25vw;
                height: 1.25vw;
                @media screen and (max-width: 1024px) {
                  width: 18px;
                  height: 18px;
                }

                path {
                  fill: #fff;
                }
              }
            }
          }

          .chunk-of-questions {
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;

            .dialog-question-wrap {
              box-sizing: border-box;
              width: 100%;
              max-width: 100%;
              padding: 0.391vw 0.26vw;

              .dialog__question {
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include text-base($size: 16px, $weight: $regular);
                line-height: 1.2;
                box-sizing: border-box;
                width: 100%;
                border-radius: 10px;
                padding: 24px 30px;
                text-decoration: none;
                background-color: #f8f5f2;
                background-image: var(--angle-down);
                background-size: 13px;
                background-repeat: no-repeat;
                background-position: calc(100% - 24px) 50%;
                cursor: pointer;

                .dialog-question__arrow {
                  transition: 0.2s;
                }

                &.active {
                  .dialog-question__arrow {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer {
      position: fixed;
      bottom: 0;
      height: $footer-desktop;
      width: 100%;
      box-sizing: border-box;
      padding-top: 1.042vw;
      padding-left: $margin-desktop;
      padding-right: $margin-desktop;
      background-color: $bg-beige;
    }
  }
}

@media screen and (max-width: 1024px) {
  .faq-page {
    &.grid {
      // Start css-grid building for Laptop
      grid-template-areas: 'header header header' 'title title title' 'content content content';
      grid-template-columns: $margin-laptop calc(100vw - #{$margin-laptop} * 2) $margin-laptop;
      grid-template-rows: $header-height-laptop $title-height;
      // End css-grid building for Laptop

      .grid-area-header {
        padding-left: $margin-laptop;
        padding-right: $margin-laptop;
      }

      .grid-area-content {
        position: relative;
        padding-left: $margin-laptop;
        padding-right: $margin-laptop;

        .tabs_container {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 0;

          .tabs-links-wrap {
            height: 56px;

            .scrollable-tabs {
              .slider-wrap {
                .slider {
                  .slider-item {
                    margin-top: 19px;
                    margin-bottom: 19px;

                    &__link {
                      font-size: 14px;
                      padding-bottom: 10px;
                      border-bottom-width: 2px;
                    }
                  }
                }
              }
            }
          }

          .tabs-panels {
            width: 103%; // Scrollbar should be outside of the block
            position: absolute;
            height: calc(100% - 76px); //  minus scrollable links height
            padding-left: 0;
            box-sizing: border-box;

            .dialog-venori-says-wrap {
              padding: 10px 4px;

              .dialog__venori-says {
                font-size: 14px;
                height: 60px;
                border-radius: 50px;
                border-width: 1.5px;
                padding: 10px 25px;

                &.answer {
                  line-height: 1.8;
                  padding: 10px 25px;

                  .answer__title {
                    line-height: 1.8;
                    margin-bottom: 20px;
                  }
                }
              }
            }

            .chunk-of-questions {
              @include flex-direction(column);

              .dialog-question-wrap {
                box-sizing: border-box;
                padding: 6px 4px;

                .dialog__question {
                  font-size: 14px;
                  padding: 10px 25px;

                  &.active {
                    background-color: $dark-brown;
                    color: $white;
                  }

                  &:hover {
                    background-color: $gray2;
                    color: $white;
                    border-color: $gray2;
                  }
                }
              }
            }

            &.block-scrollbar {
              margin-right: -40px;
              padding-right: 40px;
            }
          }
        }
      }

      .footer {
        height: $footer-laptop;
        padding-top: 20px;
        padding-left: $margin-laptop;
        padding-right: $margin-laptop;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .faq-page {
    position: initial;

    &.grid {
      // Start css-grid building for mobile
      grid-template-areas: 'header header header' 'title title title' 'content content content' 'footer footer footer';
      grid-template-columns: $margin-mobile calc(100vw - #{$margin-mobile} * 2) $margin-mobile;
      grid-template-rows: $header-height-mobile min-content auto;
      background-color: $bg-mobile-beige;
      // End css-grid building for mobile

      .grid-area-header {
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
        background-color: $bg-mobile-beige;
      }

      .grid-area-page-title {
        display: block;
        padding-bottom: $margin-mobile;
        background-color: $bg-mobile-beige;
      }

      .grid-area-content {
        padding-left: 0;
        padding-right: 0;

        .tabs_container {
          .tabs-links-wrap {
            .scrollable-tabs {
              &__arrow {
                width: 25px;
              }

              .slider-wrap {
                .slider {
                  .slider-item {
                    margin-top: 20px;
                    margin-bottom: 20px;

                    &__link {
                      font-size: 14px;
                      padding-bottom: 5px;
                      border-bottom-width: 2px;
                    }
                  }
                }
              }
            }
          }

          .tabs-panels {
            height: auto;
            width: auto;
            position: initial;
            margin-left: $margin-mobile;
            margin-right: $margin-mobile;
            padding-bottom: $footer-laptop;

            .dialog-venori-says-wrap {
              .dialog__venori-says {
                height: auto;
                line-height: 1.6;
              }
            }

            &.block-scrollbar {
              margin-right: 0;
              padding-right: $margin-mobile;
            }
          }
        }
      }

      .footer {
        width: 100%;
        padding-top: 20px;
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
        box-shadow: $shadow-top-xs;
        background-color: $white;
      }
    }
  }
}

@media (max-width: 823px) and (orientation: landscape) {
  .faq-page {
    &.grid {
      grid-template-areas: '. header header .' '. attention . .';
      grid-template-columns: $margin-desktop;
      grid-template-rows: $header-height-desktop;

      .grid-area {
        &-header {
          grid-area: header;
          padding-left: $margin-desktop;
          padding-right: $margin-desktop;
        }

        &-page-title {
          display: none;
        }

        &-content {
          display: none;
        }

        &-attention {
          display: flex;
          grid-area: attention;
          justify-content: center;
          margin-top: 10%;
          margin-left: 20%;
          width: 60%;
          @include text-base($size: 24px, $weight: $bold, $color: $yellow-dark);
        }
      }
    }
  }
}
