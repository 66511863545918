@import '../../styles/common';
@import '../../styles/variables';
@import '../../styles/functions';

.hint-product-set {
  width: 57.8125vw;
  position: absolute;
  top: $header-height-desktop;
  left: $margin-desktop + 24.74vw + 2.604vw;

  background: #ffffff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px 30px;
  @include text-base($size: getVW(14), $color: $dark-brown);
  line-height: 17px;
  z-index: 10;
  box-sizing: border-box;
  @media (max-width: 1024px) {
    top: $header-height-laptop;
    font-size: 14px;
  }
  @media (max-width: 900px) {
    width: calc(100% - #{$margin-laptop} * 2);
    left: $margin-laptop;
  }
  @media (max-width: 767px) {
    top: 85px;
  }
  @media (max-width: 700px) {
    padding: 15px 20px;
  }
}

.hint-product-set__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;

  @media (max-width: 700px) {
    padding-right: 0;
  }
}

.hint-product-set__info {
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
}

.hint-product-set__img {
  width: auto;
  margin-right: 10px;
  @media (max-width: 767px) {
    margin-right: 20px;
  }

  svg,
  img {
    width: 80px;
    height: 80px;
    @media (max-width: 767px) {
      width: 50px;
    }
  }
}

.hint-product-set__name {
  margin-right: 40px;
  font-size: getVW(18);
  font-weight: 700;

  &_mobile {
    display: none;
    white-space: nowrap;
    text-decoration: underline;
  }

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 700px) {
    display: none;

    &_mobile {
      display: inline-block;
    }
  }
}

.hint-product-set__description {
  > div {
    margin: 4px 0;
  }

  span {
    font-size: getVW(12);
    font-weight: 400;
    line-height: 15px;

    .text {
      display: inline-block;
      text-overflow: ellipsis;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      line-height: 9px;
      color: $yellow;
    }
  }

  @media (max-width: 1024px) {
    span {
      font-size: 12px;
    }
  }

  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    margin-top: 10px;
  }
}

.hint-product-set__change {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background: none;
  border: 1px solid $dark-brown;
  border-radius: 56px;
  cursor: pointer;
  transition: $animation-global;

  &:hover {
    background: #4d4048;
    border-color: #4d4048;
    transition: $animation-global;

    svg {
      path {
        fill: #fff;
        transition: $animation-global;
      }
    }
  }

  svg {
    position: relative;
    left: 3px;
    top: -1px;

    path {
      fill: $dark-brown;
      transition: $animation-global;
    }
  }

  @media (max-width: 1024px) {
    margin-left: 15px;
  }

  @media (max-width: 700px) {
    display: none;
  }
}

.hint-product-set__close {
  position: absolute;
  top: 13px;
  right: 16px;
  cursor: pointer;

  svg {
    cursor: pointer;
  }
}
