@import '../../../styles/_variables';
@import '../../../styles/_common';
@import '../../../styles/_flex';

header.header {
  z-index: 999;
  height: $header-height-desktop;
  box-sizing: border-box;
  @include display-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: $margin-desktop;
  padding-right: $margin-desktop;
  grid-area: header;

  .header {
    &__actions-item {
      svg {
        padding: 2px;
      }
    }
  }

  &_sticky {
    position: fixed;
    width: 100vw;
    transition: height 0.3s, box-shadow 0.3s;

    &.active {
      height: 3.646vw;
      background: $beige;
      box-shadow: $shadow-bottom-xs !important;

      .menu__link {
        line-height: 2;
      }
    }
  }

  .header__logo {
    @include display-flex;
    @include align-items(center);
    @include justify-content(flex-start);
    flex-wrap: wrap;
    z-index: 9;

    svg {
      width: 100px;
      max-width: 100%;
      transition: width 0.3s;
    }
  }

  /* MENU */
  .header__menu {
    @include display-flex;
    @include align-items(center);
    @include justify-content(space-between);
    flex-wrap: wrap;
    z-index: 10;

    .dropdown {
      position: relative;

      &__button {
        &_with-caret {
          cursor: pointer;

          svg {
            pointer-events: none;
            width: 0.5vw;
            height: 0.5vw;
            position: relative;
            margin-left: 8px;
            transform: rotateX(0deg);
            transition: $animation-global;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 2.3vw;
        z-index: 2;
        overflow: hidden;
        opacity: 0;
        max-height: 0;
        transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
        background-color: $beige5;
        box-shadow: 0 60px 99px rgba(0, 0, 0, 0.16);
        pointer-events: auto;

        @media screen and (min-width: 1025px) {
          &.first {
            left: -50px;
          }

          &.second {
            left: -280px;
          }

          &.third {
            left: -260px;
          }

          &.fourth {
            left: -350px;
          }
        }
      }

      &__column {
        margin-right: 70px;
        padding: 30px 0;

        .menu__link {
          line-height: 2;
        }

        .dropdown__links-wrapper {
          > div {
            line-height: 2.2;
          }
        }

        @media screen and (min-width: 1025px) {
          margin: 40px 0px;
          padding: 0 60px;
          font-size: 0;
          line-height: 2.1;

          .dropdown__links-wrapper {
            > div {
              margin-top: 4px;
              line-height: 2.2;
            }
          }

          .dropdown__column-content {
            &:not(:last-child) {
              margin-bottom: 30px;
            }
          }

          &:not(:last-child) {
            border-right: 1px solid rgba(198, 193, 194, 0.3);
          }
        }
      }

      &__title {
        @include text-base($size: 0.729vw, $weight: $bold);
        white-space: nowrap;
      }

      &__link {
        @include text-base($size: 0.729vw, $weight: $regular, $color: $gray2);
        white-space: nowrap;
        display: inline-block;
        line-height: 2.3;
        text-transform: capitalize;
        position: relative;
        cursor: pointer;
        outline: none;
        text-decoration: none;

        &:after {
          display: block;
          content: '';
          background-color: $yellow;
          height: 2px;
          width: 0;
          left: 50%;
          position: absolute;
          -webkit-transition: width 0.2s ease-in-out;
          -moz--transition: width 0.2s ease-in-out;
          transition: width 0.2s ease-in-out;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          transform: translateX(-50%);
        }

        &:hover {
          color: $gray7;

          &:after {
            width: 100%;
          }
        }

        &.active:after {
          width: 100%;
        }
      }

      &__title-link {
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 0;
      }
    }

    @media (hover: hover) {
      .dropdown:hover {
        .dropdown__button_with-caret {
          svg {
            transform: rotateX(180deg);
          }
        }

        .dropdown__content {
          opacity: 1;
          max-height: 100vh;
          pointer-events: initial;
        }
      }
    }

    .dropdown {
      @media screen and (min-width: 1024px) {
        .dropdown__title {
          .icon_caret {
            opacity: 0;
          }
        }
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1500px) {
        &.show-on-tablet {
          .dropdown__button_with-caret {
            svg {
              transform: rotateX(180deg);
            }
          }

          .dropdown__content {
            opacity: 1;
            max-height: 100vh;
            pointer-events: initial;
          }
        }
      }
    }

    #menu-toggle {
      width: 100%;

      #menu {
        .menu_scrollable {
          @include display-flex;
          @include align-items(center);
          @include justify-content(space-between);

          .menu-part {
            @include display-flex;
            @include align-items(center);
            @include justify-content(space-between);

            .menu_item_link {
              margin-left: 2vw;
            }
          }
        }
      }
    }

    input[type='checkbox'] {
      display: none;
    }
  }

  .menu__link {
    @include text-base($size: 0.8vw, $weight: $semibold);
    line-height: 3;
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    transition: line-height 0.3s;

    &:not(.dropdown):after {
      display: block;
      content: '';
      background-color: $yellow;
      height: 2px;
      width: 0;
      left: 50%;
      position: absolute;
      -webkit-transition: width 0.2s ease-in-out;
      -moz--transition: width 0.2s ease-in-out;
      transition: width 0.2s ease-in-out;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    &:hover {
      color: $gray7;
      text-decoration: none;

      &:after {
        width: 100%;
      }

      svg {
        transform: rotateX(0deg);
        transition: $animation-global;
      }

      @media (pointer: coarse) {
        color: $dark-brown;

        &:after {
          width: 0;
        }
      }
    }

    &.active:after {
      width: 100%;
    }
  }

  .dropdown {
    margin-left: 1vw;
  }

  .header__actions {
    @include display-flex;
    @include align-items(center);
    flex-wrap: wrap;
    margin-right: 3px;

    .icon_shopping {
      @media screen and (min-width: 2900px) {
        width: 30px;
        height: 35px;
      }
    }

    &-item {
      cursor: pointer;
      @include display-flex;
      @include align-items(center);
      margin-left: 1vw;

      &:first-child {
        padding-left: 0;
      }

      .icon-profile_active {
        path {
          fill: none;
          stroke: $dark-brown;
        }
      }

      &.preview-parent {
        position: relative;

        .preview-block {
          padding: 0.781vw 0;

          .preview {
            opacity: 0.7;
            transform: scale(0);
            transform-origin: top right;
            transition: all 0.37s ease-in;
            transition-delay: 0.5s;

            &.show {
              opacity: 1;
              transform: scale(1);
            }

            &.hide {
              opacity: 0;
              transform: scale(0);
            }
          }

          &:not(.no-hover):hover {
            .preview {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }
    }

    .search__search-block {
      input[type='text'] {
        width: 130px;
        box-sizing: border-box;
        border: none;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        background-color: transparent;
        background-image: url('../../../img/icons/icon-search.png');
        background-position: 10px 10px;
        background-repeat: no-repeat;
        padding: 12px 20px 12px 40px;
        -webkit-transition: width 0.4s ease-in-out;
        transition: width 0.4s ease-in-out;
      }

      input[type='text']:focus {
        width: 100%;
      }
    }
  }

  .cart-count-items {
    @include text-base($size: 0.625vw);
    color: $white;
    background-color: $dark-brown;
    padding: 5px 6px 4px;
    border-radius: $border-radius-circle;
    margin-right: 0.26vw;
    min-width: 0.469vw;
    text-align: center;
    cursor: default;
  }
}

@media screen and (max-width: 1024px) {
  header.header {
    height: $header-height-laptop;
    padding-left: $margin-laptop;
    padding-right: $margin-laptop;

    &_sticky {
      &.active {
        height: 70px;

        .header__logo {
          flex-grow: 1;
          svg {
            width: 100px;
          }
        }

        .menu__link {
          line-height: 2;
        }
      }
    }

    .header__logo {
      flex-grow: 1;
      order: 2;
      z-index: 20;

      svg {
        width: 100px;
      }
    }

    .header__menu {
      order: 1;
      flex: none;

      #menu-toggle {
        display: block;
        position: relative;
        margin-right: 5px;
        z-index: 1;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        #menu {
          .menu-part {
            margin-top: 20px;
            .menu__link {
              @include text-base;
              font-size: 14px;
              font-weight: 600;
              width: 100%;
              line-height: 2;
              padding: 5px 0;
              margin: 0;
            }

            .dropdown {
              &__button {
                &_with-caret {
                  width: 25px;
                  height: 38px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: absolute;
                  right: -25px;
                  top: 5px;
                  z-index: 11;

                  .menu__link {
                    font-size: 14px;
                    font-weight: 600;
                  }

                  svg {
                    width: 12px;
                    height: 12px;
                    left: 0;
                    top: 0;
                    margin: 0;
                  }
                }
              }

              &__content {
                margin-left: 20px;
                display: none;
                position: relative;
                padding: 0;
                left: auto;
                top: 0;
                background-color: initial;
                box-shadow: none;
                opacity: 1;
                max-height: initial;
                pointer-events: initial;
              }

              &__checkbox:checked {
                ~ .dropdown__button_with-caret {
                  svg {
                    transform: rotateX(180deg);
                  }
                }

                ~ .dropdown__content {
                  display: flex;
                }
              }

              &__checkbox:not(:checked) {
                ~ .dropdown__button_with-caret {
                  svg {
                    transform: rotateX(0deg);
                  }
                }
              }

              &__column {
                margin-right: 0;
                padding: 0;

                .dropdown__links-wrapper {
                  display: none;
                }
                .dropdown__column-checkbox:checked {
                  ~ .dropdown__title_with-caret {
                    svg {
                      transform: rotateX(180deg);
                    }
                  }

                  ~ .dropdown__links-wrapper {
                    margin-bottom: 10px;
                    display: block;
                  }
                }
                .menu__link {
                  padding-top: 0;
                  padding-bottom: 0;
                }
              }

              &__title-link.dropdown__link {
                margin-left: 0;
                margin-bottom: 5px;
              }

              &__title {
                font-size: 12px;
                font-weight: 500;
                line-height: 2.7;

                &_with-caret {
                  display: flex;
                  align-items: center;

                  .mobile-displaying {
                    line-height: 1.5;
                    display: flex;
                    flex-direction: column;
                  }

                  svg {
                    width: 12px;
                    height: 12px;
                    margin-left: 8px;
                    transform: rotateX(0deg);
                  }
                  cursor: pointer;
                }

                .menu__link {
                  font-size: 11px !important;
                  padding: 4px 0 !important;
                }
              }

              &__link {
                margin-left: 20px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .header__actions {
      order: 3;
      @include justify-content(flex-end);

      &-item {
        cursor: pointer;
        &:not(:first-child) {
          margin-left: 1.5vw;
        }

        &.preview-parent {
          .preview-block {
            padding: 17px 0;
          }
        }
      }
    }

    #menu-toggle .menu__checkbox {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -8px;
      cursor: pointer;
      opacity: 0;
      z-index: 2;
      -webkit-touch-callout: none;
    }

    #menu-toggle span {
      display: block;
      width: 27px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      background-color: #4d4048;
      border-radius: 3px;
      z-index: 1;
      -webkit-transform-origin: 4px 0;
      transform-origin: 4px 0;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
        -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }

    #menu-toggle span:first-child {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }

    #menu-toggle span:nth-last-child(2) {
      -webkit-transform-origin: 0 100%;
      transform-origin: 0 100%;
    }

    #menu-toggle .menu__checkbox {
      &.open_menu ~ span {
        opacity: 1;
        -webkit-transform: rotate(45deg) translate(-1px, 0);
        transform: rotate(45deg) translate(2px, 1px);
        background: #232323;
      }
    }
    #menu-toggle .menu__checkbox {
      &.open_menu ~ span:nth-last-child(3) {
        opacity: 0;
        -webkit-transform: rotate(0deg) scale(0.2, 0.2);
        transform: rotate(0deg) scale(0.2, 0.2);
      }
    }

    #menu-toggle .menu__checkbox {
      &.open_menu ~ span:nth-last-child(2) {
        -webkit-transform: rotate(-45deg) translate(-3px, 3px);
        transform: rotate(-45deg) translate(-2px, 4px);
      }
    }
    #menu {
      position: absolute;
      display: block !important;
      box-sizing: border-box;
      width: 400px;
      margin: -110px 0 0 -80px;
      padding: 125px 25px 50px 80px;
      background: $beige;
      box-shadow: 0 10px 20px $black-16;
      list-style-type: none;
      -webkit-font-smoothing: antialiased;
      /* to stop flickering of text in safari */
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
      -webkit-transform: translate(-107%, 0);
      transform: translate(-107%, 0);
      transition: -webkit-transform 1s cubic-bezier(0.77, 0.2, 0.05, 1);
      transition: transform 1s cubic-bezier(0.77, 0.2, 0.05, 1),
        -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

      div {
        @include flex-direction(column);
        @include align-items(flex-start !important);
      }

      .menu_scrollable {
        max-height: calc(80vh);
        overflow-y: auto;
      }
    }

    #menu-toggle .menu__checkbox {
      &.open_menu {
        ~ #menu {
          -webkit-transform: none;
          transform: none;
        }
      }
    }

    .cart-count-items {
      font-size: 12px;
      padding: 5px 6px 4px;
      margin-right: 5px;
      min-width: 9px;
    }
  }
}

@media (max-width: 767px) {
  header.header {
    height: $header-height-mobile;
    padding-left: $margin-mobile;
    padding-right: $margin-mobile;

    &_sticky {
      &.active {
        height: 50px;
        background: $bg-mobile-beige;
        .header__logo {
          width: 50px;
          flex-grow: 0;
        }
      }
    }

    .header__logo {
      width: 50px;
      flex-grow: 0;
      z-index: 25;
    }

    #menu {
      width: 500px;
      padding: 115px 20px 50px 60px;

      .menu_scrollable {
        margin-left: 35px;
        max-height: calc(70vh);
      }
    }

    .header__actions {
      @include flex-size(1, 0, 20%);
      z-index: 20;

      &-item {
        margin-left: 4.3vw !important;

        &.preview-parent {
          .preview-block {
            .preview {
              &.show {
                opacity: 0.7;
                transform: scale(0);
              }
            }

            &:not(.no-hover):hover {
              .preview {
                opacity: 0.7;
                transform: scale(0);
              }
            }
          }
        }
      }
    }
  }
}