@import '../../../styles/variables';
@import '../../../styles/common';

/**
  Styles for custom select without label start
  Managed by class .open
  If parent of .custom-select has overflow-hidden, the options list will be cutted (in case with default select will not)
*/
.custom-select {
  @include text-base($size: 0.833vw, $color: $gray2);
  line-height: 2.6em;
  position: relative;
  width: 100%;
  background-color: $beige;
  margin-bottom: 0.5vw;

  &_bg-white {
    background-color: $white;
  }

  &_disabled {
    .select {
      cursor: default;

      .select__inner {
        cursor: default !important;
      }
    }
  }

  &__centered-item {
    display: flex;
    align-items: center;
  }

  .select {
    white-space: nowrap;
    position: relative;
    border-bottom: 0.052vw solid $beige2;
    background-color: inherit;
    display: block;
    overflow: hidden;
    line-height: 1em;

    @media screen and (max-width: 1920px) {
      border-bottom-width: 1px;
    }

    > .label-text {
      position: absolute;
      bottom: 0.7vw;
      left: 0;
      transition: transform 0.2s;

      &_empty-value {
        right: 1.363vw;
        left: auto;
        color: $brown-black;
        @media (max-width: 1024px) {
          right: 20px;
        }
      }

      &_open {
        transform: translate3d(0, -1.5vw, 0) scale(0.9);
        transform-origin: 0;
      }
    }

    //&:hover {
    //  border-bottom-color: $brown-black;
    //
    //  @media (pointer: coarse) {
    //    border-bottom-color: $beige2;
    //  }
    //}

    &:active {
      border-bottom-color: $brown-black;
    }

    // You can apply .select__inner to div or input
    .select__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border: none;
      padding: 0.8em 1.042vw 0.85em 0;
      color: $gray2;
      background-color: inherit;
      background-size: 0.5em;
      background-repeat: no-repeat;
      background-position: 97% center;
      cursor: pointer;
      box-sizing: border-box;
      margin-top: 1.425vw;

      &_with-caret {
        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='caret-down' class='svg-inline--fa fa-caret-down fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'%3E%3C/path%3E%3C/svg%3E");
      }

      &_with-angle {
        padding-right: 3%;
      }

      .select__arrow {
        transition: 0.2s;
        margin-left: auto;
      }
    }
  }

  &.open {
    .select {
      position: relative;
      .select__inner {
        &_with-caret {
          background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='caret-up' class='svg-inline--fa fa-caret-up fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'%3E%3C/path%3E%3C/svg%3E");
        }

        .select__arrow {
          transform: rotate(180deg);
        }
      }
    }

    .options {
      z-index: 21;
      display: block;
    }
  }

  &.active {
    .select__inner {
      color: #1c1c1f;
    }
  }

  .options {
    z-index: 1;
    display: none;
    position: absolute;
    width: 100%;
    max-height: 9.375vw;
    box-sizing: border-box;
    margin-top: 1px;
    padding: 0.521vw 0;
    background-color: inherit;
    box-shadow: $shadow-bottom-sm;

    &-list {
      overflow-y: auto;
      max-height: 8.333vw;
      width: 100%;
      box-sizing: border-box;

      &__item {
        line-height: 1.3em;
        padding: 0.5em;
        color: $dark-brown;

        &:hover {
          cursor: pointer;
          background-color: $beige5;
        }

        .checkbox-container {
          width: auto;
        }

        &_active {
          &-with-check-icon {
            &::after {
              content: '';
              display: inline-block;
              margin-left: 11px;
              width: 18px;
              height: 18px;
              background-image: url(../../../img/icons/icon-check-active.svg);
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;

    &-track {
      border-radius: 10px;
      background: $beige2;
    }

    &-thumb {
      background: $dark-brown;
      border-radius: 3px;

      &:hover {
        background: $brown-black;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    font-size: 14px;
    padding-top: 27px;
    line-height: 2.7em;

    .select {
      border-bottom-width: 1px;

      .select__inner {
        padding-right: 10px;
      }

      > .label-text {
        &_open {
          transform: translate3d(0, -24px, 0) scale(0.9);
        }
      }
    }

    .options {
      max-height: 180px;
      padding: 7px 0;

      &-list {
        max-height: 160px;

        &__item {
          padding: 0.5em 10px;
        }
      }
    }
  }
}
