//Fonts were generated by https://transfonter.org/
//It generates css-rules @font-face and fonts in different formats

//TODO: need to use aliases or smth like this

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Montserrat-Regular.otf') format('opentype'),
    url('../fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../fonts/Montserrat-Regular.woff') format('woff'),
    url('../fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('../fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Montserrat-Medium.otf') format('opentype'),
    url('../fonts/Montserrat-Medium.woff2') format('woff2'),
    url('../fonts/Montserrat-Medium.woff') format('woff'),
    url('../fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.eot');
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Montserrat-SemiBold.otf') format('opentype'),
    url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('../fonts/Montserrat-SemiBold.woff') format('woff'),
    url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'DidotLH';
  src: url('../fonts/DidotLHBold.eot');
  src: local('DidotLH Bold'), local('DidotLHBold'),
    url('../fonts/DidotLHBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DidotLHBold.woff2') format('woff2'),
    url('../fonts/DidotLHBold.woff') format('woff'),
    url('../fonts/DidotLHBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('../fonts/RobotoCondensed-Bold.eot');
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
    url('../fonts/RobotoCondensed-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/RobotoCondensed-Bold.woff2') format('woff2'),
    url('../fonts/RobotoCondensed-Bold.woff') format('woff'),
    url('../fonts/RobotoCondensed-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/PlayfairDisplay-Regular.eot');
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'),
    url('../fonts/PlayfairDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/PlayfairDisplay-Regular.woff2') format('woff2'),
    url('../fonts/PlayfairDisplay-Regular.woff') format('woff'),
    url('../fonts/PlayfairDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Book';
  src: url('../fonts/Futura-Book.eot');
  src: local('Futura Book'), local('Futura-Book'),
    url('../fonts/Futura-Book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Futura-Book.woff2') format('woff2'),
    url('../fonts/Futura-Book.woff') format('woff'),
    url('../fonts/Futura-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Old English';
  src: local('Old English'),
    url('../fonts/engrave/old-english/OldEnglish.ttf') format('truetype'),
    url('../fonts/engrave/old-english/OldEnglish.woff2') format('woff2'),
    url('../fonts/engrave/old-english/OldEnglish.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bookman';
  src: local('Bookman-Regular'),
    url('../fonts/engrave/bookman/bookman-regular.ttf') format('truetype'),
    url('../fonts/engrave/bookman/Bookman-Regular.woff2') format('woff2'),
    url('../fonts/engrave/bookman/Bookman-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Regular Corinthia';
  src: local('Regular Corinthia'),
    url('../fonts/engrave/corinthia/Corinthia.woff2') format('woff2'),
    url('../fonts/engrave/corinthia/Corinthia.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Logo';
  src: local('logo Regular'), local('Logo'),
    url('../fonts/engrave/logo/logo.woff2') format('woff2'),
    url('../fonts/engrave/logo/logo.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monoline';
  src: local('Monoline'),
    url('../fonts/engrave/monoline/Monoline.woff2') format('woff2'),
    url('../fonts/engrave/monoline/Monoline.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lucida';
  src: local('Lucida Handwriting Italic'), local('LucidaHandwriting-Italic'),
    url('../fonts/engrave/lucida-handwriting/LucidaHandwriting-Italic.woff2')
      format('woff2'),
    url('../fonts/engrave/lucida-handwriting/LucidaHandwriting-Italic.woff')
      format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'P22 Corinthia';
  src: local('P22 Corinthia'), local('P22Corinthia'),
    url('../fonts/engrave/p22-corinthia/P22Corinthia.woff2') format('woff2'),
    url('../fonts/engrave/p22-corinthia/P22Corinthia.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Edwardian Script';
  src: local('Edwardian Script'), local('EdwardianScript'),
    url('../fonts/engrave/Edwardian-script/EseninscriptOne.woff2')
      format('woff2'),
    url('../fonts/engrave/Edwardian-script/EseninscriptOne.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Script MT Bold';
  src: local('Script MT Bold'), local('ScriptMTBold'),
    url('../fonts/engrave/scriptmtbold/ScriptMTBold.woff2') format('woff2'),
    url('../fonts/engrave/scriptmtbold/ScriptMTBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Colonna';
  src: local('Colonna MT'), local('ColonnaMT'),
    url('../fonts/engrave/collonna-mt/ColonnaMT.woff2') format('woff2'),
    url('../fonts/engrave/collonna-mt/ColonnaMT.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bulletin Typewriter';
  src: local('Bulletin Typewriter'), local('BulletinTypewriter'),
    url('../fonts/engrave/Bulletin-typewriter/Bulletin-Typewriter.woff2')
      format('woff2'),
    url('../fonts/engrave/Bulletin-typewriter/Bulletin-Typewriter.woff')
      format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Cut Out';
  src: local('Helvetica Cut Out'), local('HelveticaCutOut'),
    url('../fonts/engrave/Helvetica/Helvetica.woff2') format('woff2'),
    url('../fonts/engrave/Helvetica/Helvetica.woff') format('woff');
  font-style: normal;
  font-display: swap;
}
