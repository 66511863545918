@import '../../../styles/variables';

.engrave-color-circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 2px;
  border: 1px solid $dark-brown;
  border-radius: 8px;
  box-sizing: border-box;
}
