.loader-spinner {
  position: absolute;
  border-radius: 50%;
  width: 1.3vw;
  height: 1.3vw;
  border: 0.156vw solid rgba(0, 0, 0, 0.1);
  border-left-color: rgba(77, 64, 72, 1);
  transform: translateZ(0);
  animation: loader-spinner 0.5s infinite linear;

  &_mr-sm {
    margin-right: 0vw;
  }

  @media screen and (max-width: 1024px) {
    width: 24px;
    height: 24px;
    border-width: 2px;
    &_mr-sm {
      margin-right: 0vw;
    }
  }
}

@keyframes loader-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
