#awn-toast-container {
  position: fixed;
  z-index: $awn-container-z-index;
  bottom: $awn-container-padding;
  right: $awn-container-padding;
  box-sizing: border-box;

  &.awn-top-left,
  &.awn-top-right {
    top: $awn-container-padding;
    bottom: auto;

    .awn-toast:first-child {
      margin-top: $awn-toast-margin;
    }
  }

  &.awn-top-left,
  &.awn-bottom-left {
    left: $awn-container-padding;
    right: auto;

    .awn-toast {
      right: 100%;
      animation-name: $awn-toast-left-show-animation;

      &.awn-hiding {
        right: 0;
      }
    }
  }

  &.awn-top-right,
  &.awn-bottom-right {
    .awn-toast {
      left: 100%;
      animation-name: $awn-toast-right-show-animation;

      &.awn-hiding {
        left: 0;
      }
    }
  }
}

.awn-toast {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  opacity: 0;
  width: $awn-toast-width;
  background: $awn-primary-background;
  margin-top: $awn-toast-margin;
  border-radius: $awn-toast-border-radius;
  color: $awn-primary-color;
  font-size: $awn-toast-font-size;
  animation-timing-function: $awn-toast-animation-timing;
  animation-fill-mode: both;

  &-content {
    word-break: break-word;
  }

  &-label {
    display: block;
    text-transform: uppercase;
    color: $awn-primary-color;
    font-size: $awn-toast-title-font-size;
  }

  &-icon {
    position: absolute;
    right: $awn-toast-padding;
    top: $awn-progress-bar-height;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .fa {
      font-size: $awn-toast-icon-size;
      color: $awn-primary-color;
    }
  }

  &-wrapper {
    padding: $awn-progress-bar-height + $awn-toast-padding 88px
      $awn-toast-padding $awn-toast-padding;

    border: $awn-border;
    border-radius: $awn-toast-border-radius;
  }

  &-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $awn-progress-bar-height;

    &:after {
      content: ' ';
      background: $awn-primary-color;
      position: absolute;
      width: 100%;
      right: 100%;
      top: 0;
      height: $awn-progress-bar-height;
      animation-name: awn-bar;
      animation-duration: inherit;
      animation-timing-function: linear;
      animation-fill-mode: both;
    }
  }

  &.awn-toast-progress-bar-paused .awn-toast-progress-bar:after {
    animation-play-state: paused;
  }

  &.awn-hiding {
    animation-name: $awn-toast-hide-animation !important;
  }

  &.awn-toast-success {
    background: $awn-success-background;
    color: $awn-success-color;

    .awn-toast-wrapper {
      border-color: $awn-success-border-color;
    }

    b,
    .fa {
      color: $awn-success-color;
    }

    .awn-toast-progress-bar:after {
      background: $awn-success-color;
    }
  }

  &.awn-toast-info {
    background: $awn-info-background;
    color: $awn-info-color;

    .awn-toast-wrapper {
      border-color: $awn-info-border-color;
    }

    b,
    .fa {
      color: $awn-info-color;
    }

    .awn-toast-progress-bar:after {
      background: $awn-info-color;
    }
  }

  &.awn-toast-alert {
    background: $awn-alert-background;
    color: $awn-alert-color;

    .awn-toast-wrapper {
      border-color: $awn-alert-border-color;
    }

    b,
    .fa {
      color: $awn-alert-color;
    }

    .awn-toast-progress-bar:after {
      background: $awn-alert-color;
    }
  }

  &.awn-toast-warning {
    background: $awn-warning-background;
    color: $awn-warning-color;

    .awn-toast-wrapper {
      border-color: $awn-warning-border-color;
    }

    b,
    .fa {
      color: $awn-warning-color;
    }

    .awn-toast-progress-bar:after {
      background: $awn-warning-color;
    }
  }
}
