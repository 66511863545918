@import '../../styles/variables';
@import '../../styles/flex';
@import '../../styles/common';
@import '../../styles/icons';
@import '../../styles/buttons';
@import '../../styles/animations';

.catalog-page {
  &.grid {
    // Start css-grid building
    display: grid;
    min-height: 100%;
    grid-template-areas: 'header header header header header' 'breadcrumbs breadcrumbs . . .' 'filters filters sort-header sort-header .' 'filters filters results . .' 'filters filters table table .';
    grid-template-columns: $margin-desktop 24.74vw 4fr 1fr $margin-desktop;
    grid-template-rows: $header-height-desktop 30px 80px $breadcrumbs-height-desktop auto;

    .grid-area {
      &-header {
        grid-area: header;
        padding-left: $margin-desktop;
        padding-right: $margin-desktop;
      }

      &-breadcrumbs {
        grid-area: breadcrumbs;
        margin-left: $margin-desktop;
        margin-bottom: 20px;
      }

      &-filters {
        grid-area: filters;
        background-color: $beige;
        // Setting background-color
        margin-top: calc(
          (#{$header-height-desktop} + #{$breadcrumbs-height-desktop}) * -1
        );
        padding-top: calc(
          #{$header-height-desktop} + #{$breadcrumbs-height-desktop}
        );
        margin-left: -$margin-desktop;
        padding-left: $margin-desktop * 2;
      }

      &-table {
        grid-area: table;
      }

      &-sort-header {
        padding-left: 2.604vw;
        grid-area: sort-header;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .icon-catalog {
          display: flex;
          margin-top: 20px;

          .icon {
            padding: 2px;
          }

          .icon_list {
            margin-left: 16px;
          }

          .icon_list,
          .icon_tiles {
            &.active {
              path {
                fill: $dark-brown;
              }
            }

            path {
              fill: $gray2;
            }
          }
        }

        .items-per-page {
          display: flex;
          flex-direction: row;
          .btn {
            font-size: 14px;
            display: none;
            width: 320px;
            span {
              margin-right: 10px;
            }
          }
          .custom-select {
            width: 17.316vw;
            margin-right: 30px;
            background-color: white;
          }
        }
      }

      &-results {
        margin-top: 10px;
        margin-bottom: 10px;
        grid-area: results;
        display: flex;
        align-items: center;
      }

      &-results__tooltip {
        margin-left: 30px;
      }
    }

    // End css-grid building

    .grid-area-filters {
      padding-right: 2.344vw;

      .filters-header {
        @include display-flex;
        @include align-items(center);
        @include justify-content(space-between);
        margin-bottom: 2.865vw;
      }

      .advanced-filters {
        padding-top: 2.604vw;
        margin-bottom: 7.813vw;
        border-top: 0.052vw solid $dark-gray;

        .advanced-filters-title {
          @include text-base(
            $size: 1.302vw,
            $family: 'Roboto Condensed',
            $weight: $bold
          );
          @include display-flex();
          @include align-items(center);

          .icon {
            margin-right: 1.458vw;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .filter {
        margin: 1.302vw 0 1.823vw;

        .filter-name {
          @include text-base($size: 1.25vw, $family: 'Playfair Display');
          margin-bottom: 1.042vw;
        }

        .filter-body {
          @include display-flex;
          @include flex-wrap(wrap);

          &.cols-3 {
            .btn {
              @include flex-grow(1);
              width: calc((100% - 2.33% * 2) / 3);
              margin-bottom: 2.33%;
              margin-right: 2.33%;
              line-height: 1.5;
              white-space: pre-wrap;

              &:nth-child(3n + 3) {
                margin-right: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }

          &.cols-4 {
            .btn {
              @include flex-grow(1);
              width: calc((100% - 2.33% * 3) / 4);
              margin-bottom: 2.33%;
              margin-right: 2.33%;

              &:nth-child(4n + 4) {
                margin-right: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .rc-slider {
            position: relative;
            //height: 0.729vw;
            padding: 0.26vw 0;
            margin-top: 0.521vw;
            width: 100%;
            border-radius: 0.313vw;
            -ms-touch-action: none;
            touch-action: none;
            box-sizing: border-box;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            &-rail {
              position: absolute;
              width: 100%;
              background-color: $beige2;
              height: 0.104vw;
              border-radius: 0.313vw;
            }

            &-track {
              position: absolute;
              left: 0;
              height: 0.156vw;
              margin-top: -0.026vw;
              border-radius: 0.313vw;
              background-color: $dark-brown;
            }

            &-step {
              position: absolute;
              width: 100%;
              height: 0.208vw;
              background: transparent;
            }

            &-handle {
              position: absolute;
              margin-left: -0.521vw; // check
              margin-top: -0.521vw;
              width: 1.042vw;
              height: 1.042vw;
              cursor: pointer;
              cursor: -webkit-grab;
              cursor: grab;
              border-radius: $border-radius-circle;
              background-color: $dark-brown;
              -ms-touch-action: pan-x;
              touch-action: pan-x;
            }

            &-mark {
              position: absolute;
              top: 0.938vw;
              left: 0;
              width: 100%;
              font-size: 0.625vw;

              span.rc-slider-mark-text {
                @include text-base($size: 0.938vw);
                position: absolute;
              }
            }

            & * {
              box-sizing: border-box;
              -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            }
          }

          .filter-range-inputs-wrap {
            @include display-flex;
            width: 100%;
            margin-top: 1.979vw;

            .label {
              @include text-base($size: 0.938vw);

              &_left {
                margin-right: 1.302vw;
              }

              &_right {
                margin-left: 0.521vw;
              }
            }

            .dash {
              font-size: 0.938vw;
              margin: 0.156vw 1.302vw;
            }

            .filter-range-input-block {
              @include display-flex;
              width: 50%;

              .filter-range-input {
                border-bottom: 0.104vw solid $beige2;
                @include display-flex;
                width: 100%;

                .label_from-to {
                  @include text-base($size: 0.729vw, $color: $gray2);
                  margin-top: 0.104vw;
                }

                input.input {
                  width: 100%;
                  min-width: 0; // for FireFox
                  text-align: right;
                  background-color: transparent;
                  border: none;
                  @include text-base($size: 0.885vw, $color: $gray2);
                  margin-top: -0.156vw;
                  margin-bottom: 0.521vw;
                  margin-left: 0.26vw;
                }
              }
            }
          }
        }

        &_range {
          margin-bottom: 2.344vw;
        }

        &_mb-67 {
          margin-bottom: 3.49vw;
        }
      }
    }

    .grid-area-table {
      padding-left: 2.604vw;

      .table-wrap {
        position: relative;

        .parent {
          position: fixed !important;
        }

        table {
          width: 100%;
          background-color: white;

          th,
          td {
            text-align: left;
            padding: 1.198vw 0.521vw;
            white-space: nowrap;
            vertical-align: middle;

            span {
              vertical-align: middle;

              &.double-icons {
                @include display-inline-flex();
                @include flex-direction(column);
                margin-left: 0.521vw;
                padding: 0.521vw;
                cursor: pointer;
              }
            }
          }

          thead {
            tr {
              border-bottom: 0.104vw solid $gray2;

              th {
                @include text-base(
                  $size: 0.833vw,
                  $family: 'Playfair Display',
                  $color: $brown-black
                );
                padding: 0.521vw;

                .content-wrap {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }

          tbody {
            opacity: 0;
            transition: 1s;
            animation: show 1s 1;
            animation-fill-mode: forwards;

            tr {
              border-bottom: 0.052vw solid $beige2;

              td {
                position: relative;
                @include text-base($size: 0.729vw, $color: $brown-black);
                line-height: 1.2;
                max-width: 60px;
                white-space: normal;

                &:last-child {
                  @include text-base(
                    $size: 0.729vw,
                    $weight: $bold,
                    $family: 'Roboto Condensed'
                  );
                }

                img {
                  width: 80px;
                  height: 80px;
                }

                a {
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 1;
                  width: 100%;
                  height: 100%;
                  box-sizing: border-box;
                }
              }
            }
          }

          &.table_pc {
            tbody {
              tr {
                &:not(.no-hover) {
                  &:hover {
                    background-color: $beige3;
                    transition: $animation-global;
                  }
                }
              }
            }
          }
          &.table_mobile {
            tbody {
              tr {
                &:not(.no-hover) {
                  &:active {
                    background-color: $beige3;
                    cursor: pointer;
                    transition: $animation-global;
                  }
                }
              }
            }
          }
        }
      }

      .pagination-footer {
        display: flex;
        justify-content: center;
        .pagination {
          margin-top: 20px;
        }
      }
    }

    .grid-area-results {
      @include text-base($size: 0.833vw, $weight: $medium);
      padding-left: 2.604vw;
    }
  }
}

@media screen and (max-width: 1024px) {
  .catalog-page {
    &.grid {
      // Start css-grid building for laptop
      grid-template-areas: 'header header header header header' 'breadcrumbs breadcrumbs . . .' 'filters filters sort-header sort-header .' 'filters filters results results .' 'filters filters table table .';
      grid-template-columns: $margin-desktop 24.74vw 4fr 1fr $margin-desktop;
      grid-template-rows: $header-height-desktop min-content 90px $breadcrumbs-height-desktop auto;

      .grid-area-header {
        padding-left: $margin-laptop;
        padding-right: $margin-laptop;
      }

      .grid-area-breadcrumbs {
        margin-left: $margin-laptop;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .grid-area-sort-header {
        .icon-catalog {
          margin-top: 40px;
        }
      }

      .grid-area-filters {
        margin-top: calc(
          (#{$header-height-laptop} + #{$breadcrumbs-height-laptop}) * -1
        );
        padding-top: calc(
          #{$header-height-laptop} + #{$breadcrumbs-height-laptop}
        );
        margin-left: -$margin-laptop;
        padding-left: $margin-laptop * 2;
        // End css-grid building for laptop
        padding-right: 20px;

        .filters-header {
          margin-bottom: 40px;
        }

        .advanced-filters {
          padding-top: 20px;
          margin-bottom: 100px;
          border-top: 2px solid $dark-gray;

          .advanced-filters-title {
            font-size: 20px;

            .icon {
              margin-right: 15px;
            }
          }
        }

        .filter {
          margin: 25px 0 35px;

          .filter-name {
            font-size: 16px;
            margin-bottom: 20px;
            margin-right: 15px;
          }

          .filter-body {
            .btn {
              .icon {
                display: none;
              }
            }

            .rc-slider {
              height: 14px;
              padding: 5px 0;
              margin-top: 10px;

              &-rail {
                height: 2px;
              }

              &-track {
                height: 3px;
                margin-top: -0.5px;
              }

              &-step {
                height: 4px;
              }

              &-handle {
                margin-left: -7.5px;
                margin-top: -7.5px;
                width: 15px;
                height: 15px;
              }

              &-mark {
                top: 18px;
                font-size: 12px;

                span.rc-slider-mark-text {
                  font-size: 12px;
                }
              }
            }

            .filter-range-inputs-wrap {
              margin-top: 30px;

              .label {
                font-size: 12px;
              }

              .dash {
                font-size: 10px;
                margin: 3px 10px;
              }

              .filter-range-input-block {
                .filter-range-input {
                  border-bottom: 2 solid $beige2;

                  .label_from-to {
                    font-size: 12px;
                    margin-top: 2px;
                  }

                  input.input {
                    font-size: 14px;
                    margin-top: -0.156vw;
                    margin-bottom: 0.521vw;
                    margin-left: 0.26vw;
                  }
                }
              }
            }
          }

          &_range {
            margin-bottom: 45px;
          }

          &_mb-67 {
            margin-bottom: 60px;
          }
        }
      }

      .grid-area-table {
        padding-left: 15px;

        .table-wrap {
          overflow: auto;

          table {
            margin-bottom: 20px;

            th,
            td {
              padding: 20px 10px;

              span {
                &.double-icons {
                  margin-left: 5px;
                  padding: 10px;
                }
              }
            }

            thead {
              tr {
                th {
                  font-size: 12px;
                  padding: 10px;
                }
              }
            }

            tbody {
              tr {
                td {
                  font-size: 12px;

                  &:last-child {
                    font-size: 14px;
                    min-width: 100px;
                  }
                }
              }
            }
          }
        }

        .pagination {
          margin-right: 10px;
          //margin-bottom: 1.563vw;
        }
      }

      .grid-area-results {
        margin-top: 10px;
        font-size: 12px;
      }

      // Styles for portrait orientation for tablet only
      @media (min-width: 900px) and (orientation: portrait) {
        .grid-area-filters {
          background-color: $beige;
          .filter {
            .filter-body {
              &.cols-3 {
                .btn {
                  @include flex-grow(1);
                  width: calc((100% - 2.33%) / 2);
                  margin-bottom: 2.33%;
                  margin-right: 2.33%;

                  // Rewrite the :nth-child(3n+3) desktop styles, added above other :nth-child styles
                  &:nth-child(3n + 3) {
                    margin-right: 2.33%;
                  }

                  &:nth-child(2n) {
                    margin-right: 0;
                  }

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }

              .filter-range-inputs-wrap {
                .filter-range-input-block {
                  .filter-range-input {
                    position: relative;

                    .label_from-to {
                      position: absolute;
                      top: -15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .catalog-page {
    &.grid {
      // Start css-grid building for mobile
      grid-template-areas: '. header header .' '. breadcrumbs breadcrumbs .' '. sort-header sort-header .' 'filters filters filters filters' 'results results results results' 'table table table table';
      grid-template-columns: $margin-mobile 1fr 1fr $margin-mobile;
      grid-template-rows: $header-height-mobile $breadcrumbs-height-mobile min-content min-content min-content auto;

      .grid-area-header {
        padding-left: $margin-mobile;
        padding-right: $margin-mobile;
      }

      .grid-area-breadcrumbs {
        margin: 0;
      }

      // End css-grid building for mobile

      .grid-area-results {
        padding-right: 20px;
        padding-left: 20px;
        @include display-flex;
        @include align-items(center);
        background-color: transparent;
      }

      .grid-area-sort-header {
        // height: 250px;
        flex-direction: column;
        padding: 0;
        .icon-catalog {
          align-self: flex-end;
          margin-top: 30px;
          margin-bottom: 10px;
        }
        .items-per-page {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          flex-wrap: wrap;
          @media (max-width: 760px) {
            flex-direction: column;
          }
          .btn {
            align-self: center;
            display: block;
            padding: 10px 10px;
            @media (max-width: 760px) {
              width: 100%;
            }
          }
          .custom-select {
            margin-right: 0;
            padding-top: 0;
            width: 320px;
            background-color: white;
            @media (max-width: 760px) {
              margin-top: 10px;
              width: 100%;
            }
          }
        }
      }

      .grid-area-filters {
        display: none;
        margin-top: 0;
        padding-top: 20px;
        padding-right: 20px;
        padding-left: 20px;
        margin-left: 0;

        .filter {
          .filter-body {
            .btn {
              .icon {
                display: block;
              }
            }
          }
        }

        .advanced-filters {
          margin-bottom: 50px;
        }

        &.show {
          display: block;
        }
      }

      .grid-area-table {
        padding-left: 0;
        background-color: $white;

        .table-wrap {
          margin-left: $margin-mobile;

          .parent {
            width: 100%;
          }
        }

        .pagination {
          margin: 20px $margin-mobile;
        }
      }

      &.compare-panel_opened {
        .grid-area-filters {
          padding-bottom: 0;
        }

        .grid-area-table {
          padding-bottom: 86px;
        }
      }
    }
  }
}

/* ----------- for landscape orientation for iPhone X, Pixel 2 XL ----------- */
@media only screen and (max-device-width: 823px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .catalog-page {
    &.grid {
      .grid-area-filters {
        .filter {
          .filter-body {
            .filter-range-inputs-wrap {
              .filter-range-input-block {
                .filter-range-input {
                  position: relative;

                  .label_from-to {
                    position: absolute;
                    top: -15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
