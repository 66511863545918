.form-friend-contacts {
  display: inline-grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 1.56vw;
  margin: 1.04vw 0 0;
}

@media (max-width: 1024px) {
  .form-friend-contacts {
    grid-gap: 30px;
    margin: 30px 0 0;
  }
}
